import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { ConversionRatePerformanceItem } from '../../../../../hooks';
import { Container } from '../../../../../stories/atoms';
import { muiTheme } from '../../../../../theme';
import { homeTranslations, kpiTargetTransalations } from '../../../../../translations';
import { WidgetMetricTile, WidgetMetricType } from '../../../common/metricTile/WidgetMetricTile';
import { WidgetMetricTilesContainer } from '../../../common/metricTile/WidgetMetricTile.styles';
import { getDefaultStatus } from '../../../common/metricTile/util';
import { StyledTrendIconComponent } from '../../../common/styledTrendIcon/StyledTrendIcon';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetMobileLayout } from '../../../common/widgetMobileLayout/WidgetMobileLayout';

export const ConversionRateGraphView = ({
  target,
  today,
  lastWeek,
  lastYear,
  performanceItems,
  filteredPerformanceItems,
  forecastPerformanceItems,
  targetItems,
}: {
  target: number | null;
  today: number;
  lastWeek: number;
  lastYear: number;
  performanceItems: ConversionRatePerformanceItem[];
  filteredPerformanceItems: (ConversionRatePerformanceItem | null)[];
  forecastPerformanceItems: (ConversionRatePerformanceItem | null)[];
  targetItems: (number | null)[] | undefined;
}) => {
  const { conversionRate: conversionRateTranslation, common: commontTranslations } = homeTranslations;
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const { mode } = useThemeContext();
  const { t } = useTranslation();

  const itemValueResolver = (item: ConversionRatePerformanceItem) => {
    const { payingCustomers, nonPayingCustomers } = item;
    if (nonPayingCustomers === 0 && payingCustomers === 0) {
      return 0;
    }
    return parseFloat(((payingCustomers / (nonPayingCustomers + payingCustomers)) * 100).toFixed(2));
  };

  const getConversionRate = (items: (ConversionRatePerformanceItem | null)[]) => {
    let hoursElapsed = 0;
    let payingCustomersCount = 0;
    let nonPayingCustomersCount = 0;
    let numberOfTillsCount = 0;

    items.forEach((item) => {
      if (item === null) return;
      hoursElapsed += 1;
      payingCustomersCount += item.payingCustomers;
      nonPayingCustomersCount += item.nonPayingCustomers;
      numberOfTillsCount += item.numberOfTills;
    });

    const averagePayingCustomers = payingCustomersCount / hoursElapsed;
    const averageNonPayingCustomers = nonPayingCustomersCount / hoursElapsed;
    const averageAmountOfTills = numberOfTillsCount / hoursElapsed;

    const averageTillObject = {
      hour: 'Average',
      payingCustomers: averagePayingCustomers,
      nonPayingCustomers: averageNonPayingCustomers,
      numberOfTills: Math.floor(averageAmountOfTills),
    };

    const conversionRate =
      payingCustomersCount === 0 && nonPayingCustomersCount === 0
        ? 0
        : (payingCustomersCount / (payingCustomersCount + nonPayingCustomersCount)) * 100;

    return {
      conversionRate,
      averageTillObject,
      averagePayingCustomers,
      averageNonPayingCustomers,
    };
  };

  const { conversionRate: forecastConversionRate } = getConversionRate([
    ...filteredPerformanceItems,
    ...forecastPerformanceItems,
  ]);

  const targetProgressPercent = target && target != 0 ? (forecastConversionRate / target) * 100 : 0;

  const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

  return (
    <>
      {!isMobile && (
        <>
          <WidgetMetricTilesContainer>
            {showForecast && (
              <WidgetMetricTile
                metricProperties={{
                  type: WidgetMetricType.ForecastMetric,
                  properties: {
                    metricValue: `${forecastConversionRate.toFixed(2)}%`,
                    forecastIndicator: getDefaultStatus(targetProgressPercent),
                  },
                }}
              />
            )}

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.RegularMetric,
                properties: {
                  metricTitle: 'KPI',
                  metricValue: t(
                    kpiTargetTransalations.kpiWidgets.dailyConversionRate.key,
                    kpiTargetTransalations.kpiWidgets.dailyConversionRate.defaultValue,
                  ),
                },
              }}
            />

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.LegendMetric,
                properties: {
                  metricTitle: t(
                    homeTranslations.conversionRate.title.key,
                    homeTranslations.conversionRate.title.defaultValue,
                  ),
                  metricValue: `${today.toFixed(2)}%`,
                  legendColor: 'validationGreen',
                },
              }}
            />

            {target !== null && (
              <WidgetMetricTile
                metricProperties={{
                  type: WidgetMetricType.LegendMetric,
                  properties: {
                    metricTitle: t(
                      kpiTargetTransalations.kpiProperties.target.title.key,
                      kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                    ),
                    metricValue: `${target}%`,
                    legendColor: 'tagBlue',
                  },
                }}
              />
            )}

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.RegularMetric,
                properties: {
                  metricTitle: t(commontTranslations.lastWeek.key, commontTranslations.lastWeek.defaultValue),
                  metricValue: (
                    <StyledTrendIconComponent
                      originalAmount={today}
                      previousAmount={lastWeek}
                      direction="horizontal"
                      valueFormatter={(value) => `${value.toFixed(2)}%`}
                      textOptions={{ type: 'caption' }}
                      hasPercentage={false}
                    />
                  ),
                },
              }}
            />

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.RegularMetric,
                properties: {
                  metricTitle: t(commontTranslations.lastYear.key, commontTranslations.lastYear.defaultValue),
                  metricValue: (
                    <StyledTrendIconComponent
                      originalAmount={today}
                      previousAmount={lastYear}
                      direction="horizontal"
                      valueFormatter={(value) => `${value.toFixed(2)}%`}
                      textOptions={{ type: 'caption' }}
                      hasPercentage={false}
                    />
                  ),
                },
              }}
            />
          </WidgetMetricTilesContainer>

          <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
            <WidgetChart
              type="bar"
              performanceItemsChartName={t(
                conversionRateTranslation.title.key,
                conversionRateTranslation.title.defaultValue,
              )}
              performanceItems={performanceItems}
              filteredPerformanceItems={filteredPerformanceItems}
              forecastPerformanceItems={forecastPerformanceItems}
              targetItems={targetItems}
              itemValueResolver={itemValueResolver}
              showXAxis={true}
              yAxisFormatter={(value) => `${Math.floor(value)}%`}
              tooltipFormatter={(value) => `${value.toFixed(2)}%`}
              dataLabel={{
                enabled: true,
                enabledOnSeries: [0, 2],
                offsetY: -28,
                style: {
                  fontSize: isMobile ? '8px' : '12px',
                  fontWeight: '100',
                  colors: [mode === 'dark' ? muiTheme.color.white : muiTheme.color.primary],
                },
                formatter: function (value) {
                  return value ? value + '%' : '';
                },
              }}
            />
          </Container>
        </>
      )}
      {isMobile && (
        <Container padding={[2, 6]} wrap="nowrap">
          {showForecast && (
            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.ForecastMetric,
                properties: {
                  metricValue: `${forecastConversionRate.toFixed(2)}%`,
                  forecastIndicator: getDefaultStatus(targetProgressPercent),
                },
              }}
            />
          )}

          <WidgetMobileLayout
            metricItems={[
              {
                label: 'KPI',
                content: t(
                  kpiTargetTransalations.kpiWidgets.dailyConversionRate.key,
                  kpiTargetTransalations.kpiWidgets.dailyConversionRate.defaultValue,
                ),
              },
              {
                label: t(conversionRateTranslation.title.key, conversionRateTranslation.title.defaultValue),
                content: `${today.toFixed(2)}%`,
              },

              {
                label: t(
                  kpiTargetTransalations.kpiProperties.target.title.key,
                  kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                ),
                content: target === null ? `-` : `${target.toFixed(2)}%`,
              },

              {
                label: t(commontTranslations.lastWeek.key, commontTranslations.lastWeek.defaultValue),
                content: (
                  <StyledTrendIconComponent
                    originalAmount={today}
                    previousAmount={lastWeek}
                    direction="horizontal"
                    valueFormatter={(value) => `${value.toFixed(2)}%`}
                    textOptions={{ type: 'caption' }}
                    hasPercentage={false}
                  />
                ),
              },
              {
                label: t(commontTranslations.lastYear.key, commontTranslations.lastYear.defaultValue),
                content: (
                  <StyledTrendIconComponent
                    originalAmount={today}
                    previousAmount={lastYear}
                    direction="horizontal"
                    valueFormatter={(value) => `${value.toFixed(2)}%`}
                    textOptions={{ type: 'caption' }}
                    hasPercentage={false}
                  />
                ),
              },
            ]}
            renderChart={() => {
              return (
                <WidgetChart
                  type="bar"
                  performanceItemsChartName={t(
                    conversionRateTranslation.title.key,
                    conversionRateTranslation.title.defaultValue,
                  )}
                  performanceItems={performanceItems}
                  filteredPerformanceItems={filteredPerformanceItems}
                  forecastPerformanceItems={forecastPerformanceItems}
                  targetItems={targetItems}
                  itemValueResolver={itemValueResolver}
                  showXAxis={true}
                  yAxisFormatter={(value) => `${Math.floor(value)}%`}
                  tooltipFormatter={(value) => `${value.toFixed(2)}%`}
                  dataLabel={{
                    enabled: false,
                  }}
                  sparklineEnabled
                  customYAxis={{ show: false }}
                />
              );
            }}
          />
        </Container>
      )}
    </>
  );
};
