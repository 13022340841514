import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  QueueLengthCheckoutExperience,
  QueueLengthForecastPerformanceItem,
  QueueLengthPerformanceItem,
  TillType,
} from '../../../../../hooks';
import { Container } from '../../../../../stories/atoms';
import { muiTheme } from '../../../../../theme';
import { homeTranslations, kpiTargetTransalations } from '../../../../../translations';
import { WidgetMetricTile, WidgetMetricType } from '../../../common/metricTile/WidgetMetricTile';
import { WidgetMetricTilesContainer } from '../../../common/metricTile/WidgetMetricTile.styles';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetMobileLayout } from '../../../common/widgetMobileLayout/WidgetMobileLayout';
import { calculatePercentage, getOverAllQueueInformation, isQueueLengthForecastPerformanceItem } from './util';

export const GraphView = ({
  target,
  checkoutExperience,
  performanceItems,
  filteredPerformanceItems,
  forecastPerformanceItems,
}: {
  target: number | null;
  checkoutExperience: QueueLengthCheckoutExperience | null;
  performanceItems: QueueLengthPerformanceItem[];
  filteredPerformanceItems: (QueueLengthPerformanceItem | null)[];
  forecastPerformanceItems: (QueueLengthPerformanceItem | null)[];
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const { queueLength: queueLengthTranslations } = homeTranslations;

  const getQueueLengthByTillType = (
    queueLengthPerformanceItems: QueueLengthPerformanceItem[],
    queueTillType: TillType,
  ) => {
    let totalQueueCount = 0;
    let totalTransactions = 0;

    queueLengthPerformanceItems.forEach((queueLengthPerformanceItem) => {
      const { tillData } = queueLengthPerformanceItem;

      tillData.forEach((queueLengthTillData) => {
        const { queueCount, transactions, tillType } = queueLengthTillData;

        if ((tillType === 'null' || tillType === 'DEFAULT') && queueTillType === 'DEFAULT') {
          totalQueueCount += queueCount;
          totalTransactions += transactions;
        }

        if (tillType === 'SELFCHECKOUT' && queueTillType === 'SELFCHECKOUT') {
          totalQueueCount += queueCount;
          totalTransactions += transactions;
        }
      });
    });

    return (totalTransactions === 0 ? 0 : totalQueueCount / totalTransactions) * 100;
  };

  const regularTillQueueLength = getQueueLengthByTillType(performanceItems, 'DEFAULT');
  const scoTillQueueLength = getQueueLengthByTillType(performanceItems, 'SELFCHECKOUT');

  const itemValueResolver = (
    storePerformanceItem: QueueLengthPerformanceItem,
    seriesIndex?: number | undefined,
  ): number => {
    // Forecast data type does not match the dnormal data type. Need to manually cast here
    const item = storePerformanceItem as QueueLengthPerformanceItem | QueueLengthForecastPerformanceItem;

    if (!isQueueLengthForecastPerformanceItem(item)) {
      let totalQueueCount = 0;
      let totalTransactions = 0;

      storePerformanceItem.tillData.forEach((tillData) => {
        if (seriesIndex === 0 && (tillData.tillType === 'DEFAULT' || tillData.tillType === 'null')) {
          totalQueueCount += tillData.queueCount;
          totalTransactions += tillData.transactions;
        }

        if (seriesIndex === 1 && tillData.tillType === 'SELFCHECKOUT') {
          totalQueueCount += tillData.queueCount;
          totalTransactions += tillData.transactions;
        }
      });

      return (totalTransactions === 0 ? 0 : totalQueueCount / totalTransactions) * 100;
    }

    const totalQueueCount = item.defaultQueueCount + item.scoQueueCount;
    const totalTransactions = item.defaultTransactionsCount + item.scoTransactionsCount;

    return (totalTransactions === 0 ? 0 : totalQueueCount / totalTransactions) * 100;
  };

  const generateChart = (isMobileChart: boolean) => {
    return (
      <WidgetChart
        type="bar"
        seriesCount={2}
        performanceItemsChartName={[
          t(queueLengthTranslations.tabDefault.key, queueLengthTranslations.tabDefault.defaultValue),
          t(queueLengthTranslations.tabSelfCheckout.key, queueLengthTranslations.tabSelfCheckout.defaultValue),
        ]}
        performanceItems={performanceItems}
        filteredPerformanceItems={filteredPerformanceItems}
        forecastPerformanceItems={forecastPerformanceItems}
        tooltipFormatter={(value) => `${value.toFixed(2)}%`}
        itemValueResolver={itemValueResolver}
        showXAxis={true}
        chartColors={['validationGreen', 'inActiveGray', 'tagBlue']}
        dashArray={[0, 0, 4]}
        yAxisFormatter={(value) => `${value.toFixed()}%`}
        sparklineEnabled={isMobileChart}
        customYAxis={{
          show: !isMobileChart,
        }}
      />
    );
  };

  const getForecastIndicator = (percent: number) => {
    if (percent <= 100) return 'HAPPY';
    if (percent <= 103) return 'CONFUSED';
    return 'SAD';
  };

  const { overallQueueLengthPercentage, overallExperiencePercentage, totalQueueCount, totalTransactions } =
    getOverAllQueueInformation({ checkoutExperience, filteredPerformanceItems });

  const forecastTotalQueueCount =
    (forecastPerformanceItems as unknown as (QueueLengthForecastPerformanceItem | null)[]).reduce((acc, item) => {
      if (item === null) return acc;

      return acc + item.defaultQueueCount + item.scoQueueCount;
    }, 0) + totalQueueCount;

  const forecastTotalTransactions =
    (forecastPerformanceItems as unknown as (QueueLengthForecastPerformanceItem | null)[]).reduce((acc, item) => {
      if (item === null) return acc;

      return acc + item.defaultTransactionsCount + item.scoTransactionsCount;
    }, 0) + totalTransactions;

  const forecastQueueLengthPercentage = calculatePercentage(forecastTotalQueueCount, forecastTotalTransactions);

  const targetProgressPercent = target && target != 0 ? (forecastQueueLengthPercentage / target) * 100 : 0;

  const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

  return (
    <Container
      padding={isMobile ? [2, 6] : [2]}
      wrap="nowrap"
      data-testid="chart"
      style={{ flex: 1, minHeight: 0 }}
    >
      {!isMobile && (
        <>
          <WidgetMetricTilesContainer>
            {showForecast && (
              <WidgetMetricTile
                metricProperties={{
                  type: WidgetMetricType.ForecastMetric,
                  properties: {
                    metricValue: `${forecastQueueLengthPercentage.toFixed(2)}%`,
                    forecastIndicator: getForecastIndicator(targetProgressPercent),
                  },
                }}
              />
            )}

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.RegularMetric,
                properties: {
                  metricTitle: 'KPI',
                  metricValue: t(
                    kpiTargetTransalations.kpiWidgets.dailyQueueLength.key,
                    kpiTargetTransalations.kpiWidgets.dailyQueueLength.defaultValue,
                  ),
                },
              }}
            />

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.RegularMetric,
                properties: {
                  metricTitle: t(
                    queueLengthTranslations.overallQueueLength.key,
                    queueLengthTranslations.overallQueueLength.defaultValue,
                  ),
                  metricValue: `${overallQueueLengthPercentage.toFixed(2)}%`,
                },
              }}
            />

            {target && (
              <WidgetMetricTile
                metricProperties={{
                  type: WidgetMetricType.RegularMetric,
                  properties: {
                    metricTitle: t(
                      kpiTargetTransalations.kpiProperties.target.title.key,
                      kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                    ),
                    metricValue: `${target}%`,
                  },
                }}
              />
            )}

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.RegularMetric,
                properties: {
                  metricTitle: t(
                    queueLengthTranslations.overallExperience.key,
                    queueLengthTranslations.overallExperience.defaultValue,
                  ),
                  metricValue: `${overallExperiencePercentage.toFixed(2)}%`,
                },
              }}
            />

            {/* Regular Till Queue Length */}
            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.LegendMetric,
                properties: {
                  metricTitle: t(
                    queueLengthTranslations.tabDefault.key,
                    queueLengthTranslations.tabDefault.defaultValue,
                  ),
                  metricValue: `${regularTillQueueLength.toFixed(2)}%`,
                  legendColor: 'validationGreen',
                },
              }}
            />

            {/* SCO Till Queue Length */}
            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.LegendMetric,
                properties: {
                  metricTitle: t(
                    queueLengthTranslations.tabSelfCheckout.key,
                    queueLengthTranslations.tabSelfCheckout.defaultValue,
                  ),
                  metricValue: `${scoTillQueueLength.toFixed(2)}%`,
                  legendColor: 'inActiveGray',
                },
              }}
            />
          </WidgetMetricTilesContainer>

          <div style={{ flex: 1 }}>{generateChart(false)}</div>
        </>
      )}

      {isMobile && (
        <>
          {showForecast && (
            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.ForecastMetric,
                properties: {
                  metricValue: `${forecastQueueLengthPercentage.toFixed(2)}%`,
                  forecastIndicator: getForecastIndicator(targetProgressPercent),
                },
              }}
            />
          )}

          <WidgetMobileLayout
            metricItems={[
              {
                label: 'KPI',
                content: t(queueLengthTranslations.title.key, queueLengthTranslations.title.defaultValue),
              },
              {
                label: t(
                  queueLengthTranslations.overallQueueLength.key,
                  queueLengthTranslations.overallQueueLength.defaultValue,
                ),
                content: `${overallQueueLengthPercentage.toFixed(2)}%`,
              },
              {
                label: t(
                  kpiTargetTransalations.kpiProperties.target.title.key,
                  kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                ),
                content: `${target !== null ? `${target}%` : '-'}`,
              },
              {
                label: t(
                  queueLengthTranslations.overallExperience.key,
                  queueLengthTranslations.overallExperience.defaultValue,
                ),
                content: `${overallExperiencePercentage.toFixed(2)}%`,
              },

              {
                label: t(queueLengthTranslations.tabDefault.key, queueLengthTranslations.tabDefault.defaultValue),
                content: `${regularTillQueueLength.toFixed(2)}%`,
              },
              {
                label: t(
                  queueLengthTranslations.tabSelfCheckout.key,
                  queueLengthTranslations.tabSelfCheckout.defaultValue,
                ),
                content: `${scoTillQueueLength.toFixed(2)}%`,
              },
            ]}
            renderChart={() => {
              return generateChart(true);
            }}
          />
        </>
      )}
    </Container>
  );
};
