import { rest } from 'msw';
import {
  baseUrl,
  rfidApprovalsEndpoint,
  rfidCountEndpoint,
  rfidEndpoint,
  rfidSalesRetaggingEndpoint,
} from '../../constants';
import {
  getFetchRfidApprovalsDataMockResponse,
  getFetchRfidSalesRetaggingDataMockResponse,
  getMockRfidCountData,
} from '../mocks';

export const fetchRfidCountData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${rfidEndpoint}${rfidCountEndpoint}`, (_, res, ctx) => {
    const mockData = getMockRfidCountData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const mockRfidApprovalsData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${rfidEndpoint}${rfidApprovalsEndpoint}`, (_, res, ctx) => {
    const mockData = getFetchRfidApprovalsDataMockResponse();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const mockRfidSalesRetaggingData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${rfidEndpoint}${rfidSalesRetaggingEndpoint}`, (_, res, ctx) => {
    const mockData = getFetchRfidSalesRetaggingDataMockResponse();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
