import { rest } from 'msw';
import {
  areaComparisonEndpoint,
  areaKpiEndpoint,
  assetManagementWidgetEndpoint,
  backofficeAIEndpoint,
  baseUrl,
  feedBackEndpoint,
  forecastEndpoint,
  lastKpiEndpoint,
  profitAndLossEndpoint,
  storeEndpoint,
  targetEndpoint,
  weatherWidgetEndpoint,
} from '../../constants';
import { MockWidgetKpi, mockKpiTargets, mockLastKpi } from '../mocks';
import { getMockChatBotAnswers } from '../mocks/chatBot.mock';
import {
  forecastData,
  getMockAreaComparisonData,
  getMockAssetManagementData,
  getMockProfitLossData,
  getMockWeatherData,
  mockStores,
  mockStoresDetails,
} from '../mocks/store.mock';

const { storeId } = mockStores[0];

export const fetchStoreDetails = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockStoresDetails),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchStoreData = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockStores[0]),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchKpisAreaDetails = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${areaKpiEndpoint}`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(MockWidgetKpi),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchAssetManagementWidgetData = (addDelay = false) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${assetManagementWidgetEndpoint}`, (_, res, ctx) => {
    const mockData = getMockAssetManagementData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchWeatherWidgetData = (addDelay = false) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${weatherWidgetEndpoint}`, (_, res, ctx) => {
    const mockData = getMockWeatherData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchChatBotData = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${backofficeAIEndpoint}`, (req, res, ctx) => {
    const id = req.url.searchParams.get('question') as string;

    return res(
      ctx.status(200),
      ctx.json(id && getMockChatBotAnswers()),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 1000 : 0),
    );
  });

export const updateKpiData = (addDelay?: boolean) =>
  rest.put(`${storeEndpoint}/${storeId}${targetEndpoint}`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.set('Content-Type', 'application/json'), ctx.delay(addDelay ? 500 : 0));
  });

export const fetchForecastForKpis = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${forecastEndpoint}/:kpi`, (_req, res, ctx) => {
    const mockdata = forecastData(_req.params.kpi as string);

    return res(
      ctx.status(200),
      ctx.json(mockdata),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const sendFeedbackData = (addDelay?: boolean) =>
  rest.put(`${storeEndpoint}/${storeId}${feedBackEndpoint}`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.set('Content-Type', 'application/json'), ctx.delay(addDelay ? 500 : 0));
  });

export const fetchKpiTargets = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${targetEndpoint}`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockKpiTargets()),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchAreaComparisonData = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${areaComparisonEndpoint}`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getMockAreaComparisonData()),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchProfitLossData = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${profitAndLossEndpoint}`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getMockProfitLossData()),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchLastKpi = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${storeEndpoint}/:storeId${targetEndpoint}${lastKpiEndpoint}`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockLastKpi()),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
