import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: ${({ theme: { spacing } }) => spacing(37)};
`;

export const LayoutSection = styled.div`
  display: flex;
  padding: ${({ theme: { spacing } }) => spacing(2, 0)};
  justify-content: space-between;
  align-items: center;
`;

export const LayoutChart = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(0, 2, 0)};
  height: ${({ theme: { spacing } }) => spacing(24)};
  opacity: 0.4;
`;
