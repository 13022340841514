import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import {
  NewMembersPerformanceItem,
  useKpiTargetDistriubution,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { muiTheme } from '../../../../../theme';
import { homeTranslations, kpiTargetTransalations } from '../../../../../translations';
import { getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { WidgetMetricTile, WidgetMetricType } from '../../../common/metricTile/WidgetMetricTile';
import { WidgetMetricTilesContainer } from '../../../common/metricTile/WidgetMetricTile.styles';
import { getDefaultStatus } from '../../../common/metricTile/util';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetMobileLayout } from '../../../common/widgetMobileLayout/WidgetMobileLayout';
import { StyledWrapper } from './NewMembersWidget.styles';

export type NewMembersProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const NewMembersWidget: FC<NewMembersProps> = observer(
  ({ interval, currentDate, hasPermissionToEditKpi, isToday, handleDropdownDetails, fromDate, toDate }) => {
    const { t } = useTranslation();
    const {
      get: { kpiTargetsProps },
    } = useUser();

    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();

    const { fetchNewMembersData } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { transactionEventsCount } = useEventContext();

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<NewMembersPerformanceItem>();

    const { kpiTargets, newMembers } = homeTranslations;

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyNewMembers');
    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyNewMembers')?.isLocked ?? false;

    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchNewMembersData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.newMembers;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<NewMembersPerformanceItem>('newMembers');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    const itemValueResolver = (item: NewMembersPerformanceItem) => {
      return item.newMembersCount;
    };

    const getTotalNewMembersCount = (items: (NewMembersPerformanceItem | null)[]) => {
      return items.reduce((acc, val) => acc + (val ? val.newMembersCount : 0), 0);
    };

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getForecastDataAction: fetchForecastData,
        getHasValue: (item) => item.totalTransactions !== 0,
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, isToday, currentDate, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailyNewMembers',
    });

    const totalNewMemberCount = getTotalNewMembersCount(performanceItems);
    const forecastTotalNewMemberCount = getTotalNewMembersCount([
      ...performanceItems,
      ...forecastPerformanceItems,
    ]);

    const totalNMTransactions = performanceItems.reduce(
      (acc, { totalTransactions }) => acc + totalTransactions,
      0,
    );

    const totalUnrecruitedValue = performanceItems.reduce((acc, { unrecruited }) => acc + unrecruited, 0);

    const totalNewMemberShare = totalNMTransactions === 0 ? 0 : (totalNewMemberCount / totalNMTransactions) * 100;
    const totalUnrecruitedPercentage =
      totalNMTransactions === 0 ? 0 : (totalUnrecruitedValue / totalNMTransactions) * 100;

    const targetProgressPercent = target && target != 0 ? (forecastTotalNewMemberCount / target) * 100 : 0;

    const isEditKPIDisabled = performanceItemsError || !hasPermissionToEditKpi;

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);

    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    let maxNewMemberCount = 0;

    performanceItems.forEach((item) => {
      const { newMembersCount } = item;
      if (newMembersCount > maxNewMemberCount) maxNewMemberCount = newMembersCount;
    });

    targetItems.forEach((item) => {
      if (!item) return;
      if (item > maxNewMemberCount) maxNewMemberCount = item;
    });

    let yAxisTickAmount = Math.ceil(maxNewMemberCount);

    while (yAxisTickAmount / 4 > 1) {
      yAxisTickAmount = yAxisTickAmount / 2;
    }

    yAxisTickAmount = Math.ceil(yAxisTickAmount);

    const isPreviousDay = interval === StorePerformanceInterval.WEEKLY || !isToday;

    const menuItems = [
      {
        key: target ? 'editkpi' : 'addkpi',
        disabled: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        hasTooltip: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : isPreviousDay
                ? t(kpiTargets.previousDayTooltip.key, kpiTargets.previousDayTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <WidgetCardContent
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(newMembers.title.key, newMembers.title.defaultValue)}
        subHeaderText={t(newMembers.subTitle.key, newMembers.subTitle.defaultValue)}
        lastUpdatedDate={lastUpdated}
        isLoading={performanceItemsLoading}
        isError={performanceItemsError}
        errorText={t(newMembers.error.key, newMembers.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData}
        headerSideContent={
          <StyledWrapper>
            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={menuItems}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItem) => {
                handleDropdownDetails('dailyNewMembers', selectedItem[0] as KpiDropdownActions);
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledWrapper>
        }
        content={
          <>
            {!isMobile && (
              <Container wrap="nowrap" padding={[3, 4]} data-testid="new-members-wrapper" height="100%">
                <WidgetMetricTilesContainer>
                  {showForecast && (
                    <WidgetMetricTile
                      metricProperties={{
                        type: WidgetMetricType.ForecastMetric,
                        properties: {
                          metricValue: `${Math.trunc(Math.round(forecastTotalNewMemberCount))}`,
                          forecastIndicator: getDefaultStatus(targetProgressPercent),
                        },
                      }}
                    />
                  )}

                  <WidgetMetricTile
                    metricProperties={{
                      type: WidgetMetricType.RegularMetric,
                      properties: {
                        metricTitle: 'KPI',
                        metricValue: t(
                          kpiTargetTransalations.kpiWidgets.dailyNewMembers.key,
                          kpiTargetTransalations.kpiWidgets.dailyNewMembers.defaultValue,
                        ),
                      },
                    }}
                  />

                  <WidgetMetricTile
                    metricProperties={{
                      type: WidgetMetricType.LegendMetric,
                      properties: {
                        metricTitle: t(
                          homeTranslations.newMembers.totalNewMembers.key,
                          homeTranslations.newMembers.totalNewMembers.defaultValue,
                        ),
                        metricValue: `${Math.round(totalNewMemberCount)}`,
                        legendColor: 'validationGreen',
                      },
                    }}
                  />

                  {target !== null && (
                    <WidgetMetricTile
                      metricProperties={{
                        type: WidgetMetricType.LegendMetric,
                        properties: {
                          metricTitle: t(
                            kpiTargetTransalations.kpiProperties.target.title.key,
                            kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                          ),
                          metricValue: `${target}`,
                          legendColor: 'tagBlue',
                        },
                      }}
                    />
                  )}

                  <WidgetMetricTile
                    metricProperties={{
                      type: WidgetMetricType.RegularMetric,
                      properties: {
                        metricTitle: t(newMembers.totalNMShare.key, newMembers.totalNMShare.defaultValue),
                        metricValue: `${totalNewMemberShare.toFixed(2)}%`,
                      },
                    }}
                  />

                  <WidgetMetricTile
                    metricProperties={{
                      type: WidgetMetricType.RegularMetric,
                      properties: {
                        metricTitle: t(newMembers.unrecruited.key, newMembers.unrecruited.defaultValue),
                        metricValue: `${totalUnrecruitedPercentage.toFixed(2)}%`,
                      },
                    }}
                  />
                </WidgetMetricTilesContainer>

                <Container wrap="nowrap" data-testid="chart" height="100%">
                  <WidgetChart
                    performanceItemsChartName={t(newMembers.title.key, newMembers.title.defaultValue)}
                    performanceItems={performanceItems}
                    filteredPerformanceItems={filteredPerformanceItems}
                    forecastPerformanceItems={forecastPerformanceItems}
                    targetItems={targetItems}
                    itemValueResolver={itemValueResolver}
                    showXAxis={true}
                    yAxisTickAmount={yAxisTickAmount || 1}
                    yAxisMax={Math.ceil(maxNewMemberCount) || 1}
                    tooltipFormatter={(value) => `${Math.ceil(value)}`}
                    yAxisFormatter={(value) => `${value.toFixed(0)}`}
                  />
                </Container>
              </Container>
            )}

            {isMobile && (
              <Container padding={[2, 6]} wrap="nowrap" data-testid="chart" style={{ flex: 1, minHeight: 0 }}>
                {showForecast && (
                  <WidgetMetricTile
                    metricProperties={{
                      type: WidgetMetricType.ForecastMetric,
                      properties: {
                        metricValue: `${Math.trunc(Math.round(forecastTotalNewMemberCount))}`,
                        forecastIndicator: getDefaultStatus(targetProgressPercent),
                      },
                    }}
                  />
                )}

                <WidgetMobileLayout
                  metricItems={[
                    {
                      label: 'KPI',
                      content: t(
                        kpiTargetTransalations.kpiWidgets.dailyNewMembers.key,
                        kpiTargetTransalations.kpiWidgets.dailyNewMembers.defaultValue,
                      ),
                    },
                    {
                      label: t(
                        homeTranslations.newMembers.totalNewMembers.key,
                        homeTranslations.newMembers.totalNewMembers.defaultValue,
                      ),
                      content: `${Math.round(totalNewMemberCount)}`,
                    },
                    {
                      label: t(
                        kpiTargetTransalations.kpiProperties.target.title.key,
                        kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                      ),
                      content: target === null ? `-` : `${target}`,
                    },

                    {
                      label: t(newMembers.totalNMShare.key, newMembers.totalNMShare.defaultValue),
                      content: `${totalNewMemberShare.toFixed(2)}%`,
                    },
                    {
                      label: t(newMembers.unrecruited.key, newMembers.unrecruited.defaultValue),
                      content: `${totalUnrecruitedPercentage.toFixed(2)}%`,
                    },
                  ]}
                  renderChart={() => {
                    return (
                      <WidgetChart
                        performanceItemsChartName={t(newMembers.title.key, newMembers.title.defaultValue)}
                        performanceItems={performanceItems}
                        filteredPerformanceItems={filteredPerformanceItems}
                        forecastPerformanceItems={forecastPerformanceItems}
                        targetItems={targetItems}
                        itemValueResolver={itemValueResolver}
                        showXAxis={true}
                        yAxisTickAmount={yAxisTickAmount || 1}
                        yAxisMax={Math.ceil(maxNewMemberCount) || 1}
                        tooltipFormatter={(value) => `${Math.ceil(value)}`}
                        yAxisFormatter={(value) => `${value.toFixed(0)}`}
                        sparklineEnabled
                        customYAxis={{ show: false }}
                      />
                    );
                  }}
                />
              </Container>
            )}
          </>
        }
        toolTipText={t(newMembers.toolTip.key, newMembers.toolTip.defaultValue)}
      />
    );
  },
);
