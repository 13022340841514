import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../../../../stories/atoms';
import { Charts } from '../../../../../../stories/molecules/charts/Charts';
import { muiTheme } from '../../../../../../theme';
import { homeTranslations } from '../../../../../../translations';
import { formatValue } from '../../../../../../utils';
import { CustomerEngagementTranslation, EngagementMetrics } from '../CustomerEngagement';
import {
  StyledChartText,
  StyledIconWrapper,
  StyledMetricTile,
  StyledTrafficContainer,
  StyledTypography,
} from '../CustomerEngagement.styles';

const { customerEngagement: customerEngagementranslations } = homeTranslations;

type EngagementMetricTooltipProps = {
  metricName: EngagementMetrics;
  tooltipText: string;
  icon: Icons;
  isMobileView: boolean;
  value: string;
};

const EngagementMetricTooltip: React.FC<EngagementMetricTooltipProps> = ({
  metricName,
  tooltipText,
  icon,
  isMobileView,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      arrowedToolTip
      text={
        <Container padding={[1, 2]}>
          <Typography color="white">
            {t(
              customerEngagementranslations[tooltipText as CustomerEngagementTranslation].key,
              customerEngagementranslations[tooltipText as CustomerEngagementTranslation].defaultValue,
            )}
            : {value}%
          </Typography>
        </Container>
      }
    >
      <StyledMetricTile metricName={metricName} isMobileView={isMobileView}>
        <StyledIconWrapper metricName={metricName} isMobileView={isMobileView}>
          <Icon icon={icon} size="small" />
        </StyledIconWrapper>
        <Typography type={isMobileView ? 'caption' : 'body2'}>{value}%</Typography>
      </StyledMetricTile>
    </Tooltip>
  );
};

export type EngagementOverviewProps = {
  totalCustomerEngaged: number;
  storeTraffic: number;
  memberRecruited: number;
  memberIdentification: number;
  articleLookups: number;
};

export const EngagementOverview: FC<EngagementOverviewProps> = ({
  totalCustomerEngaged,
  storeTraffic,
  memberRecruited,
  memberIdentification,
  articleLookups,
}) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const customerEngagementPercentage = storeTraffic === 0 ? 0 : (totalCustomerEngaged / storeTraffic) * 100;

  const memberRecruitedPercentage =
    totalCustomerEngaged === 0 ? 0 : (memberRecruited / totalCustomerEngaged) * 100;
  const memberIdentificationPercentage =
    totalCustomerEngaged === 0 ? 0 : (memberIdentification / totalCustomerEngaged) * 100;
  const articleLookupsPercentage = totalCustomerEngaged === 0 ? 0 : (articleLookups / totalCustomerEngaged) * 100;

  return (
    <Container data-testid="engagment-overview-wrapper" wrap="nowrap" width={isMobileView ? '40%' : '35%'}>
      <Container wrap="nowrap" direction={isMobileView ? 'vertical' : 'horizontal'}>
        <StyledTypography>
          {t(customerEngagementranslations.title.key, customerEngagementranslations.title.defaultValue)}%
        </StyledTypography>
        <StyledTypography textAlign={isMobileView ? 'start' : 'end'} type="h4">
          {customerEngagementPercentage.toFixed(2)}%
        </StyledTypography>
      </Container>
      <Container
        height={isMobileView ? '70%' : '100%'}
        style={{ position: 'relative', flex: isMobileView ? 0 : 1 }}
        data-testid="customer-engagement-chart"
      >
        <Charts
          chartType="donut"
          chartSeries={[memberRecruitedPercentage, memberIdentificationPercentage, articleLookupsPercentage]}
          xAxisCategories={['', '', '']}
          areaChartGradient={{
            type: ['solid'],
          }}
          chartColors={['info', 'warning', 'successLight']}
          chartPlotOptions={{
            customScale: isMobileView ? 1.1 : 1.1,
            startAngle: isMobileView ? -90 : 0,
            endAngle: isMobileView ? 90 : 360,
            offsetY: 15,
            donut: {
              size: isMobileView ? '70%' : '90%',
              labels: {
                show: false,
              },
            },
          }}
          legend={{
            show: false,
          }}
          strokeWidth={[0]}
        />
        <StyledChartText isMobileView={isMobileView}>
          <Typography type={isMobileView ? 'body2' : 'legal'}>
            {t(
              customerEngagementranslations.customerEngaged.key,
              customerEngagementranslations.customerEngaged.defaultValue,
            )}
          </Typography>
          <Typography type="button">
            {formatValue(totalCustomerEngaged, {
              options: { minimumFractionDigits: 0, notation: 'compact' },
            })}
          </Typography>
        </StyledChartText>
      </Container>

      <Container wrap="nowrap" direction="horizontal" space="between">
        <EngagementMetricTooltip
          key="memberRecruitedTooltip1"
          metricName="memberRecruited"
          tooltipText="memberRecruited"
          icon={Icons.NEWMEMBER}
          isMobileView={isMobileView}
          value={memberRecruitedPercentage.toFixed()}
        />
        <EngagementMetricTooltip
          key="memberIdentificationTooltip1"
          metricName="memberIdentification"
          tooltipText="membersIdentified"
          icon={Icons.BAG}
          isMobileView={isMobileView}
          value={memberIdentificationPercentage.toFixed()}
        />
        <EngagementMetricTooltip
          key="articleLookupsTooltip1"
          metricName="articleLookups"
          tooltipText="articleLookups"
          icon={Icons.SEARCH}
          isMobileView={isMobileView}
          value={articleLookupsPercentage.toFixed()}
        />
      </Container>
      <StyledTrafficContainer>
        <Container
          wrap="nowrap"
          direction={isMobileView ? 'vertical' : 'horizontal'}
          position={isMobileView ? 'left' : 'center'}
        >
          <Typography type="legal" color="textGray">
            Store Traffic{' '}
          </Typography>
          <Typography>
            {formatValue(storeTraffic, {
              options: { minimumFractionDigits: 0, notation: 'compact' },
            })}
          </Typography>
        </Container>
        <Tooltip
          arrowedToolTip
          text={
            <Container padding={[1, 2]}>
              <Typography color="white">
                {t(
                  customerEngagementranslations.storeTrafficTooltip.key,
                  customerEngagementranslations.storeTrafficTooltip.defaultValue,
                )}
              </Typography>
            </Container>
          }
        >
          <Icon icon={Icons.INFO} size="small" />
        </Tooltip>
      </StyledTrafficContainer>
    </Container>
  );
};
