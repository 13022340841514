import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  hasPercentageinKpi,
  KpiEditTypes,
  KpiModalType,
  KpiModalViews,
  KpiToApiMapping,
  KpiType,
  UpdateKpiRequest,
} from '../../constants/kpiTarget';
import { Icon, Icons, Typography } from '../../stories/atoms';
import { Button, Modal } from '../../stories/molecules';
import { muiTheme } from '../../theme';
import { commonTranslations, homeTranslations, kpiTargetTransalations } from '../../translations';
import { StyledContainer, StyledDiv } from './KpiModal.styles';
import { EditKpiView, RemoveKpiView } from './KpiView';

type TranslationMapping = {
  [key in KpiModalType]: {
    [subKey in KpiEditTypes]: string;
  };
};

export type KPIModalProps = {
  isOpen: boolean;
  kpiType: KpiType;
  updateType: KpiEditTypes;
  isAreaCap?: boolean;
  isModalLoading?: boolean;
  modalView: KpiModalViews;
  onKpiModalRetry: () => void;
  onKpiModalClose: () => void;
  handleUpdateKpi: (updateRequest: UpdateKpiRequest[], isDelete: boolean) => void;
};

const KpiModal: FC<KPIModalProps> = ({
  isOpen,
  updateType,
  kpiType,
  isAreaCap = false,
  isModalLoading = false,
  modalView,
  onKpiModalRetry,
  onKpiModalClose,
  handleUpdateKpi,
}) => {
  const { t } = useTranslation();
  const { kpiTargets } = homeTranslations;
  const { close, tryAgain } = commonTranslations;
  const { kpiDropdown } = kpiTargetTransalations;
  const widgetName = KpiToApiMapping[kpiType].widgetName;

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const hasPercentage = hasPercentageinKpi(kpiType);

  // Header translation Mapping
  const headerTranslationMapping: TranslationMapping = {
    [KpiModalType.AREA_CAP]: {
      [KpiEditTypes.ADD]: t(kpiDropdown.addAreaCap.key, kpiDropdown.addAreaCap.defaultValue),
      [KpiEditTypes.UPDATE]: t(kpiTargets.editAreaCap.key, kpiTargets.editAreaCap.defaultValue),
      [KpiEditTypes.REMOVE]: t(kpiDropdown.removeAreaCap.key, kpiDropdown.removeAreaCap.defaultValue),
    },
    [KpiModalType.KPI]: {
      [KpiEditTypes.ADD]: t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue),
      [KpiEditTypes.UPDATE]: t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue),
      [KpiEditTypes.REMOVE]: t(kpiDropdown.removeKpi.key, kpiDropdown.removeKpi.defaultValue),
    },
  };

  const modalType = isAreaCap ? KpiModalType.AREA_CAP : KpiModalType.KPI;
  const headerText = headerTranslationMapping[modalType][updateType];

  const handleKpiModalSubmit = async (isKpiDelete: boolean, newKpi?: number | null) => {
    const updateRequest = isAreaCap
      ? [{ kpiName: kpiType, areaCap: newKpi ? newKpi : null }]
      : [{ kpiName: kpiType, target: newKpi ? newKpi : null }];

    handleUpdateKpi(updateRequest, isKpiDelete);
  };

  return (
    <Modal isOpen={isOpen} handleClose={onKpiModalClose}>
      <StyledContainer
        isMobileView={isMobile}
        shouldRemove={updateType}
        view={modalView}
        data-testid={`kpi-${modalView}-wrapper`}
      >
        {modalView === KpiModalViews.SUCCESS && (
          <>
            <Icon icon={Icons.VALIDACTION} size="x-large" color="validationGreen" />
            <Typography gutterBottom textAlign="center" data-testid="kpiStatus">
              {t(kpiTargets.successText.key, {
                defaultValue: kpiTargets.successText.defaultValue,
                modalType,
                widgetName,
                operation:
                  updateType === KpiEditTypes.ADD
                    ? 'added'
                    : updateType === KpiEditTypes.UPDATE
                    ? 'updated'
                    : 'removed',
              })}
            </Typography>
            <Button label={t(close.key, close.defaultValue)} fullWidth onClick={onKpiModalClose} />
          </>
        )}

        {modalView === KpiModalViews.ERROR && (
          <>
            <Icon icon={Icons.REFRESH} size="x-large" color="red" />
            <Typography gutterBottom textAlign="center" data-testid="kpiStatus">
              {t(kpiTargets.errorText.key, { defaultValue: kpiTargets.errorText.defaultValue, modalType })}
            </Typography>
            <StyledDiv>
              <Button
                variant="secondary"
                label={t(tryAgain.key, tryAgain.defaultValue)}
                fullWidth
                onClick={onKpiModalRetry}
              />
              <Button label={t(close.key, close.defaultValue)} fullWidth onClick={onKpiModalClose} />
            </StyledDiv>
          </>
        )}

        {modalView === KpiModalViews.INITIAL && updateType === KpiEditTypes.REMOVE && (
          <RemoveKpiView
            modalType={modalType}
            kpiName={kpiType}
            isLoading={isModalLoading}
            removeKpi={(newKpi) => handleKpiModalSubmit(true, newKpi)}
            onKpiModalClose={onKpiModalClose}
          />
        )}

        {modalView === KpiModalViews.INITIAL && updateType !== KpiEditTypes.REMOVE && (
          <EditKpiView
            isOpen={isOpen}
            modalType={modalType}
            kpiName={kpiType}
            isLoading={isModalLoading}
            hasPercentage={hasPercentage}
            headerText={headerText}
            updateKpi={(newKpi) => handleKpiModalSubmit(false, newKpi)}
          />
        )}
      </StyledContainer>
    </Modal>
  );
};

export default KpiModal;
