import styled from 'styled-components';
import { Colors, color } from '../../../../../theme';

export const GridContainer = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => spacing(4, 4, 4, 2)};
`;

export const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { spacing } }) => spacing(1)};
`;

export const GridCell = styled.div<{ gridColor?: Colors }>`
  flex: 1;
  height: ${({ theme: { spacing } }) => spacing(7)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ gridColor }) => (gridColor ? color[gridColor] : 'inherit')};
  margin-bottom: ${({ theme: { spacing } }) => spacing(1)};
`;

export const InnerGridContainer = styled.div`
  flex: 1;
  overflow: scroll;
`;
