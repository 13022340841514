import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KpiModalType, KpiToApiMapping, KpiType, getErrorStatus } from '../../constants/kpiTarget';
import { Icon, Icons, Typography } from '../../stories/atoms';
import { Button, InputField } from '../../stories/molecules';
import { commonTranslations, homeTranslations } from '../../translations';
import { StyledDiv, StyledInputField, StyledTypography } from './KpiModal.styles';

type KpiViewProps = {
  modalType: KpiModalType;
  kpiName: KpiType;
  isLoading: boolean;
};

export type EditKpiViewProps = KpiViewProps & {
  isOpen: boolean;
  hasPercentage: boolean;
  headerText: string;
  updateKpi: (newValue: number) => void;
};

export type RemoveKpiViewProps = KpiViewProps & {
  removeKpi: (newValue: null) => void;
  onKpiModalClose: () => void;
};

export const EditKpiView: FC<EditKpiViewProps> = ({
  isOpen,
  hasPercentage,
  headerText,
  modalType,
  kpiName,
  isLoading,
  updateKpi,
}) => {
  const { t } = useTranslation();
  const { kpiTargets } = homeTranslations;
  const { confirm } = commonTranslations;
  const [kpiValue, setKpiValue] = useState('');
  const widgetName = KpiToApiMapping[kpiName].widgetName;

  const kpiValidationError = getErrorStatus(hasPercentage, kpiValue, kpiName);

  const kpiErrorTextKey = kpiValidationError.text ? (kpiValidationError.text as keyof typeof kpiTargets) : null;

  useEffect(() => {
    if (!isOpen) {
      setKpiValue('');
    }
  }, [isOpen]);

  const handleUpdateKpi = () => {
    const hasDecimalValues = /\.\d+/.test(kpiValue);
    updateKpi(hasDecimalValues ? parseFloat(kpiValue) : parseInt(kpiValue));
  };

  return (
    <div data-testid="edit-view-wrapper">
      <Typography type="h4" gutterBottom>
        {headerText}
      </Typography>
      {modalType === KpiModalType.AREA_CAP && (
        <StyledTypography type="body3" padding={[2]} gutterBottom>
          {t(kpiTargets.note.key, kpiTargets.note.defaultValue)}
        </StyledTypography>
      )}
      <StyledInputField>
        <InputField
          data-testid="edit-view-input"
          label={t(kpiTargets.labelText.key, {
            defaultValue: kpiTargets.labelText.defaultValue,
            modalType,
            widgetName,
          })}
          placeholder="Enter Value"
          onChange={(value) => {
            setKpiValue(value);
          }}
          helperText={
            kpiErrorTextKey !== null
              ? t(kpiTargets[kpiErrorTextKey].key, {
                  defaultValue: kpiTargets[kpiErrorTextKey].defaultValue,
                  modalType,
                })
              : ''
          }
          tabIndex={0}
          status={kpiValidationError.status ? 'error' : 'success'}
          size="fullWidth"
          initialValue={kpiValue}
          isRequired
        />
      </StyledInputField>
      <Button
        label={t(confirm.key, confirm.defaultValue)}
        fullWidth
        disabled={kpiValue === '' || kpiValidationError.status}
        onClick={handleUpdateKpi}
        isLoading={isLoading}
      />
    </div>
  );
};

export const RemoveKpiView: FC<RemoveKpiViewProps> = ({
  modalType,
  kpiName,
  isLoading,
  removeKpi,
  onKpiModalClose,
}) => {
  const { t } = useTranslation();
  const { kpiTargets } = homeTranslations;
  const { close, confirm } = commonTranslations;
  const widgetName = KpiToApiMapping[kpiName].widgetName;

  return (
    <>
      <Icon icon={Icons.TRASHBIN} size="x-large" color="red" />
      <Typography gutterBottom textAlign="center" data-testid="remove-label-text">
        {t(kpiTargets.removeLabelText.key, {
          defaultValue: kpiTargets.removeLabelText.defaultValue,
          modalType,
          widgetName,
        })}
      </Typography>
      {modalType === KpiModalType.AREA_CAP && (
        <StyledTypography data-testid="areacap-note" type="body3" padding={[2]} gutterBottom>
          {t(kpiTargets.note.key, kpiTargets.note.defaultValue)}
        </StyledTypography>
      )}
      <StyledDiv>
        <Button
          data-testid="confirm-button"
          label={t(confirm.key, confirm.defaultValue)}
          fullWidth
          onClick={() => removeKpi(null)}
          isLoading={isLoading}
        />
        <Button
          data-testid="close-button"
          variant="secondary"
          label={t(close.key, close.defaultValue)}
          fullWidth
          onClick={onKpiModalClose}
        />
      </StyledDiv>
    </>
  );
};
