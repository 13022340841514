import { useMediaQuery } from '@mui/material';
import { format, parse } from 'date-fns';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getKPIIcons, getUnit, KpiType } from '../../../constants';
import { useThemeContext } from '../../../global-state/themeContext';
import { LastKpiResponse, useAsyncAction, useStoreInformation, useStores, useUser } from '../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../stories/atoms';
import { Animation, Animations, Button } from '../../../stories/molecules';
import { Charts } from '../../../stories/molecules/charts/Charts';
import { muiTheme } from '../../../theme';
import { commonTranslations, kpiTargetTransalations } from '../../../translations';
import { formatAmountWithCurrency, isFailureResponse } from '../../../utils';
import { StyledDivider } from '../KpiTarget.styles';
import {
  FlexColumn,
  FlexRow,
  StyledContentWrapper,
  StyledDateContainer,
  StyledKpiHistoryWrapper,
  StyledPastKpiContainer,
  StyledTag,
  StyledTargetContainer,
  StyledTypography,
} from './KpiHistoryDashboard.styles';

export type KpiHistoryDashboardProps = {
  kpiType: KpiType;
  handleBack: () => void;
};

export const KpiHistoryDashboard: FC<KpiHistoryDashboardProps> = ({ kpiType, handleBack }) => {
  const { t, i18n } = useTranslation();
  const isTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const { kpiWidgets, lastKpi: lastKpiTranslations, kpiProperties } = kpiTargetTransalations;
  const { fromDate, toDate } = commonTranslations;
  const { currencyCode: storeCurrency } = useStoreInformation();
  const {
    get: { currentStoreId },
  } = useUser();
  const { mode } = useThemeContext();
  const { fetchLastKpi } = useStores();

  const {
    result: kpiHistoryDetails,
    isLoading: kpiHistoryLoading,
    isError: kpiHistoryError,
    triggerAction: triggerFetchLastKpis,
  } = useAsyncAction<LastKpiResponse>();

  // Fetch Last Kpis
  const fetchLastKpisData = async () => {
    const response = await fetchLastKpi(kpiType);

    if (isFailureResponse(response)) {
      throw new Error();
    }
    return response.data;
  };

  // Trigger initial Area Kpi Data
  useEffect(() => {
    triggerFetchLastKpis(fetchLastKpisData);
  }, []);

  const dateRanges =
    kpiHistoryDetails &&
    kpiHistoryDetails.last
      .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
      .map((item) => `${item.startDate}-${item.endDate}`);

  const targetRange = kpiHistoryDetails && kpiHistoryDetails.last.map((item) => item.target);

  const getFormattedValue = (value: number) => {
    const unit = getUnit(kpiType, storeCurrency);
    const formattedValue =
      unit === storeCurrency
        ? formatAmountWithCurrency(value, i18n.language, storeCurrency || '')
        : value.toLocaleString() + (unit ?? '');
    return formattedValue;
  };

  const chartSeries = [
    {
      name: t(kpiProperties.target.title.key, kpiProperties.target.title.defaultValue),
      data: targetRange ?? [],
    },
  ];

  const formatCustomDateRange = (dateRange: string) => {
    const [startYear, startMonth, startDay, endYear, endMonth, endDay] = dateRange.split('-');

    // Parse the dates into proper Date objects
    const startDate = parse(`${startYear}-${startMonth}-${startDay}`, 'yyyy-MM-dd', new Date());
    const endDate = parse(`${endYear}-${endMonth}-${endDay}`, 'yyyy-MM-dd', new Date());

    // Format the dates using date-fns
    const formattedDate = `${format(startDate, 'd MMM yy')} - ${format(endDate, 'd MMM yy')}`;

    return formattedDate;
  };

  return (
    <StyledKpiHistoryWrapper
      isTabletView={isTablet}
      isMobileView={isMobile}
      data-testid="kpi-history-page-wrapper"
    >
      <Button
        label={t(commonTranslations.back.key, commonTranslations.back.defaultValue)}
        data-testid="back-button"
        buttonContentProps={{
          iconOptions: {
            icon: Icons.ARROW,
            rotate: 'up',
            size: 'small',
          },
          textOptions: {
            whiteSpace: 'nowrap',
          },
        }}
        aria-haspopup="true"
        onClick={handleBack}
        variant="text"
      />
      <Typography type="button" margin={[0]}>
        {t(lastKpiTranslations.lastKpiText.key, {
          defaultValue: lastKpiTranslations.lastKpiText.defaultValue,
          storeId: currentStoreId,
        })}
      </Typography>

      {kpiHistoryLoading && (
        <Animation
          title={t(lastKpiTranslations.fetchingLastKpis.key, lastKpiTranslations.fetchingLastKpis.defaultValue)}
          animation={Animations.LOADING}
        />
      )}

      {kpiHistoryError && !kpiHistoryLoading && (
        <Animation
          title={t(lastKpiTranslations.error.errorTitle.key, lastKpiTranslations.error.errorTitle.defaultValue)}
          subtitle={t(
            lastKpiTranslations.error.errorSubtitle.key,
            lastKpiTranslations.error.errorSubtitle.defaultValue,
          )}
          animation={Animations.SAD}
        />
      )}

      {!kpiHistoryError && !kpiHistoryLoading && kpiHistoryDetails && (
        <StyledContentWrapper data-testid="kpi-history-page-content">
          <FlexRow data-testid="kpi-history-content-header">
            <Container wrap="nowrap" margin={[0, 2, 0, 0]}>
              <FlexColumn>
                <Icon icon={Icons[getKPIIcons(kpiType)]} size="medium" margin={[0, 2, 1, 0]} />
                <Typography type={isMobile ? 'body3' : 'h4'}>
                  {t(kpiWidgets[kpiType].key, kpiWidgets[kpiType].defaultValue)}
                </Typography>
              </FlexColumn>

              <Container margin={[1, 0, 3]}>
                <Typography type="h4" color={kpiHistoryDetails?.current.target ? 'primary' : 'textGray'}>
                  {kpiHistoryDetails?.current.target
                    ? getFormattedValue(kpiHistoryDetails?.current.target)
                    : t(kpiTargetTransalations.notSet.key, kpiTargetTransalations.notSet.defaultValue)}
                </Typography>
                <StyledTag textColor={mode === 'dark' ? 'successLight' : 'validationGreen'}>
                  <Typography type="legal">
                    {t(lastKpiTranslations.latestText.key, lastKpiTranslations.latestText.defaultValue)}
                  </Typography>
                </StyledTag>
              </Container>

              {kpiHistoryDetails.current.startDate !== '' && (
                <FlexRow>
                  <Icon
                    icon={Icons.CALENDAR}
                    size="small"
                    color={mode === 'dark' ? 'secondaryDarkTextGray' : 'secondaryTextGray'}
                    margin={[0, 1, 0, 0]}
                  />
                  <StyledTypography type="caption">
                    {t(lastKpiTranslations.setOnText.key, {
                      defaultValue: lastKpiTranslations.setOnText.defaultValue,
                      date: kpiHistoryDetails?.current.startDate,
                    })}
                  </StyledTypography>
                </FlexRow>
              )}
            </Container>
            {!!kpiHistoryDetails.last.length && (
              <Container height={isMobile ? '60%' : '100%'} style={{ flex: 1 }}>
                <Charts
                  chartType="area"
                  xAxisCategories={dateRanges ?? []}
                  chartSeries={chartSeries}
                  chartColors={['validationGreen']}
                  sparklineEnabled={isMobile}
                  xAxis={{
                    labels: {
                      show: false,
                    },
                    tooltip: {
                      enabled: false,
                    },
                  }}
                  toolTip={{
                    followCursor: true,
                    x: {
                      show: true,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      formatter: (val: number, opts?: any) => {
                        if (!dateRanges) return '';
                        return formatCustomDateRange(dateRanges[opts.dataPointIndex]);
                      },
                    },
                    marker: { show: true },
                  }}
                  dataLabel={{
                    enabled: !isMobile,
                    style: {
                      fontSize: '12px',
                      fontWeight: '100',
                      colors: [muiTheme.color.primary],
                    },
                    formatter: function (value: number) {
                      if (!value) return '';
                      return getFormattedValue(value);
                    },
                  }}
                />
              </Container>
            )}
          </FlexRow>
          <Container wrap="nowrap" data-testid="past-kpis-container" margin={[6, 0, 0]}>
            <Container wrap="nowrap" direction="horizontal" margin={[0, 0, 1]}>
              <Typography type="body3">
                {t(lastKpiTranslations.pastKpisText.key, lastKpiTranslations.pastKpisText.defaultValue)}
              </Typography>
              <StyledTag
                wrap="nowrap"
                bgColor="infoTransparent"
                textColor={mode === 'dark' ? 'info' : 'infoDark'}
                margin={[0, 0, 0, 2]}
                padding={[1, 2]}
              >
                <Typography type="legal">{kpiHistoryDetails?.last.length}</Typography>
              </StyledTag>
            </Container>
            <StyledDivider />
            {!kpiHistoryDetails.last.length && (
              <Container position="center" height="200px">
                <Typography type="body3">
                  {t(lastKpiTranslations.noLastKpiText.key, lastKpiTranslations.noLastKpiText.defaultValue)}
                </Typography>
              </Container>
            )}

            {!!kpiHistoryDetails.last.length && (
              <StyledPastKpiContainer>
                {kpiHistoryDetails.last.map((item, index) => (
                  <StyledTargetContainer key={index}>
                    <FlexRow style={{ alignItems: 'center' }}>
                      <Icon icon={Icons.TARGET} size="medium" margin={[0, 1, 0, 0]} />
                      <Typography>
                        {t(kpiProperties.target.title.key, kpiProperties.target.title.defaultValue)} -{' '}
                      </Typography>
                      <Typography type="body3">{getFormattedValue(item.target)}</Typography>
                    </FlexRow>

                    <StyledDateContainer>
                      <FlexColumn>
                        <Typography type="legal" margin={[0, 0, 1]}>
                          {t(fromDate.key, fromDate.defaultValue)}
                        </Typography>
                        <Typography>{item.startDate}</Typography>
                      </FlexColumn>
                      <FlexColumn>
                        <Typography type="legal" margin={[0, 0, 1]}>
                          {t(toDate.key, toDate.defaultValue)}
                        </Typography>
                        <Typography>{item.endDate}</Typography>
                      </FlexColumn>
                    </StyledDateContainer>
                  </StyledTargetContainer>
                ))}
              </StyledPastKpiContainer>
            )}
          </Container>
          {/* TODO: Add future KPIs */}
          {/* <Container wrap="nowrap" data-testid="past-kpis-container" margin={[4, 0, 0]}>
          <Container wrap="nowrap" direction="horizontal" margin={[0, 0, 1]}>
            <Typography type="body3">Future Kpis</Typography>
            <StyledTag bgColor="warningTransparent" textColor={mode === 'dark' ? 'warning' : 'warningDark'}>
              <Typography type="legal">{kpiHistory?.last.length}</Typography>
            </StyledTag>
          </Container>
          <StyledDivider />
          <Container wrap="nowrap" padding={[3]} position="center" height="100px">
            <Typography type="button">Future KPIs coming soon!</Typography>
          </Container>
        </Container> */}
        </StyledContentWrapper>
      )}
    </StyledKpiHistoryWrapper>
  );
};
