import { RfidApprovalsResponse, RfidCountResponse, RfidSalesRetaggingResponse } from '../../hooks';

export const getFetchRfidApprovalsDataMockResponse = (): RfidApprovalsResponse => {
  return {
    negative: {
      approvedPieces: 3,
      difference: 23,
      missingPieces: 12,
      totalPieces: 400,
      approvalRate: 23,
    },
    positive: {
      approvedPieces: 12,
      difference: 102,
      extraPieces: 23,
      totalPieces: 500,
      approvalRate: 78,
    },

    lastUpdated: new Date(),
  };
};

export const getFetchRfidSalesRetaggingDataMockResponse = (): RfidSalesRetaggingResponse => {
  return {
    addedSales: 33,
    retaggingRate: 101,
    lastUpdated: new Date(),
  };
};

export const getMockRfidCountData = (): RfidCountResponse => {
  return {
    counts: 1,
    timeSpent: 78,
    scannersUsed: Math.floor(Math.random() * 2) + 1,
    lastUpdated: new Date(),
  };
};
