import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval } from '../../../../../constants';
import { ConversionRatePerformanceItem } from '../../../../../hooks';
import { Container } from '../../../../../stories/atoms';
import { Charts } from '../../../../../stories/molecules/charts/Charts';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { getXAxisLabels } from '../../../../../utils';
import { WidgetMetricTile, WidgetMetricType } from '../../../common/metricTile/WidgetMetricTile';
import { WidgetMetricTilesContainer } from '../../../common/metricTile/WidgetMetricTile.styles';
import { WidgetMobileLayout } from '../../../common/widgetMobileLayout/WidgetMobileLayout';

export const PayingNonPayingGraphView = ({
  interval,
  performanceItems,
  filteredPerformanceItems,
}: {
  interval: StorePerformanceInterval;
  performanceItems: ConversionRatePerformanceItem[];
  filteredPerformanceItems: (ConversionRatePerformanceItem | null)[];
}) => {
  const { conversionRate: conversionRateTranslation } = homeTranslations;
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const { t } = useTranslation();

  const getConversionRate = (items: (ConversionRatePerformanceItem | null)[]) => {
    let hoursElapsed = 0;
    let payingCustomersCount = 0;
    let nonPayingCustomersCount = 0;
    let numberOfTillsCount = 0;

    items.forEach((item) => {
      if (item === null) return;
      hoursElapsed += 1;
      payingCustomersCount += item.payingCustomers;
      nonPayingCustomersCount += item.nonPayingCustomers;
      numberOfTillsCount += item.numberOfTills;
    });

    const averagePayingCustomers = payingCustomersCount / hoursElapsed;
    const averageNonPayingCustomers = nonPayingCustomersCount / hoursElapsed;
    const averageAmountOfTills = numberOfTillsCount / hoursElapsed;

    const averageTillObject = {
      hour: 'Average',
      payingCustomers: averagePayingCustomers,
      nonPayingCustomers: averageNonPayingCustomers,
      numberOfTills: Math.floor(averageAmountOfTills),
    };

    const conversionRate =
      payingCustomersCount === 0 && nonPayingCustomersCount === 0
        ? 0
        : (payingCustomersCount / (payingCustomersCount + nonPayingCustomersCount)) * 100;

    return {
      conversionRate,
      averageTillObject,
      averagePayingCustomers,
      averageNonPayingCustomers,
    };
  };

  const payingCustomersCounts = performanceItems.map(({ payingCustomers }) => {
    return Math.floor(payingCustomers);
  });

  const nonPayingCustomersCounts = performanceItems.map(({ nonPayingCustomers }) => {
    return Math.floor(nonPayingCustomers);
  });

  const numberPayingCustomers = payingCustomersCounts.reduce((acc, curr) => acc + curr, 0);
  const numberNonPayingCustomers = nonPayingCustomersCounts.reduce((acc, curr) => acc + curr, 0);
  const totalVisitors = [...payingCustomersCounts, ...nonPayingCustomersCounts].reduce(
    (acc, curr) => acc + curr,
    0,
  );

  const { averageTillObject, averagePayingCustomers, averageNonPayingCustomers } =
    getConversionRate(filteredPerformanceItems);

  const conversionRateTillsData = [...performanceItems, averageTillObject];

  const getUsedTills = (dataIndex: number) => {
    const tills = conversionRateTillsData.find((item, index) => index === dataIndex);
    return tills;
  };

  return (
    <>
      {!isMobile && (
        <>
          <WidgetMetricTilesContainer>
            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.RegularMetric,
                properties: {
                  metricTitle: t(
                    conversionRateTranslation.totalVisitors.key,
                    conversionRateTranslation.totalVisitors.defaultValue,
                  ),
                  metricValue: totalVisitors.toLocaleString(),
                },
              }}
            />

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.LegendMetric,
                properties: {
                  metricTitle: t(
                    conversionRateTranslation.payingCustomers.key,
                    conversionRateTranslation.payingCustomers.defaultValue,
                  ),
                  metricValue: numberPayingCustomers.toLocaleString(),
                  legendColor: 'validationGreen',
                },
              }}
            />

            <WidgetMetricTile
              metricProperties={{
                type: WidgetMetricType.LegendMetric,
                properties: {
                  metricTitle: t(
                    conversionRateTranslation.nonPayingCustomers.key,
                    conversionRateTranslation.nonPayingCustomers.defaultValue,
                  ),
                  metricValue: numberNonPayingCustomers.toLocaleString(),
                  legendColor: 'inActiveGray',
                },
              }}
            />
          </WidgetMetricTilesContainer>

          <Container wrap="nowrap" data-testid="chart" height={isMobile ? '95%' : '90%'}>
            <Charts
              chartType="bar"
              isStacked={true}
              xAxisCategories={getXAxisLabels(interval, performanceItems).concat(
                t(conversionRateTranslation.average.key, conversionRateTranslation.average.defaultValue),
              )}
              chartSeries={[
                {
                  name: t(
                    conversionRateTranslation.payingCustomers.key,
                    conversionRateTranslation.payingCustomers.defaultValue,
                  ),
                  data: payingCustomersCounts.concat(Math.floor(averagePayingCustomers)),
                },
                {
                  name: t(
                    conversionRateTranslation.nonPayingCustomers.key,
                    conversionRateTranslation.nonPayingCustomers.defaultValue,
                  ),
                  data: nonPayingCustomersCounts.concat(Math.floor(averageNonPayingCustomers)),
                },
              ]}
              areaChartGradient={{ opacity: 1 }}
              toolTip={{
                enabled: true,
                shared: true,
                intersect: false,
                marker: { show: true },
                x: {
                  show: true,
                  formatter: (value, { dataPointIndex }) => {
                    const numberOfTillsUsed = getUsedTills(dataPointIndex);
                    return `${t(
                      conversionRateTranslation.numberOfTills.key,
                      conversionRateTranslation.numberOfTills.defaultValue,
                    )}: ${numberOfTillsUsed?.numberOfTills.toString() ?? '0'}`;
                  },
                },
                cssClass: 'custom-apex-tooltip',
              }}
              legend={{
                show: false,
                markers: {
                  radius: 12,
                  offsetY: 1,
                },
              }}
              isAnimationEnabled={false}
            />
          </Container>
        </>
      )}

      {isMobile && (
        <Container padding={[2, 6]} height="100%">
          <WidgetMobileLayout
            metricItems={[
              {
                label: t(
                  conversionRateTranslation.totalVisitors.key,
                  conversionRateTranslation.totalVisitors.defaultValue,
                ),
                content: totalVisitors.toLocaleString(),
              },
              {
                label: t(
                  conversionRateTranslation.payingCustomers.key,
                  conversionRateTranslation.payingCustomers.defaultValue,
                ),
                content: numberPayingCustomers.toLocaleString(),
              },
              {
                label: t(
                  conversionRateTranslation.nonPayingCustomers.key,
                  conversionRateTranslation.nonPayingCustomers.defaultValue,
                ),
                content: numberNonPayingCustomers.toLocaleString(),
              },
            ]}
            renderChart={() => {
              return (
                <Charts
                  chartType="bar"
                  isStacked={true}
                  xAxisCategories={getXAxisLabels(interval, performanceItems).concat(
                    t(conversionRateTranslation.average.key, conversionRateTranslation.average.defaultValue),
                  )}
                  chartSeries={[
                    {
                      name: t(
                        conversionRateTranslation.payingCustomers.key,
                        conversionRateTranslation.payingCustomers.defaultValue,
                      ),
                      data: payingCustomersCounts.concat(Math.floor(averagePayingCustomers)),
                    },
                    {
                      name: t(
                        conversionRateTranslation.nonPayingCustomers.key,
                        conversionRateTranslation.nonPayingCustomers.defaultValue,
                      ),
                      data: nonPayingCustomersCounts.concat(Math.floor(averageNonPayingCustomers)),
                    },
                  ]}
                  areaChartGradient={{ opacity: 1 }}
                  toolTip={{
                    enabled: true,
                    shared: true,
                    intersect: false,
                    marker: { show: true },
                    x: {
                      show: true,
                      formatter: (value, { dataPointIndex }) => {
                        const numberOfTillsUsed = getUsedTills(dataPointIndex);
                        return `${t(
                          conversionRateTranslation.numberOfTills.key,
                          conversionRateTranslation.numberOfTills.defaultValue,
                        )}: ${numberOfTillsUsed?.numberOfTills.toString() ?? '0'}`;
                      },
                    },
                    cssClass: 'custom-apex-tooltip',
                  }}
                  legend={{
                    show: false,
                  }}
                  isAnimationEnabled={false}
                  sparklineEnabled
                />
              );
            }}
          />
        </Container>
      )}
    </>
  );
};
