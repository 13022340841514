import {
  KpiAreaValue,
  KpiType,
  UpdateKpiRequest,
  areaCapEndpoint,
  areaComparisonEndpoint,
  areaKpiEndpoint,
  areaTargetEndpoint,
  assetManagementWidgetEndpoint,
  backofficeAIEndpoint,
  feedBackEndpoint,
  forecastEndpoint,
  highlightedWidgetsEndpoint,
  lastKpiEndpoint,
  nearbyStoresEndpoint,
  profitAndLossEndpoint,
  storeEndpoint,
  targetEndpoint,
  targetLockEndpoint,
  updateHighlightedWidgetsEndpoint,
  weatherWidgetEndpoint,
} from '../constants';
import { Store, StoreDetails } from '../global-state/types';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';
import { useUser } from './useController';
import { LastUpdatedTime, StorePerformanceRequest } from './useTransaction';

export type Status = 'OK' | 'WARNING' | 'UNKNOWN' | 'CLOSED';

export type ChatBotType = {
  answer: string;
  prompts: string[];
};

type UserLog = {
  lastSeen: string;
  operatorId: string;
};

type LatestTransaction = {
  timestamp: string;
  type?: string;
};

export type Tills = {
  id?: string;
  name: string;
  type?: string;
  latestTransaction?: LatestTransaction;
  totalAmount: number;
  amounts: {
    amount: number;
    currency: string;
  }[];
  userLog: UserLog[];
  status: Status;
};

export type AssetManagementResponse = LastUpdatedTime & {
  tills: Tills[];
};

export type WeatherResponse = {
  icon: string;
  name: string;
  situation: string;
  temperature: number;
  temperatureHigh: number;
  temperatureLow: number;
  units: string;
};

export type KpiAreaDetails = {
  kpiName: KpiType;
  kpiValues: KpiAreaValue[];
};

export type KpiDistribution = {
  hour: number;
  value: number;
};

export type KpiTargetProps = {
  updated: string;
  target: number | null;
  isLocked: boolean;
  distributions: KpiDistribution[];
};

export type KpiTargetsProps = {
  [key in KpiType]: KpiTargetProps;
};

export type AreaTargetResponse = {
  storeId: string;
  currentTarget: number | null;
  recommendedTarget: number | null;
  storeName: string;
};

export type UpdateHighlightedWidgetsRequest = {
  [key in KpiType]?: boolean;
};

export type FeedbackData = {
  rating: number;
  comment: string;
  dateTime: string;
};

export type SummaryCostType = 'salesExcVat' | 'salesIncVat';

export type SummaryCostDetails = {
  actual: number;
  lastYear: number | null;
  budget: number | null;
};

export type SummaryCost = {
  [key in SummaryCostType]: SummaryCostDetails;
};

export type BreakdownCostType = 'otherCosts' | 'reductions' | 'salaries' | 'scrapping' | 'shrinkage' | 'surplus1';

export type BreakdownCostDetails = SummaryCostDetails & { name: string };

export type BreakdownCost = {
  [key in BreakdownCostType]: BreakdownCostDetails[];
};

export type ProfitLossResponse = LastUpdatedTime &
  SummaryCost & {
    details: BreakdownCost;
  };

export enum SalesEfficiencyMetrics {
  AverageNetSale = 'averageNetSale',
  AverageVisitors = 'averageVisitors',
  AverageConversionRate = 'averageConversionRate',
  AverageTakt1 = 'averageTakt1',
  AveragePiecesPerReceipt = 'averagePiecesPerReceipt',
  AverageSPT = 'averageSPT',
  AveragePurchasePerReceipt = 'averagePurchasePerReceipt',
  AverageEffectiveHours = 'averageEffectiveHours',
}

export type AreaComparisonItem = {
  name: SalesEfficiencyMetrics;
  today: number | null;
  lastWeek: number;
  lastYear: number;
  lastWeekArea: number;
  lastYearArea: number;
};

export type LastKpiResponse = {
  current: {
    target: number | null;
    startDate: string;
  };
  last: {
    target: number;
    startDate: string;
    endDate: string;
  }[];
};

export const useStores = () => {
  const {
    get: { keyToken, currentStoreId },
  } = useUser();

  const { authAxiosRequest } = useAuthAxiosRequest(keyToken);

  const fetchStoresDetails = async () => {
    const storeResponse = await authAxiosRequest<StoreDetails[]>(`${storeEndpoint}/`);

    return storeResponse;
  };

  const fetchStoresData = async (storeId: string) => {
    const storeResponse = await authAxiosRequest<Store>(`${storeEndpoint}/${storeId}`);

    return storeResponse;
  };

  const fetchAssetManagementWidgetData = async (date: string) => {
    const assetManagementResponse = await authAxiosRequest<AssetManagementResponse>(
      `${storeEndpoint}/${currentStoreId}${assetManagementWidgetEndpoint}`,
      {
        params: {
          date,
        },
      },
    );

    return assetManagementResponse;
  };

  const fetchWeatherWidgetData = async () => {
    const assetManagementResponse = await authAxiosRequest<WeatherResponse>(
      `${storeEndpoint}/${currentStoreId}${weatherWidgetEndpoint}`,
    );

    return assetManagementResponse;
  };

  const fetchChatBotData = async (question: string) => {
    const chatBotResponse = await authAxiosRequest<ChatBotType>(
      `${storeEndpoint}/${currentStoreId}${backofficeAIEndpoint}`,
      {
        params: { question },
      },
    );

    return chatBotResponse;
  };

  const fetchKpiTargets = async (date?: string) => {
    const response = await authAxiosRequest<KpiTargetsProps>(
      `${storeEndpoint}/${currentStoreId}${targetEndpoint}`,
      {
        method: 'GET',
        params: {
          date: date,
        },
      },
    );

    return response;
  };

  const fetchKpisAreaDetails = async () => {
    const areaKpiResponse = await authAxiosRequest<KpiAreaDetails[]>(
      `${storeEndpoint}/${currentStoreId}${areaKpiEndpoint}`,
    );

    return areaKpiResponse;
  };

  const updateKpiLock = async (request: UpdateKpiRequest[]) => {
    const kpiResponse = await authAxiosRequest<KpiTargetsProps>(
      `${storeEndpoint}/${currentStoreId}${targetEndpoint}${targetLockEndpoint}`,
      {
        method: 'PUT',
        data: request,
      },
    );

    return kpiResponse;
  };

  const updateKpiData = async (request: UpdateKpiRequest[]) => {
    const kpiResponse = await authAxiosRequest<KpiTargetsProps>(
      `${storeEndpoint}/${currentStoreId}${targetEndpoint}`,
      {
        method: 'PUT',
        data: request,
      },
    );

    return kpiResponse;
  };

  const removeKpiData = async (request: UpdateKpiRequest[]) => {
    const kpiResponse = await authAxiosRequest<KpiTargetsProps>(
      `${storeEndpoint}/${currentStoreId}${targetEndpoint}`,
      {
        method: 'DELETE',
        data: request,
      },
    );

    return kpiResponse;
  };

  const updateAreaCapData = async (request: UpdateKpiRequest[]) => {
    const kpiResponse = await authAxiosRequest<KpiAreaDetails[]>(
      `${storeEndpoint}${areaCapEndpoint.replace(':storeId', currentStoreId)}`,
      {
        method: 'PUT',
        data: request,
      },
    );

    return kpiResponse;
  };

  const removeAreaCapData = async (request: UpdateKpiRequest[]) => {
    const kpiResponse = await authAxiosRequest<KpiAreaDetails[]>(
      `${storeEndpoint}${areaCapEndpoint.replace(':storeId', currentStoreId)}`,
      {
        method: 'DELETE',
        data: request,
      },
    );

    return kpiResponse;
  };

  const fetchAreaStoresTarget = async (kpiName: string) => {
    const areaTargets = await authAxiosRequest<AreaTargetResponse[]>(
      `${storeEndpoint}${areaTargetEndpoint.replace(':storeId', currentStoreId).replace(':kpiName', kpiName)}`,
    );

    return areaTargets;
  };

  const fetchNearbyStores = async () => {
    const response = await authAxiosRequest<StoreDetails[]>(
      `${storeEndpoint}/${currentStoreId}${nearbyStoresEndpoint}`,
    );

    return response;
  };

  const fetchHighlightedKpi = async () => {
    const highlightedWidgetResponse = await authAxiosRequest<KpiType[]>(
      `${storeEndpoint}/${currentStoreId}${highlightedWidgetsEndpoint}`,
    );
    return highlightedWidgetResponse;
  };

  const updateHighlightedKpi = async (request: UpdateHighlightedWidgetsRequest) => {
    const highlightedWidgetResponse = await authAxiosRequest<KpiType[]>(
      `${storeEndpoint}/${currentStoreId}${updateHighlightedWidgetsEndpoint}`,
      {
        method: 'PATCH',
        data: request,
      },
    );

    return highlightedWidgetResponse;
  };

  const fetchForecastForKpis = async <T>(kpiName: string) => {
    const forecastResponse = await authAxiosRequest<T[]>(
      `${storeEndpoint}/${currentStoreId}${forecastEndpoint}/${kpiName}`,
    );
    return forecastResponse;
  };

  const sendFeedbackData = async (feedbackData: FeedbackData) => {
    const response = await authAxiosRequest(`${storeEndpoint}/${currentStoreId}${feedBackEndpoint}`, {
      method: 'POST',
      data: feedbackData,
    });
    return response;
  };

  const fetchProfitLossData = async (monthArray: string[]) => {
    const params = new URLSearchParams();
    monthArray.forEach((month) => {
      params.append('months', month);
    });

    const response = await authAxiosRequest<ProfitLossResponse>(
      `${storeEndpoint}/${currentStoreId}${profitAndLossEndpoint}`,
      {
        method: 'GET',
        params: params,
      },
    );
    return response;
  };

  const fetchAreaComparisonData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<AreaComparisonItem[]>(
      `${storeEndpoint}/${currentStoreId}${areaComparisonEndpoint}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchLastKpi = async (kpiName: string) => {
    const response = await authAxiosRequest<LastKpiResponse>(
      `${storeEndpoint}/${currentStoreId}${targetEndpoint}${lastKpiEndpoint}`,
      {
        method: 'GET',
        params: {
          kpiName,
        },
      },
    );

    return response;
  };

  return {
    fetchStoresDetails,
    fetchStoresData,
    fetchAssetManagementWidgetData,
    fetchWeatherWidgetData,
    fetchChatBotData,
    fetchKpisAreaDetails,
    fetchKpiTargets,
    updateKpiLock,
    updateKpiData,
    removeKpiData,
    updateAreaCapData,
    removeAreaCapData,
    fetchAreaStoresTarget,
    fetchNearbyStores,
    fetchHighlightedKpi,
    updateHighlightedKpi,
    fetchForecastForKpis,
    sendFeedbackData,
    fetchProfitLossData,
    fetchAreaComparisonData,
    fetchLastKpi,
  };
};
