import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { RfidApprovalsResponse, useAsyncAction, useRfid } from '../../../../../hooks';
import { Icons } from '../../../../../stories/atoms';
import { Colors } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { MetricItem } from './MetricItem';
import { Wrapper } from './RfidApprovalsWidget.styles';

export type RfidApprovalsWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  fromDate?: Date;
  toDate?: Date;
};

export const RfidApprovalsWidget: FC<RfidApprovalsWidgetProps> = observer(
  ({ interval, currentDate, fromDate, toDate }) => {
    const { t } = useTranslation();

    const { rfidApprovals: rfidApprovalsTranslations } = homeTranslations;
    const { fetchRfidApprovalsData } = useRfid();

    const {
      result: rfidApprovals,
      isLoading: rfidApprovalsLoading,
      isError: rfidApprovalsError,
      triggerAction: triggerFetchRfidApprovals,
    } = useAsyncAction<RfidApprovalsResponse>();

    const fetchRfidApprovals = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchRfidApprovalsData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    const fetchWidgetData = () => {
      triggerFetchRfidApprovals(fetchRfidApprovals);
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate]);

    const getChartColor = (value: number): Colors => {
      if (value <= 97) return 'red';
      if (value < 100) return 'warning';

      return 'validationGreen';
    };

    const isNoData =
      !rfidApprovalsLoading &&
      !rfidApprovalsError &&
      rfidApprovals?.negative.totalPieces === 0 &&
      rfidApprovals?.positive.totalPieces === 0;

    return (
      <WidgetCardContent
        headerIcon={Icons.PROFILE}
        headerText={t(rfidApprovalsTranslations.title.key, rfidApprovalsTranslations.title.defaultValue)}
        subHeaderText={t(rfidApprovalsTranslations.subTitle.key, rfidApprovalsTranslations.subTitle.defaultValue)}
        toolTipText={t(rfidApprovalsTranslations.tooltip.key, rfidApprovalsTranslations.tooltip.defaultValue)}
        isLoading={rfidApprovalsLoading}
        isError={rfidApprovalsError}
        errorText={t(rfidApprovalsTranslations.error.key, rfidApprovalsTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        lastUpdatedDate={rfidApprovals?.lastUpdated}
        noDataText={t(rfidApprovalsTranslations.noData.key, rfidApprovalsTranslations.noData.defaultValue)}
        isNoData={isNoData}
        content={
          <Wrapper data-testid="rfid-approvals-wrapper">
            {rfidApprovals && (
              <>
                <MetricItem
                  icon={Icons.THUMBS_DOWN}
                  chartPercent={rfidApprovals.negative.difference}
                  chartColor={getChartColor(rfidApprovals.negative.difference)}
                  titleText={t(
                    rfidApprovalsTranslations.negativeTitle.key,
                    rfidApprovalsTranslations.negativeTitle.defaultValue,
                  )}
                  pieces={rfidApprovals.negative.approvedPieces}
                  descriptionText={t(
                    rfidApprovalsTranslations.negativeDescription.key,
                    rfidApprovalsTranslations.negativeDescription.defaultValue,
                  )}
                  approvalRateText={t(
                    rfidApprovalsTranslations.approvalRate.key,
                    rfidApprovalsTranslations.approvalRate.defaultValue,
                  )}
                  approvalRate={rfidApprovals.negative.approvalRate}
                  piecesExtraText={t(
                    rfidApprovalsTranslations.negativeMissingPieces.key,
                    rfidApprovalsTranslations.negativeMissingPieces.defaultValue,
                  )}
                  approvedPieces={rfidApprovals.negative.missingPieces}
                  totalPieces={rfidApprovals.negative.totalPieces}
                />

                <MetricItem
                  icon={Icons.THUMBS_UP}
                  chartPercent={rfidApprovals.positive.difference}
                  chartColor={getChartColor(rfidApprovals.positive.difference)}
                  titleText={t(
                    rfidApprovalsTranslations.positiveTitle.key,
                    rfidApprovalsTranslations.positiveTitle.defaultValue,
                  )}
                  pieces={rfidApprovals.positive.approvedPieces}
                  descriptionText={t(
                    rfidApprovalsTranslations.positiveDescription.key,
                    rfidApprovalsTranslations.positiveDescription.defaultValue,
                  )}
                  approvalRateText={t(
                    rfidApprovalsTranslations.approvalRate.key,
                    rfidApprovalsTranslations.approvalRate.defaultValue,
                  )}
                  approvalRate={rfidApprovals.positive.approvalRate}
                  piecesExtraText={t(
                    rfidApprovalsTranslations.positiveExtraPieces.key,
                    rfidApprovalsTranslations.positiveExtraPieces.defaultValue,
                  )}
                  approvedPieces={rfidApprovals.positive.extraPieces}
                  totalPieces={rfidApprovals.positive.totalPieces}
                />
              </>
            )}
          </Wrapper>
        }
      />
    );
  },
);
