import { rfidApprovalsEndpoint, rfidCountEndpoint, rfidEndpoint, rfidSalesRetaggingEndpoint } from '../constants';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';
import { useUser } from './useController';
import { LastUpdatedTime, StorePerformanceRequest } from './useTransaction';

export type RfidSalesRetaggingResponse = LastUpdatedTime & {
  retaggingRate: number;
  addedSales: number;
};

export type RfidApprovalsResponse = LastUpdatedTime & {
  negative: {
    approvedPieces: number;
    difference: number;
    missingPieces: number;
    totalPieces: number;
    approvalRate: number;
  };
  positive: {
    approvedPieces: number;
    difference: number;
    extraPieces: number;
    totalPieces: number;
    approvalRate: number;
  };
};

export type RfidCountResponse = LastUpdatedTime & {
  counts: number;
  timeSpent: number;
  scannersUsed: number;
};

export const useRfid = () => {
  const {
    get: { currentStoreId, keyToken },
  } = useUser();

  const { authAxiosRequest } = useAuthAxiosRequest(keyToken);

  const fetchRfidCountData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<RfidCountResponse>(
      `${rfidEndpoint}/${currentStoreId}${rfidCountEndpoint}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchRfidSalesRetaggingData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<RfidSalesRetaggingResponse>(
      `${rfidEndpoint}/${currentStoreId}${rfidSalesRetaggingEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  const fetchRfidApprovalsData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<RfidApprovalsResponse>(
      `${rfidEndpoint}/${currentStoreId}${rfidApprovalsEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  return {
    fetchRfidCountData,
    fetchRfidSalesRetaggingData,
    fetchRfidApprovalsData,
  };
};
