import { useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  KpiAreaValueType,
  KpiDropdownActions,
  KpiType,
  UpdateKpiRequest,
  getKPIIcons,
  getUnit,
} from '../../../constants';
import { KpiAreaDetails, useStoreInformation, useUser } from '../../../hooks';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../stories/atoms';
import { muiTheme } from '../../../theme';
import { homeTranslations, kpiTargetTransalations } from '../../../translations';
import { convertToKebabCase, formatAmountWithCurrency } from '../../../utils';
import KpiDropdown from '../kpiDropdown/KpiDropdown';

const {
  kpiWidgets,
  notSet,
  kpiProperties: kpiPropertiesTranslations,
  kpiLock,
  areaView,
  kpiDropdown,
} = kpiTargetTransalations;

export type KPIPropertyTranslation = keyof typeof kpiPropertiesTranslations;

import { useThemeContext } from '../../../global-state/themeContext';
import {
  ButtonsContainer,
  FlexColumn,
  FlexRow,
  FlexRowItem,
  HeaderIcon,
  HeaderIconsContainer,
  HeaderStarIconContainer,
  KPIContainerWrapper,
  StyledDivider,
  StyledHighlightIcon,
  StyledIcon,
  StyledIconWithText,
  StyledKPIHeader,
  StyledKPIProperty,
  StyledKPIValueContainer,
} from './KpiCard.styles';

type KpiCardHeaderProps = {
  kpiName: KpiType;
  isKpiHighlighted: boolean;
  kpiText: string;
  lastUpdated: string;
};

const KpiCardHeader: FC<KpiCardHeaderProps> = ({ kpiName, isKpiHighlighted, kpiText, lastUpdated }) => {
  const { t } = useTranslation();
  const { common: commonTranslations } = homeTranslations;
  const formattedDate = lastUpdated ? format(new Date(lastUpdated), "yyyy-MM-dd 'at' HH:mm") : null;
  return (
    <div>
      <HeaderIconsContainer>
        <HeaderIcon icon={Icons[getKPIIcons(kpiName)]} size="small" margin={[0, 2, 0, 0]} />
        {isKpiHighlighted && (
          <HeaderStarIconContainer>
            <StyledHighlightIcon icon={Icons.STAR_FILLED} size="small" data-testid="star-filled-icon" />
          </HeaderStarIconContainer>
        )}
      </HeaderIconsContainer>
      <Container wrap="nowrap" margin={[0, 2, 0, 0]}>
        <Typography type="h4" data-testid="widget-title" overflow={'visible'}>
          {kpiText}
        </Typography>
        <Typography type="caption" color="textGray">
          {t(commonTranslations.lastUpdated.key, commonTranslations.lastUpdated.defaultValue)}: {formattedDate}
        </Typography>
      </Container>
    </div>
  );
};

type KpiTooltipProps = {
  tooltipText: string;
};

// Get Tooltip for KPI Property
const KpiTooltip: FC<KpiTooltipProps> = ({ tooltipText }) => {
  return (
    <Tooltip
      position="bottom-start"
      arrowedToolTip
      text={
        <Container padding={[2]}>
          <Typography color="white">
            <Trans components={{ b: <b /> }}>{tooltipText}</Trans>
          </Typography>
        </Container>
      }
    >
      <StyledIcon data-testid="info-icon" icon={Icons.INFO} margin={[0, 1]} />
    </Tooltip>
  );
};

type KPIValueProps = {
  kpiProperty: string;
  isTargetLocked: boolean;
  kpiValue: number | null;
  areaCode: string;
  getKpiFormattedValue: (value: number, isAreaCap: boolean) => string;
};

const KPIValue: FC<KPIValueProps> = ({
  kpiProperty,
  isTargetLocked,
  kpiValue,
  areaCode,
  getKpiFormattedValue,
}) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  return (
    <FlexColumn key={`${kpiProperty}-key`}>
      <StyledKPIProperty data-testid="widget-kpi-property">
        <Typography type="caption">
          {t(
            kpiPropertiesTranslations[kpiProperty as KPIPropertyTranslation].title.key,
            kpiPropertiesTranslations[kpiProperty as KPIPropertyTranslation].title.defaultValue,
          )}
        </Typography>
        <KpiTooltip
          tooltipText={t(kpiPropertiesTranslations[kpiProperty as KPIPropertyTranslation].tooltip.key, {
            defaultValue: kpiPropertiesTranslations[kpiProperty as KPIPropertyTranslation].tooltip.defaultValue,
            areaName: areaCode,
          })}
        />
      </StyledKPIProperty>
      <Container wrap="wrap" direction="horizontal">
        <Typography color={kpiValue ? 'primary' : 'textGray'} data-testid="widget-kpi-value">
          {kpiValue !== null
            ? getKpiFormattedValue(kpiValue, kpiProperty === KpiAreaValueType.AREA_CAP)
            : t(notSet.key, notSet.defaultValue)}
        </Typography>
        {kpiProperty === KpiAreaValueType.TARGET && isTargetLocked && (
          <Icon
            data-testid="locked-kpi"
            color={mode === 'dark' ? 'error' : 'red'}
            icon={Icons.LOCK}
            size="small"
            margin={[0, 1]}
          />
        )}
      </Container>
    </FlexColumn>
  );
};

export type KPICardProps = {
  kpiDetails: KpiAreaDetails;
  isKpiHighlighted: boolean;
  isTargetLocked: boolean;
  lastUpdated: string;
  target: number | null;
  updateLockLoading: boolean;
  updateHighlightLoading: boolean;
  onAreaViewClick: (isOpen: boolean) => void;
  handleKpiLock: (request: UpdateKpiRequest[]) => void;
  handleHighlightKpi: (kpiType: KpiType) => void;
  handleLastKpis: (kpiType: KpiType) => void;
  handleSelectDropdown: (dropdownValue: KpiDropdownActions) => void;
};

const KpiCard: FC<KPICardProps> = ({
  kpiDetails,
  isKpiHighlighted,
  isTargetLocked,
  lastUpdated,
  target,
  updateLockLoading,
  updateHighlightLoading,
  onAreaViewClick,
  handleKpiLock,
  handleHighlightKpi,
  handleLastKpis,
  handleSelectDropdown,
}) => {
  const { t, i18n } = useTranslation();
  const {
    get: { userPermissions },
  } = useUser();
  const { kpiName, kpiValues: kpiAreaValues } = kpiDetails;
  const areaCap = kpiAreaValues.find((item) => item.kpiProperty === 'areaCap');
  const areaCapValue = areaCap ? areaCap?.kpiValue : null;

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const { currencyCode: storeCurrency, areaCode } = useStoreInformation();

  // Define layout structure
  const layoutConfig = {
    desktop: [
      [1, 2, 3],
      [4, 5, 6],
    ],
    mobile: [
      [1, 2],
      [3, 4],
      [5, 6],
    ],
  };
  const layout = isMobile ? layoutConfig.mobile : layoutConfig.desktop;

  const canEditKpi = userPermissions.kpiTargets.canUpdate;
  const canRemoveKpi = userPermissions.kpiTargets.canDelete;
  const canEditAreaCap = userPermissions.areaCaps.canUpdate;
  const canRemoveAreaCap = userPermissions.areaCaps.canDelete;

  const canEditAny = canEditKpi || canEditAreaCap || canRemoveKpi || canRemoveAreaCap;

  // Get formatted KPI Value
  const getKpiFormattedValue = (value: number, isAreaCap: boolean) => {
    const unit = getUnit(kpiName, storeCurrency);
    const newValue =
      unit === storeCurrency
        ? formatAmountWithCurrency(parseFloat(value.toFixed(2)), i18n.language, storeCurrency || '')
        : value.toFixed(2).toLocaleString() + (unit ?? '');

    return isAreaCap ? `+${newValue}` : newValue;
  };

  const handleLock = () => {
    const request = [
      {
        kpiName,
        isLocked: !isTargetLocked,
      },
    ];
    handleKpiLock(request);
  };

  const elementMap: Record<string, JSX.Element> = {
    1: (
      <KPIValue
        key={`${kpiName}-target-key`}
        isTargetLocked={isTargetLocked}
        kpiProperty="target"
        kpiValue={target}
        areaCode={areaCode}
        getKpiFormattedValue={getKpiFormattedValue}
      />
    ),
  };

  // Add the rest of the elements dynamically from kpiAreaValues
  kpiAreaValues.forEach((value, index) => {
    const { kpiProperty, kpiValue } = value;
    elementMap[index + 2] = (
      <KPIValue
        key={`${kpiName}-${kpiProperty}-key`}
        isTargetLocked={isTargetLocked}
        kpiProperty={kpiProperty}
        kpiValue={kpiValue}
        areaCode={areaCode}
        getKpiFormattedValue={getKpiFormattedValue}
      />
    );
  });

  // Highlight Button
  const highlightButton = (
    <StyledIconWithText
      data-testid="highlight-button"
      label={
        isMobile
          ? ''
          : isKpiHighlighted
          ? t(kpiDropdown.unhighlightKpi.key, kpiDropdown.unhighlightKpi.defaultValue)
          : t(kpiDropdown.highlightKpi.key, kpiDropdown.highlightKpi.defaultValue)
      }
      iconOptions={{
        icon: updateHighlightLoading ? Icons.SPINNER : isKpiHighlighted ? Icons.STAR : Icons.STAR_FILLED,
        size: 'small',
      }}
      onClick={() => handleHighlightKpi(kpiName)}
    />
  );

  // Area View Button
  const areaViewButton = (
    <StyledIconWithText
      data-testid="area-view-button"
      label={isMobile ? '' : t(areaView.key, areaView.defaultValue)}
      iconOptions={{
        icon: Icons.MAP,
        size: 'small',
      }}
      onClick={() => onAreaViewClick(true)}
    />
  );

  // Lock Button
  const lockButton = (
    <StyledIconWithText
      data-testid="lock-button"
      label={
        isMobile || updateLockLoading
          ? ''
          : isTargetLocked
          ? t(kpiLock.unlocked.key, kpiLock.unlocked.defaultValue)
          : t(kpiLock.locked.key, kpiLock.locked.defaultValue)
      }
      iconOptions={{
        icon: updateLockLoading ? Icons.SPINNER : isTargetLocked ? Icons.UNLOCK : Icons.LOCK,
        size: 'small',
      }}
      onClick={() => handleLock()}
    />
  );

  const lastKpis = (
    <StyledIconWithText
      data-testid="last-kpis-button"
      label={isMobile ? '' : 'Last KPI'}
      isLast
      iconOptions={{
        icon: Icons.CLOCK,
        size: 'small',
      }}
      onClick={() => {
        handleLastKpis(kpiName);
      }}
    />
  );

  return (
    <KPIContainerWrapper key={kpiName} data-testid={`${convertToKebabCase(kpiName)}-card`}>
      <StyledKPIHeader data-testid="kpi-content-header">
        <KpiCardHeader
          kpiName={kpiName}
          isKpiHighlighted={isKpiHighlighted}
          lastUpdated={lastUpdated}
          kpiText={t(kpiWidgets[kpiName].key, kpiWidgets[kpiName].defaultValue)}
        />
        {canEditAny && (
          <KpiDropdown
            isTargetLocked={isTargetLocked}
            target={target}
            areaCapValue={areaCapValue}
            sendKpiDropdownDetails={handleSelectDropdown}
          />
        )}
      </StyledKPIHeader>

      <StyledKPIValueContainer data-testid="kpi-content-container">
        {layout.map((row, rowIndex) => (
          <Fragment key={rowIndex}>
            <FlexRow>
              {row.map((item, itemIndex) => (
                <Fragment key={itemIndex}>
                  <FlexRowItem>{elementMap[item]}</FlexRowItem>
                  {itemIndex < row.length - 1 && <StyledDivider isRowDivider={false} />}{' '}
                </Fragment>
              ))}
            </FlexRow>
            {rowIndex < layout.length - 1 && <StyledDivider isRowDivider />}
          </Fragment>
        ))}
      </StyledKPIValueContainer>
      <ButtonsContainer>
        {highlightButton} {areaViewButton} {lockButton} {lastKpis}
      </ButtonsContainer>
    </KPIContainerWrapper>
  );
};

export default KpiCard;
