import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../../../../stories/atoms';
import { homeTranslations } from '../../../../../../translations';
import { formatValue } from '../../../../../../utils';
import { CustomerEngagementTranslation, EngagementMetrics } from '../CustomerEngagement';
import { SectionContainer } from '../CustomerEngagement.styles';

const { customerEngagement: customerEngagementranslations } = homeTranslations;

type SubMetric = {
  name: string;
  value: number;
};

type MetricSectionProps = {
  metricName: EngagementMetrics;
  subMetric1: SubMetric;
  subMetric2: SubMetric;
  subMetric3: SubMetric;
  icon: Icons;
  isMobileView: boolean;
  tooltipText: string;
  isSliderView?: boolean;
  sendMetricDetails?: (metricName: EngagementMetrics) => void;
};

export const MetricSection: FC<MetricSectionProps> = ({
  metricName,
  subMetric1,
  subMetric2,
  subMetric3,
  icon,
  isMobileView,
  tooltipText,
  sendMetricDetails = () => {},
  isSliderView = false,
}) => {
  const { t } = useTranslation();
  const getMetricName = (name: string) => {
    if (customerEngagementranslations[name as CustomerEngagementTranslation]) {
      return t(
        customerEngagementranslations[name as CustomerEngagementTranslation].key,
        customerEngagementranslations[name as CustomerEngagementTranslation].defaultValue,
      );
    } else {
      return name;
    }
  };

  return (
    <SectionContainer
      data-testid={`metric-section-${metricName}`}
      metricName={metricName}
      onClick={() => sendMetricDetails(metricName)}
      isSliderView={isSliderView}
    >
      <Container wrap="nowrap" direction="horizontal" space="between">
        <Container wrap="nowrap" direction={isSliderView ? 'vertical' : 'horizontal'}>
          {!isMobileView && <Icon icon={icon} size={isSliderView ? 'large' : 'small'} />}
          <Typography type={isSliderView ? 'button' : 'body2'} margin={[0, 0, 0, 1]}>
            {t(
              customerEngagementranslations[metricName as CustomerEngagementTranslation].key,
              customerEngagementranslations[metricName as CustomerEngagementTranslation].defaultValue,
            )}
          </Typography>
        </Container>
        <Tooltip
          arrowedToolTip
          text={
            <Container padding={[1, 2]}>
              <Typography color="white">
                {t(
                  customerEngagementranslations[tooltipText as CustomerEngagementTranslation].key,
                  customerEngagementranslations[tooltipText as CustomerEngagementTranslation].defaultValue,
                )}
              </Typography>
            </Container>
          }
        >
          <Icon icon={Icons.INFO} size="small" />
        </Tooltip>
      </Container>
      <Container wrap="nowrap" direction={isSliderView ? 'vertical' : 'horizontal'} style={{ flex: 1 }}>
        {!isMobileView && (
          <Container
            style={{ flex: 1, alignItems: isSliderView ? 'start' : 'center', justifyContent: 'center' }}
            key={subMetric1.name}
            wrap="nowrap"
          >
            <Typography type="button">
              {formatValue(subMetric1.value, {
                options: { minimumFractionDigits: 0, notation: 'compact' },
              })}
            </Typography>
            <Typography color="textGray">{getMetricName(subMetric1.name)}</Typography>
          </Container>
        )}
        {!isMobileView && (
          <Container
            style={{ flex: 1, alignItems: isSliderView ? 'start' : 'center', justifyContent: 'center' }}
            key={subMetric2.name}
            wrap="nowrap"
          >
            <Typography type="button">
              {formatValue(subMetric2.value, {
                options: { minimumFractionDigits: 0, notation: 'compact' },
              })}
            </Typography>
            <Typography color="textGray">{getMetricName(subMetric2.name)}</Typography>
          </Container>
        )}
        <Container
          style={{ flex: 1, alignItems: isSliderView ? 'start' : 'center', justifyContent: 'center' }}
          key={subMetric3.name}
          wrap="nowrap"
        >
          <Typography type="button">
            {formatValue(subMetric3.value, {
              options: { minimumFractionDigits: 0, notation: 'compact' },
            })}
            %
          </Typography>
          <Typography color="textGray">{getMetricName(subMetric3.name)}</Typography>
        </Container>
      </Container>
    </SectionContainer>
  );
};
