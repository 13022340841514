import { useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { KpiDropdownActions, KpiEditTypes, KpiModalViews, KpiType, UpdateKpiRequest } from '../../constants';
import {
  KpiAreaDetails,
  KpiTargetsProps,
  UpdateHighlightedWidgetsRequest,
  useStoreInformation,
  useStores,
  useUser,
} from '../../hooks';
import { useAsyncAction } from '../../hooks/useAsyncAction';
import { Container, Icon, Icons, Typography } from '../../stories/atoms';
import {
  Animation,
  Animations,
  Banner,
  BannerItemType,
  BannerItemsType,
  DropdownMenu,
} from '../../stories/molecules';
import { Page } from '../../stories/organisms';
import { muiTheme } from '../../theme';
import { kpiTargetTransalations } from '../../translations';
import { getKpiTargetProps, getKpiTargetValue, getUniqueId, isFailureResponse } from '../../utils';
import KpiModal from '../kpiModal/KpiModal';
import { KPIWrapper, StyledKPIContent, StyledKPIHeader, StyledTextContainer } from './KpiTarget.styles';
import AreaTargetView from './areaTargetView/AreaTargetView';
import BulkRemoveModal from './bulkRemoveModal/BulkRemoveModal';
import BulkUpdateModal from './bulkUpdateModal/BulkUpdateModal';
import KpiCard from './kpiCard/KpiCard';
import { KpiHistoryDashboard } from './kpiHistoryDashboard/KpiHistoryDashboard';

export type KpiModalDetails = {
  isModalOpen: boolean;
  kpiType: KpiType;
  operation: KpiEditTypes;
  isAreaCap: boolean;
};

enum AccessTypes {
  EDIT_KPI_TARGET = 'only edit',
  EDIT_ALL = 'edit',
  ONLY_VIEW = 'view',
}

type LoadingState = {
  [key in KpiType]: boolean;
};

export const KpiTarget: FC<unknown> = observer(() => {
  const { t } = useTranslation();
  const {
    set,
    get: { highlightedKpiTargets, userPermissions, kpiTargetsProps },
  } = useUser();
  const {
    fetchKpisAreaDetails,
    updateKpiData,
    removeKpiData,
    updateKpiLock,
    updateAreaCapData,
    removeAreaCapData,
    updateHighlightedKpi,
    fetchKpiTargets,
  } = useStores();

  const {
    error,
    fetchingKpis,
    headerText,
    kpiLock,
    kpiHighlight,
    bulkUpdate,
    removeKpiButton,
    updateKpiButton,
    kpiDropdown,
  } = kpiTargetTransalations;

  const {
    result: kpisAreaDetails,
    isLoading: kpisAreaDetailsLoading,
    isError: kpisAreaDetailsError,
    triggerAction: triggerFetchKpisAreaDetails,
  } = useAsyncAction<KpiAreaDetails[]>();

  const {
    isLoading: kpisTargetsLoading,
    isError: kpisTargetsError,
    triggerAction: triggerFetchKpisTargets,
  } = useAsyncAction<KpiTargetsProps>();

  const {
    result: updatedKpiTargetProps,
    isLoading: updateTargetLoading,
    triggerAction: triggerUpdateTarget,
  } = useAsyncAction<KpiTargetsProps>();

  const {
    result: updatedAreaKpiDetails,
    isLoading: updateAreaDetailsLoading,
    triggerAction: triggerUpdateAreaKpiDetails,
  } = useAsyncAction<KpiAreaDetails[]>();

  const defaultKpi = {
    dailyConversionRate: false,
    dailyNewMembers: false,
    dailyTurnOver: false,
    dailyShareOfReceipts: false,
    dailyQueueLength: false,
    dailySCOShareOfReceipts: false,
    dailySCOTurnOver: false,
    dailyCustomerEngagement: false,
    dailyRFIDAddedSales: false,
  };

  const { result: updatedHighlightKpiTypes, triggerAction: triggerHighlightKpi } = useAsyncAction<KpiType[]>();

  const [areaKpiData, setAreaKpiData] = useState<KpiAreaDetails[] | null>(null);

  const { storeName } = useStoreInformation();

  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const [showKpiHistoryPage, setShowKpiHistoryPage] = useState(false);
  const [selectedLastKpiType, setSelectedLastKpiType] = useState<KpiType | null>(null);
  const [isLockLoading, setIsLockLoading] = useState<LoadingState>(defaultKpi);
  const [isHighlightLoading, setIsHighlightLoading] = useState<LoadingState>(defaultKpi);
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [isBulkRemoveModalOpen, setIsBulkRemoveModalOpen] = useState(false);
  const [isAreaTargetModalOpen, setIsAreaTargetModalOpen] = useState(false);
  const [modalView, setModalView] = useState<KpiModalViews>(KpiModalViews.INITIAL);
  const [selectedKpiType, setSelectedKpiType] = useState<KpiType | null>(null);
  const [kpiModalDetails, setKpiModalDetails] = useState<KpiModalDetails>({
    isModalOpen: false,
    kpiType: 'dailyConversionRate',
    operation: KpiEditTypes.ADD,
    isAreaCap: false,
  });

  const isTablet = useMediaQuery(muiTheme.breakpoints.down('desktop'));
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  const canEditKpi = userPermissions.kpiTargets.canUpdate;
  const canEditAreaCap = userPermissions.areaCaps.canUpdate;
  const canDeleteKpi = userPermissions.kpiTargets.canDelete;
  const canDeleteAreaCap = userPermissions.areaCaps.canDelete;
  const canLockKpi = userPermissions.lockTargets.canUpdate;
  const canHighlightKpi = userPermissions.highlightWidget.canUpdate;

  const getHighlightedValue = (kpiType: KpiType) => {
    return !!highlightedKpiTargets.find((kpi) => kpi === kpiType);
  };

  // Get role for user
  const getRole = () => {
    const accessLevelText = canEditAreaCap
      ? AccessTypes.EDIT_ALL
      : canEditKpi
      ? AccessTypes.EDIT_KPI_TARGET
      : AccessTypes.ONLY_VIEW;

    const canEditKpiAndAreaCapText = canEditAreaCap
      ? 'KPI Targets and Area Cap'
      : canEditKpi
      ? 'KPI Targets'
      : 'KPI Targets and Area Cap';

    return {
      accessLevel: accessLevelText,
      canEditKpiAndAreaCap: canEditKpiAndAreaCapText,
    };
  };

  // Fetch Area Kpis
  const fetchAreaKpis = async () => {
    const response = await fetchKpisAreaDetails();

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  // Fetch KPI Targets
  const fetchTargets = async () => {
    const date = new Date();
    const formattedDate = format(date as Date, 'yyyy-MM-dd');
    const response = await fetchKpiTargets(formattedDate);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    const { data } = response;
    set({ kpiTargetsProps: data });

    return response.data;
  };

  // Set Banner Item
  const setBannerItem = (labelKey: string, labelDefault: string, type: BannerItemType) => {
    setBannerItems([
      {
        id: getUniqueId(),
        label: t(labelKey, labelDefault),
        type,
      },
    ]);
  };

  // Set Kpi Lock Loading State
  const setKpiLockLoadingState = (kpiName: string, isLoading: boolean) => {
    setIsLockLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [kpiName]: isLoading,
    }));
  };

  // Set Kpi highlight Loading State
  const setKpiHighlightLoadingState = (kpiName: string, isLoading: boolean) => {
    setIsHighlightLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [kpiName]: isLoading,
    }));
  };

  // Update Area Cap
  const updateAreaCap = async (updateRequest: UpdateKpiRequest[], isDelete: boolean) => {
    const response = isDelete ? await removeAreaCapData(updateRequest) : await updateAreaCapData(updateRequest);
    if (isFailureResponse(response)) {
      setModalView(KpiModalViews.ERROR);
      throw new Error();
    }
    const { data } = response;
    setModalView(KpiModalViews.SUCCESS);
    return data;
  };

  // Update Target
  const updateTarget = async (updateRequest: UpdateKpiRequest[], isDelete: boolean) => {
    const response = isDelete ? await removeKpiData(updateRequest) : await updateKpiData(updateRequest);

    if (isFailureResponse(response)) {
      setModalView(KpiModalViews.ERROR);
      throw new Error();
    }
    const { data } = response;
    setModalView(KpiModalViews.SUCCESS);
    return data;
  };

  // Update Lock
  const updateLock = async (request: UpdateKpiRequest[]) => {
    const { kpiName, isLocked } = request[0];

    setKpiLockLoadingState(kpiName, true);
    const response = await updateKpiLock(request);

    if (isFailureResponse(response)) {
      setBannerItem(kpiLock.lockErrorBanner.key, kpiLock.lockErrorBanner.defaultValue, 'error');
      setKpiLockLoadingState(kpiName, false);
      throw new Error();
    }

    const { data } = response;

    const bannerKey = isLocked ? kpiLock.lockSuccessBanner.key : kpiLock.unlockSuccessBanner.key;
    const bannerDefault = isLocked
      ? kpiLock.lockSuccessBanner.defaultValue
      : kpiLock.unlockSuccessBanner.defaultValue;

    setBannerItem(bannerKey, bannerDefault, 'success');
    setKpiLockLoadingState(kpiName, false);
    set({ kpiTargetsProps: data });
    return data;
  };

  // Trigger Kpi Lock
  const handleKpiLock = (request: UpdateKpiRequest[]) => {
    if (!canLockKpi) {
      setBannerItem(kpiLock.noPermissionBanner.key, kpiLock.noPermissionBanner.defaultValue, 'warning');
      return;
    }
    triggerUpdateTarget(() => updateLock(request));
  };

  // Update Highlight
  const updateHighlight = async (request: UpdateHighlightedWidgetsRequest, kpiType: KpiType) => {
    setKpiHighlightLoadingState(kpiType, true);
    const response = await updateHighlightedKpi(request);

    if (isFailureResponse(response)) {
      setBannerItem(
        kpiHighlight.highlightedErrorBanner.key,
        kpiHighlight.highlightedErrorBanner.defaultValue,
        'error',
      );
      setKpiHighlightLoadingState(kpiType, false);
      throw new Error();
    }

    const { data } = response;

    const bannerKey = request[kpiType] ? kpiHighlight.highlightedBanner.key : kpiHighlight.unhighlightedBanner.key;
    const bannerDefault = request[kpiType]
      ? kpiHighlight.highlightedBanner.defaultValue
      : kpiHighlight.unhighlightedBanner.defaultValue;

    setBannerItem(bannerKey, bannerDefault, 'success');
    setKpiHighlightLoadingState(kpiType, false);
    return data;
  };

  // Handle Area View Click
  const handleAreaViewClick = (kpiType: KpiType) => {
    setSelectedKpiType(kpiType);
    setIsAreaTargetModalOpen(true);
  };

  // Handle kpi Dropdown Select
  const handleSelectDropdown = (dropdownValue: KpiDropdownActions, kpiType: KpiType) => {
    const operationType = dropdownValue.includes('add')
      ? KpiEditTypes.ADD
      : dropdownValue.includes('edit')
      ? KpiEditTypes.UPDATE
      : KpiEditTypes.REMOVE;

    setKpiModalDetails({
      isModalOpen: true,
      kpiType,
      operation: operationType,
      isAreaCap: dropdownValue.includes('AreaCap'),
    });
  };

  // Trigger Kpi Lock
  const handleHighlightKpi = (kpiType: KpiType) => {
    if (!canHighlightKpi) {
      setBannerItem(
        kpiDropdown.cannotHighlightAreaKPITooltip.key,
        kpiDropdown.cannotHighlightAreaKPITooltip.defaultValue,
        'warning',
      );
      return;
    }
    const request = {
      [kpiType]: !getHighlightedValue(kpiType),
    };
    triggerHighlightKpi(() => updateHighlight(request, kpiType));
  };

  // Trigger Last Kpis
  const handleLastKpis = (kpiType: KpiType) => {
    setShowKpiHistoryPage(true);
    setSelectedLastKpiType(kpiType);
  };

  // Trigger Update Kpi target or area cap
  const handleUpdateKpi = (updateRequest: UpdateKpiRequest[], isDelete: boolean) => {
    if (!kpiModalDetails.isAreaCap) {
      triggerUpdateTarget(() => updateTarget(updateRequest, isDelete));
    } else {
      triggerUpdateAreaKpiDetails(() => updateAreaCap(updateRequest, isDelete));
    }
  };

  // Trigger Update/remove Kpi target or area cap from bulk modal
  const handleBulkRequest = (
    request: { target: UpdateKpiRequest[]; areaCap: UpdateKpiRequest[] },
    isDelete: boolean,
  ) => {
    const { target: updatedTargetRequest, areaCap: updatedAreaCapRequest } = request;

    if (!!updatedTargetRequest.length) triggerUpdateTarget(() => updateTarget(updatedTargetRequest, isDelete));
    if (!!updatedAreaCapRequest.length)
      triggerUpdateAreaKpiDetails(() => updateAreaCap(updatedAreaCapRequest, isDelete));
  };

  // Trigger initial Area Kpi Data
  useEffect(() => {
    triggerFetchKpisAreaDetails(fetchAreaKpis);
    triggerFetchKpisTargets(fetchTargets);
  }, []);

  // Update the areaKpiData state when kpisAreaDetails changes
  useEffect(() => {
    if (kpisAreaDetails) {
      setAreaKpiData(kpisAreaDetails);
    }
  }, [kpisAreaDetails]);

  // Update the kpiTargetsProps state when updatedKpiTargetProps changes
  useEffect(() => {
    if (updatedKpiTargetProps) {
      set({ kpiTargetsProps: updatedKpiTargetProps });
    }
  }, [updatedKpiTargetProps]);

  // Update the areaKpiData state when updatedAreacap changes
  useEffect(() => {
    if (updatedAreaKpiDetails) {
      setAreaKpiData(updatedAreaKpiDetails);
    }
  }, [updatedAreaKpiDetails]);

  // Update the highlightedKpiTargets state when updatedHighlightKpiTypes changes
  useEffect(() => {
    if (updatedHighlightKpiTypes) {
      set({
        highlightedKpiTargets: updatedHighlightKpiTypes,
      });
    }
  }, [updatedHighlightKpiTypes]);

  useEffect(() => {
    if (isBulkUpdateModalOpen || isBulkRemoveModalOpen) {
      setModalView(KpiModalViews.INITIAL);
    }
  }, [isBulkUpdateModalOpen, isBulkRemoveModalOpen]);

  useEffect(() => {
    if (!kpiModalDetails.isModalOpen && !isBulkUpdateModalOpen && !isBulkRemoveModalOpen) {
      setModalView(KpiModalViews.INITIAL);
    }
  }, [kpiModalDetails.isModalOpen, isBulkUpdateModalOpen, isBulkRemoveModalOpen]);

  return (
    <Page
      body={
        <>
          <KpiModal
            isOpen={kpiModalDetails.isModalOpen}
            onKpiModalClose={() => {
              setKpiModalDetails({
                ...kpiModalDetails,
                isModalOpen: false,
              });
            }}
            kpiType={kpiModalDetails.kpiType}
            updateType={kpiModalDetails.operation}
            modalView={modalView}
            onKpiModalRetry={() => setModalView(KpiModalViews.INITIAL)}
            isModalLoading={updateTargetLoading || updateAreaDetailsLoading}
            isAreaCap={kpiModalDetails.isAreaCap}
            handleUpdateKpi={handleUpdateKpi}
          />
          {(canEditAreaCap || canEditKpi) && (
            <BulkUpdateModal
              isOpen={isBulkUpdateModalOpen}
              areaKpiData={areaKpiData}
              kpiTargetsProps={kpiTargetsProps}
              isLoading={updateTargetLoading || updateAreaDetailsLoading}
              canEditKpi={canEditKpi}
              canEditAreaCap={canEditAreaCap}
              modalView={modalView}
              onClose={() => {
                setIsBulkUpdateModalOpen(false);
              }}
              updatedKpis={(request) => handleBulkRequest(request, false)}
            />
          )}
          {(canEditAreaCap || canEditKpi) && (
            <BulkRemoveModal
              isOpen={isBulkRemoveModalOpen}
              kpiTargetsProps={kpiTargetsProps}
              areaKpiData={areaKpiData}
              canDeleteKpi={canDeleteKpi}
              canDeleteAreaCap={canDeleteAreaCap}
              isLoading={updateTargetLoading || updateAreaDetailsLoading}
              modalView={modalView}
              onClose={() => {
                setIsBulkRemoveModalOpen(false);
              }}
              updatedKpis={(request) => handleBulkRequest(request, true)}
            />
          )}

          {selectedKpiType && (
            <AreaTargetView
              kpiType={selectedKpiType}
              isOpen={isAreaTargetModalOpen}
              onClose={() => {
                setIsAreaTargetModalOpen(false);
              }}
            />
          )}

          <Container wrap="nowrap" padding={[4]} data-testid="kpi-page-wrapper">
            <Banner isSticky items={bannerItems} onChange={(items) => setBannerItems(items)} />

            {!showKpiHistoryPage && (
              <>
                <StyledKPIHeader isTabletView={isTablet} data-testid="kpi-page-header">
                  <StyledTextContainer isTabletView={isTablet}>
                    <Typography type="h3" data-testid="store-name" overflow={'visible'}>
                      {storeName}
                    </Typography>
                    <Typography data-testid="kpi-accessibility">
                      <Trans
                        i18nKey={headerText.key}
                        defaults={headerText.defaultValue}
                        components={{ b: <b /> }}
                        values={{
                          accessLevel: getRole().accessLevel,
                          kpiProperty: getRole().canEditKpiAndAreaCap,
                        }}
                      />
                    </Typography>
                  </StyledTextContainer>
                  {(canEditAreaCap || canEditKpi) && (
                    <DropdownMenu
                      data-testid="bulk-update-button"
                      dropdownLabel={!isMobile ? t(bulkUpdate.key, bulkUpdate.defaultValue) : ''}
                      buttonContentProps={{
                        labelPosition: 'right',
                        iconOptions: {
                          icon: Icons.SETTINGS,
                          size: 'small',
                        },
                      }}
                      minWidth={'0px'}
                      isCompact
                      initialSelected={[]}
                      onSelect={(selectedItem) => {
                        if (selectedItem[0] === 'bulkUpdate') {
                          setIsBulkUpdateModalOpen(true);
                        } else {
                          setIsBulkRemoveModalOpen(true);
                        }
                      }}
                      allowTooltip
                      menuItems={[
                        {
                          key: 'bulkUpdate',
                          disabled: !Boolean(areaKpiData) || !Boolean(kpiTargetsProps),
                          item: (
                            <>
                              <Icon data-testid="infoIcon" icon={Icons.EDIT} size="small" margin={[0, 1, 0, 0]} />
                              <Typography margin={[0, 2, 0]}>
                                {t(updateKpiButton.key, updateKpiButton.defaultValue)}
                              </Typography>
                            </>
                          ),
                        },
                        {
                          key: 'bulkRemove',
                          disabled: !Boolean(areaKpiData) || !Boolean(kpiTargetsProps),
                          item: (
                            <>
                              <Icon
                                data-testid="infoIcon"
                                icon={Icons.TRASHBIN}
                                size="small"
                                margin={[0, 1, 0, 0]}
                              />
                              <Typography margin={[0, 2, 0]}>
                                {t(removeKpiButton.key, removeKpiButton.defaultValue)}
                              </Typography>
                            </>
                          ),
                        },
                      ]}
                    />
                  )}
                </StyledKPIHeader>

                <StyledKPIContent isTabletView={isTablet} data-testid="kpi-content-wrapper">
                  {(kpisAreaDetailsLoading || kpisTargetsLoading) && (
                    <Animation
                      title={t(fetchingKpis.key, fetchingKpis.defaultValue)}
                      animation={Animations.LOADING}
                    />
                  )}

                  {(kpisAreaDetailsError || kpisTargetsError) && (
                    <Animation
                      title={t(error.errorTitle.key, error.errorTitle.defaultValue)}
                      subtitle={t(error.errorSubtitle.key, error.errorSubtitle.defaultValue)}
                      animation={Animations.SAD}
                    />
                  )}

                  <KPIWrapper isTablet={isTablet} isMobile={isMobile}>
                    {!kpisAreaDetailsLoading &&
                      !kpisAreaDetailsError &&
                      areaKpiData &&
                      areaKpiData.map((kpiAreaDetail) => {
                        const { kpiName: kpiType } = kpiAreaDetail;
                        const isKpiHighlighted = getHighlightedValue(kpiType);
                        const isTargetLocked = getKpiTargetProps(kpiTargetsProps, kpiType)?.isLocked ?? false;
                        const lastUpdated = getKpiTargetProps(kpiTargetsProps, kpiType)?.updated ?? '';
                        const target = getKpiTargetValue(kpiTargetsProps, kpiType);

                        return (
                          <KpiCard
                            key={getUniqueId()}
                            kpiDetails={kpiAreaDetail}
                            isKpiHighlighted={isKpiHighlighted}
                            isTargetLocked={isTargetLocked}
                            lastUpdated={lastUpdated}
                            target={target}
                            updateHighlightLoading={isHighlightLoading[kpiType]}
                            updateLockLoading={isLockLoading[kpiType]}
                            onAreaViewClick={() => handleAreaViewClick(kpiType)}
                            handleKpiLock={(request) => handleKpiLock(request)}
                            handleHighlightKpi={() => handleHighlightKpi(kpiType)}
                            handleLastKpis={() => handleLastKpis(kpiType)}
                            handleSelectDropdown={(dropdownValue) => handleSelectDropdown(dropdownValue, kpiType)}
                          />
                        );
                      })}
                  </KPIWrapper>
                </StyledKPIContent>
              </>
            )}

            {showKpiHistoryPage && selectedLastKpiType && (
              <KpiHistoryDashboard kpiType={selectedLastKpiType} handleBack={() => setShowKpiHistoryPage(false)} />
            )}
          </Container>
        </>
      }
    />
  );
});
