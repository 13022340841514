import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../../../../../stories/atoms';
import { muiTheme } from '../../../../../../theme';
import { CustomerDataType, EngagementMetrics } from '../CustomerEngagement';
import { StyledMetricContainer } from '../CustomerEngagement.styles';
import { MetricSection } from '../metricSection/MetricSection';

type CustomerEngagementCardProps = {
  customerData: CustomerDataType;
  sendMetricName: (metricName: EngagementMetrics) => void;
};

export const CustomerEngagementCard: FC<CustomerEngagementCardProps> = ({ customerData, sendMetricName }) => {
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const { memberRecruited, memberIdentification, articleLookups } = customerData;

  return (
    <StyledMetricContainer data-testid="customer-engagement-card">
      <MetricSection
        key="memberRecruited"
        metricName="memberRecruited"
        icon={Icons.NEWMEMBER}
        subMetric1={{ name: 'recruitment', value: memberRecruited.recruitment }}
        subMetric2={{ name: 'INSC APP', value: memberRecruited.inStoreCareApp }}
        subMetric3={{ name: 'ratio', value: memberRecruited.ratio }}
        isMobileView={isMobileView}
        tooltipText="memberRecruitedTooltip"
        sendMetricDetails={(name) => sendMetricName(name)}
      />
      <MetricSection
        key="memberIdentification"
        metricName="memberIdentification"
        icon={Icons.BAG}
        subMetric1={{ name: 'membersIdentified', value: memberIdentification.membersIdentified }}
        subMetric2={{ name: 'memberPurchases', value: memberIdentification.memberPurchases }}
        subMetric3={{ name: 'conversionRate', value: memberIdentification.conversionRate }}
        isMobileView={isMobileView}
        tooltipText="memberIdentificationTooltip"
        sendMetricDetails={(name) => sendMetricName(name)}
      />
      <MetricSection
        key="articleLookups"
        metricName="articleLookups"
        icon={Icons.SEARCH}
        subMetric1={{ name: 'lookups', value: articleLookups.lookups }}
        subMetric2={{ name: 'purchasedArticles', value: articleLookups.purchasedArticles }}
        subMetric3={{ name: 'conversionRate', value: articleLookups.conversionRate }}
        isMobileView={isMobileView}
        tooltipText="articleLookupsTooltip"
        sendMetricDetails={(name) => sendMetricName(name)}
      />
    </StyledMetricContainer>
  );
};
