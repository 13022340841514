import {
  StorePerformanceInterval,
  TransactionFilterType,
  VendorType,
  appCustomersEndpoint,
  averageNetSalePerReceiptEndpoint,
  averagePiecesPerReceipt,
  budgetPlannedEndpoint,
  conversionRateWidgetEndpoint,
  countEndpoint,
  customerEngagementEndpoint,
  customerFeedbackEndpoint,
  effectiveHoursEndpoint,
  hourlyTurnoverWidgetEndpoint,
  newMembersEndpoint,
  orisCustomersEndpoint,
  queueLengthEndpoint,
  receiptEndpoint,
  receiptSalesAnalysisEndpoint,
  registeredReceiptsWidgetEndpoint,
  salesAnalysisEndpoint,
  scoEndpoint,
  shareOfReceiptsEndpoint,
  sptEndpoint,
  takt1Endpoint,
  tenderWidgetEndpoint,
  transactionEndpoint,
} from '../constants';
import { PageInfo, SalesReceiptType, Transaction, TransactionFilter } from '../global-state/types';

import { useAuthAxiosRequest } from './useAuthAxiosRequest';

import { useUser } from './useController';

export type StorePerformanceDailyRequest = {
  interval: StorePerformanceInterval.DAILY;
  date: string;
};

export type StorePerformanceWeeklyRequest = {
  interval: StorePerformanceInterval.WEEKLY;
  fromDate: string;
  toDate: string;
};

export type StorePerformanceRequest = StorePerformanceDailyRequest | StorePerformanceWeeklyRequest;

export type StorePerformanceItem = {
  timeUnit: string;
};

export type LastUpdatedTime = {
  lastUpdated: Date;
};

export type HistoricalComparisionSummary = {
  today: number;
  lastWeek: number;
  lastYear: number;
};

export type TransactionResponse = {
  data: Transaction[];
  paging: Omit<PageInfo, 'currentPageNumber' | 'pageSize' | 'rowsPerPageOptions'>;
};

export type TransactionCount = {
  totalCount: string;
  filterData: TransactionFilter;
};

export type TenderItem = {
  type: VendorType | string;
  count: number;
  amount: number;
  currency: string;
};

export type TenderResponse = LastUpdatedTime & {
  tenders: TenderItem[];
};

export type RegisteredReceiptsItem = {
  item: string;
  count: number;
};

export type RegisteredReceiptsResponse = LastUpdatedTime & {
  registeredReceipts: RegisteredReceiptsItem[];
};

export type HourlyTurnoverPerformanceItem = StorePerformanceItem & {
  amount: number;
};

export type HourlyTurnoverResponse = HistoricalComparisionSummary &
  LastUpdatedTime & {
    netSales: HourlyTurnoverPerformanceItem[];
  };

export type SCOHourlyTurnoverPerformanceItem = StorePerformanceItem & {
  netSaleSCO: number;
  netSale: number;
};

export type SCOHourlyTurnoverResponse = LastUpdatedTime & {
  netSalesSCO: SCOHourlyTurnoverPerformanceItem[];
};

export type ConversionRatePerformanceItem = StorePerformanceItem & {
  payingCustomers: number;
  nonPayingCustomers: number;
  numberOfTills: number;
};

export type ConversionRateResponse = HistoricalComparisionSummary &
  LastUpdatedTime & {
    conversionRate: ConversionRatePerformanceItem[];
  };

export type NewMembersPerformanceItem = StorePerformanceItem & {
  newMembersCount: number;
  totalTransactions: number;
  unrecruited: number;
};

export type NewMembersResponse = LastUpdatedTime & {
  newMembers: NewMembersPerformanceItem[];
};

export type AppCustomersPerformanceItem = StorePerformanceItem & {
  newCustomerCount: number;
};

export type AppCustomersResponse = {
  appCustomers: string;
  newAppCustomers: AppCustomersPerformanceItem[];
};

export type ShareOfReceiptsItem = StorePerformanceItem & {
  totalTransactions: number;
  memberTransactions: number;
};

export type ShareOfReceiptResponse = LastUpdatedTime & { shareOfReceipts: ShareOfReceiptsItem[] };

export type SCOShareOfReceiptsItem = StorePerformanceItem & {
  totalTransactions: number;
  totalSCOTransactions: number;
};

export type SCOShareOfReceiptResponse = LastUpdatedTime & { shareOfReceiptsSCO: SCOShareOfReceiptsItem[] };

export type EffectiveHoursResponse = LastUpdatedTime & HistoricalComparisionSummary;

export type BudgetPlannedData = {
  budget: number;
  planned: number;
};

export type SalesNetPerReceiptPerformanceItem = StorePerformanceItem & {
  amount: number;
};

export type SalesNetPerReceiptResponse = LastUpdatedTime &
  HistoricalComparisionSummary & {
    netSalePerReceipt: SalesNetPerReceiptPerformanceItem[];
  };

export type ReceiptSalesAnalysisItem = HistoricalComparisionSummary;

export type ReceiptSalesAnalysisResponse = {
  [SalesReceiptType.Spt]: ReceiptSalesAnalysisItem;
  [SalesReceiptType.Takt1]: ReceiptSalesAnalysisItem;
};

export type ReceiptSalesTimeResponse = LastUpdatedTime & ReceiptSalesAnalysisResponse;

export type AveragePiecesPerReceiptPerformanceItem = StorePerformanceItem & {
  amount: number;
};

export type AveragePiecesPerReceiptDataResponse = LastUpdatedTime &
  HistoricalComparisionSummary & {
    piecesPerReceipt: AveragePiecesPerReceiptPerformanceItem[];
  };

export type TillType = 'DEFAULT' | 'SELFCHECKOUT' | 'APPCHECKOUT' | 'null';

export type QueueLengthTillData = {
  queueCount: number;
  tillId: string;
  tillType: TillType;
  transactions: number;
};

export type QueueLengthPerformanceItem = StorePerformanceItem & {
  tillData: QueueLengthTillData[];
};

export type QueueLengthCheckoutExperience = {
  happyCheckoutExperienceCount: number;
  totalCheckoutExperienceCount: number;
};

export type QueueLengthResponse = LastUpdatedTime & {
  queueLength: QueueLengthPerformanceItem[];
  checkoutExperience: QueueLengthCheckoutExperience;
};

export type QueueLengthForecastPerformanceItem = StorePerformanceItem & {
  defaultQueueCount: number;
  scoQueueCount: number;
  defaultTransactionsCount: number;
  scoTransactionsCount: number;
};

export type CustomerFeedback = {
  rating: number;
  text: string;
};

export type CustomerFeedbackResponse = LastUpdatedTime & {
  feedback: CustomerFeedback[];
};

export type ORISResponse = LastUpdatedTime & {
  totalORISCustomer: number;
  convertedORISCustomers: number;
  loyalOrisCustomerCount: number;
  orisAverageSaleTime: number;
};

export type HourlySales = { hour: string; netSale: number };

export type StoreSalesData = {
  storeId: string;
  currency: string;
  hourlySales: HourlySales[];
  lastUpdated: string;
};

export type CustomerEngagementResponse = LastUpdatedTime & {
  storeTraffic: number;
  memberRecruited: {
    recruitment: number;
    inStoreCareApp: number;
  };
  memberIdentification: {
    membersIdentified: number;
    memberPurchases: number;
  };
  articleLookups: {
    lookups: number;
    purchasedArticles: number;
  };
};

export const useTransaction = () => {
  const {
    get: { currentStoreId, keyToken },
  } = useUser();

  const { authAxiosRequest } = useAuthAxiosRequest(keyToken);

  const fetchTenderData = async (date: string) => {
    return authAxiosRequest<TenderResponse>(`/transaction-search/${currentStoreId}${tenderWidgetEndpoint}`, {
      params: {
        date,
      },
    });
  };

  const fetchRegisteredReceiptsData = async (businessDayDateFrom: string, businessDayDateTo: string) => {
    return authAxiosRequest<RegisteredReceiptsResponse>(
      `/transaction-search/${currentStoreId}${registeredReceiptsWidgetEndpoint}`,
      {
        params: {
          businessDayDateFrom,
          businessDayDateTo,
        },
      },
    );
  };

  const fetchTransactions = async (
    businessDayDateFrom: string,
    businessDayDateTo: string,
    pageNo: number,
    pageSize: number,
    paymentType?: string,
    retailType?: string,
    cashier?: string,
    countFromOldPartition?: number,
    nextBusinessDayDate?: string,
    storeId?: string,
  ) => {
    const transactionResponse = await authAxiosRequest<TransactionResponse>(
      `/transaction-search/${storeId ? storeId : currentStoreId}${transactionEndpoint}`,
      {
        params: {
          businessDayDateFrom,
          businessDayDateTo,
          paymentType,
          retailType,
          cashier,
          pageNo,
          pageSize,
          countFromOldPartition,
          nextBusinessDayDate,
        },
      },
    );

    return transactionResponse;
  };

  const fetchCount = async (
    businessDayDateFrom: string,
    businessDayDateTo: string,
    filterType?: TransactionFilterType,
    paymentType?: string,
    retailType?: string,
    cashier?: string,
    storeId?: string,
  ) => {
    const countResponse = await authAxiosRequest<TransactionCount>(
      `/transaction-search/${storeId ? storeId : currentStoreId}${countEndpoint}`,
      {
        params: {
          businessDayDateFrom,
          businessDayDateTo,
          filterType,
          paymentType,
          retailType,
          cashier,
        },
      },
    );

    return countResponse;
  };

  const fetchReceiptData = async (businessDayDate: string, receiptNumber: string) => {
    const response = await authAxiosRequest<string[]>(
      `/transaction-search/${currentStoreId}${receiptEndpoint}/${receiptNumber}`,
      {
        params: {
          businessDayDate,
        },
      },
    );

    return response;
  };

  const fetchHourlyTurnoverWidgetData = async (request: StorePerformanceRequest) => {
    const hourlyTurnoverResponse = await authAxiosRequest<HourlyTurnoverResponse>(
      `/transaction-search/${currentStoreId}${hourlyTurnoverWidgetEndpoint}`,
      {
        params: request,
      },
    );

    return hourlyTurnoverResponse;
  };

  const fetchSCOHourlyTurnoverWidgetData = async (request: StorePerformanceRequest) => {
    const hourlyTurnoverResponse = await authAxiosRequest<SCOHourlyTurnoverResponse>(
      `/transaction-search/${currentStoreId}${hourlyTurnoverWidgetEndpoint}${scoEndpoint}`,
      {
        params: request,
      },
    );

    return hourlyTurnoverResponse;
  };

  const fetchNetSalesBudget = async (date: string) => {
    const netSaleBudgetResponse = await authAxiosRequest<BudgetPlannedData>(
      `/transaction-search/${currentStoreId}${hourlyTurnoverWidgetEndpoint}${budgetPlannedEndpoint}?date=${date}`,
    );

    return netSaleBudgetResponse;
  };

  const fetchConversionRateWidgetData = async (request: StorePerformanceRequest) => {
    const conversionRateResponse = await authAxiosRequest<ConversionRateResponse>(
      `/transaction-search/${currentStoreId}${conversionRateWidgetEndpoint}`,
      {
        params: request,
      },
    );

    return conversionRateResponse;
  };

  const fetchShareOfReceipts = async (request: StorePerformanceRequest) => {
    const shareOfReceiptsResponse = await authAxiosRequest<ShareOfReceiptResponse>(
      `/transaction-search/${currentStoreId}${shareOfReceiptsEndpoint}`,
      {
        params: request,
      },
    );

    return shareOfReceiptsResponse;
  };

  const fetchSCOShareOfReceiptsData = async (request: StorePerformanceRequest) => {
    const shareOfReceiptsResponse = await authAxiosRequest<SCOShareOfReceiptResponse>(
      `/transaction-search/${currentStoreId}${shareOfReceiptsEndpoint}${scoEndpoint}`,
      {
        params: request,
      },
    );

    return shareOfReceiptsResponse;
  };

  const fetchNewMembersData = async (request: StorePerformanceRequest) => {
    const newMembersResponse = await authAxiosRequest<NewMembersResponse>(
      `/transaction-search/${currentStoreId}${newMembersEndpoint}`,
      {
        params: request,
      },
    );
    return newMembersResponse;
  };

  const fetchAppCustomersData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<AppCustomersResponse>(
      `/transaction-search/${currentStoreId}${appCustomersEndpoint}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchEffectiveHoursData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<EffectiveHoursResponse>(
      `/transaction-search/${currentStoreId}${effectiveHoursEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  const fetchEffectiveHoursBudget = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<BudgetPlannedData>(
      `/transaction-search/${currentStoreId}${effectiveHoursEndpoint}${budgetPlannedEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  const fetchSalesNetPerReceiptData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<SalesNetPerReceiptResponse>(
      `/transaction-search/${currentStoreId}${averageNetSalePerReceiptEndpoint}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchReceiptSalesAnalysisData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<ReceiptSalesTimeResponse>(
      `/transaction-search/${currentStoreId}${receiptSalesAnalysisEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  const fetchTakt1Budget = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<BudgetPlannedData>(
      `/transaction-search/${currentStoreId}${takt1Endpoint}${budgetPlannedEndpoint}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchSptBudget = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<BudgetPlannedData>(
      `/transaction-search/${currentStoreId}${sptEndpoint}${budgetPlannedEndpoint}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchAveragePiecesPerReceiptData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<AveragePiecesPerReceiptDataResponse>(
      `/transaction-search/${currentStoreId}${averagePiecesPerReceipt}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchQueueLengthData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<QueueLengthResponse>(
      `/transaction-search/v2/${currentStoreId}${queueLengthEndpoint}`,
      {
        params: request,
      },
    );

    return response;
  };

  const fetchCustomerFeedbackData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<CustomerFeedbackResponse>(
      `/transaction-search/${currentStoreId}${customerFeedbackEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  const fetchORISData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<ORISResponse>(
      `/transaction-search/${currentStoreId}${orisCustomersEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  const fetchSalesAnalysisData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<StoreSalesData[]>(
      `/transaction-search/${currentStoreId}${salesAnalysisEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  const fetchCustomerEngagementData = async (request: StorePerformanceRequest) => {
    const response = await authAxiosRequest<CustomerEngagementResponse>(
      `/transaction-search/${currentStoreId}${customerEngagementEndpoint}`,
      {
        params: request,
      },
    );
    return response;
  };

  return {
    fetchTenderData,
    fetchRegisteredReceiptsData,
    fetchTransactions,
    fetchCount,
    fetchReceiptData,
    fetchHourlyTurnoverWidgetData,
    fetchSCOHourlyTurnoverWidgetData,
    fetchNetSalesBudget,
    fetchConversionRateWidgetData,
    fetchShareOfReceipts,
    fetchSCOShareOfReceiptsData,
    fetchNewMembersData,
    fetchAppCustomersData,
    fetchReceiptSalesAnalysisData,
    fetchTakt1Budget,
    fetchSptBudget,
    fetchAveragePiecesPerReceiptData,
    fetchSalesNetPerReceiptData,
    fetchEffectiveHoursData,
    fetchEffectiveHoursBudget,
    fetchQueueLengthData,
    fetchCustomerFeedbackData,
    fetchORISData,
    fetchSalesAnalysisData,
    fetchCustomerEngagementData,
  };
};
