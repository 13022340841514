import { useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import {
  BudgetPlannedData,
  HourlyTurnoverPerformanceItem,
  useAsyncAction,
  useKpiTargetDistriubution,
  useStoreInformation,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { ContainerDrawer } from '../../../../../stories/molecules/container-drawer/ContainerDrawer';
import { muiTheme } from '../../../../../theme';
import { homeTranslations, kpiTargetTransalations } from '../../../../../translations';
import { formatValue, getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import {
  BudgetPlannedContent,
  getBudgetAndPlannedPermissions,
} from '../../../common/budgetPlannedContent/BudgetPlannedContent';
import { WidgetMetricTile, WidgetMetricType } from '../../../common/metricTile/WidgetMetricTile';
import { WidgetMetricTilesContainer } from '../../../common/metricTile/WidgetMetricTile.styles';
import { getDefaultStatus } from '../../../common/metricTile/util';
import { StyledTrendIconComponent } from '../../../common/styledTrendIcon/StyledTrendIcon';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetMobileLayout } from '../../../common/widgetMobileLayout/WidgetMobileLayout';
import { StyledView, StyledWrapper } from './HourlyTurnoverWidget.styles';

type HourlyTurnoverWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const HourlyTurnoverWidget: FC<HourlyTurnoverWidgetProps> = observer(
  ({ interval, currentDate, hasPermissionToEditKpi, isToday, handleDropdownDetails, fromDate, toDate }) => {
    const {
      get: { userPermissions, kpiTargetsProps },
    } = useUser();
    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { currencyCode } = useStoreInformation();
    const { fetchHourlyTurnoverWidgetData, fetchNetSalesBudget } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { transactionEventsCount } = useEventContext();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const [isBudgetSliderOpen, setIsBudgetSliderOpen] = useState(false);
    const [today, setToday] = useState(0);
    const [lastWeek, setLastWeek] = useState(0);
    const [lastYear, setLastYear] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<HourlyTurnoverPerformanceItem>();

    const {
      result: netSaleBudgetPlanned,
      isLoading: netSaleBudgetPlannedLoading,
      isError: netSaleBudgetPlannedError,
      triggerAction: triggerFetchNetSaleBudgetPlanned,
    } = useAsyncAction<BudgetPlannedData>();

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyTurnOver');
    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyTurnOver')?.isLocked ?? false;

    const { t, i18n } = useTranslation();
    const { hourlyTurnover, kpiTargets, budgetPlanned, common: commontTranslations } = homeTranslations;

    const { canViewBudget, canViewPlanned, showBudgetAndPlanned } =
      getBudgetAndPlannedPermissions(userPermissions);

    const itemValueResolver = (item: HourlyTurnoverPerformanceItem) => {
      return parseFloat(item.amount.toFixed(2));
    };

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchHourlyTurnoverWidgetData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setToday(response.data.today);
      setLastWeek(response.data.lastWeek);
      setLastYear(response.data.lastYear);
      setLastUpdated(response.data.lastUpdated);

      return response.data.netSales;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<HourlyTurnoverPerformanceItem>('netSale');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [isToday]);

    const getTotalNetSales = (items: (HourlyTurnoverPerformanceItem | null)[]) => {
      return items.reduce((acc, val) => acc + (val ? val.amount : 0), 0);
    };

    const fetchBudget = async () => {
      // TODO: change this when BE sends weekly budget plan data for net sale
      const date = format(currentDate as Date, 'yyyy-MM-dd');
      const response = await fetchNetSalesBudget(date);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    };

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getForecastDataAction: fetchForecastData,
        getHasValue: (item) => Math.floor(item.amount) !== 0,
      });
    };

    useEffect(() => {
      if (interval === StorePerformanceInterval.WEEKLY) return;
      triggerFetchNetSaleBudgetPlanned(fetchBudget);
    }, [currentDate, transactionEventsCount, interval]);

    useEffect(() => {
      fetchWidgetData();
      setIsBudgetSliderOpen(false);
    }, [interval, isToday, currentDate, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailyTurnOver',
    });

    const totalNetSales = getTotalNetSales(performanceItems);
    const forecastTotalNetSales = getTotalNetSales([...performanceItems, ...forecastPerformanceItems]);

    const isEditKPIDisabled = !hasPermissionToEditKpi;

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);
    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    const targetProgressPercent = target && target != 0 ? (forecastTotalNetSales / target) * 100 : 0;

    const isPreviousDay = interval === StorePerformanceInterval.WEEKLY || !isToday;

    const getTooltipText = () => {
      let translation = '';

      if (!showBudgetAndPlanned) {
        translation = t(
          budgetPlanned.noPermissionBudgetTooltip.key,
          budgetPlanned.noPermissionBudgetTooltip.defaultValue,
        );
      }

      return (
        <Container padding={[1]}>
          <Typography color="white">{translation}</Typography>
        </Container>
      );
    };
    const menuItems = [
      {
        key: target ? 'editkpi' : 'addkpi',
        disabled: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        hasTooltip: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : isPreviousDay
                ? t(kpiTargets.previousDayTooltip.key, kpiTargets.previousDayTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
      {
        key: 'showBudget',
        disabled: !showBudgetAndPlanned || interval !== StorePerformanceInterval.DAILY, // Show budget only for daily interval until BE adds support for it.
        hasTooltip: !showBudgetAndPlanned || interval !== StorePerformanceInterval.DAILY,
        tooltipText: getTooltipText(),
        item: (
          <Typography margin={[0, 2, 0]}>
            {t(budgetPlanned.budgetDropdown.key, budgetPlanned.budgetDropdown.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <ContainerDrawer
        isOpen={isBudgetSliderOpen}
        onClose={() => setIsBudgetSliderOpen(false)}
        sliderContent={
          <BudgetPlannedContent
            title={t(hourlyTurnover.title.key, hourlyTurnover.title.defaultValue)}
            currentData={totalNetSales}
            isLoading={netSaleBudgetPlannedLoading}
            isError={netSaleBudgetPlannedError}
            canViewBudget={canViewBudget}
            canViewPlanned={canViewPlanned}
            budgetPlanned={netSaleBudgetPlanned}
            budgetNoData={netSaleBudgetPlanned?.budget === 0 || interval === StorePerformanceInterval.WEEKLY}
            plannedNoData={netSaleBudgetPlanned?.planned === 0 || interval === StorePerformanceInterval.WEEKLY}
          />
        }
        width={isMobile ? '250px' : '300px'}
      >
        <WidgetCardContent
          headerIcon={Icons.SALESEFFICIENCY}
          headerText={t(hourlyTurnover.title.key, hourlyTurnover.title.defaultValue)}
          subHeaderText={t(hourlyTurnover.subTitle.key, hourlyTurnover.subTitle.defaultValue)}
          lastUpdatedDate={lastUpdated}
          isLoading={performanceItemsLoading}
          isError={performanceItemsError}
          errorText={t(hourlyTurnover.error.key, hourlyTurnover.error.defaultValue)}
          errorRetryHandler={() => {
            fetchBudget();
            fetchWidgetData();
          }}
          isNoData={isNoData}
          headerSideContent={
            <StyledWrapper>
              <DropdownMenu
                data-testid="kpiDropdown"
                dropdownLabel={''}
                buttonContentProps={{
                  iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
                }}
                menuItems={menuItems}
                minWidth={'0px'}
                isCompact
                initialSelected={[]}
                onSelect={(selectedItems) => {
                  if ((selectedItems[0] === 'editkpi' || selectedItems[0] === 'addkpi') && !isEditKPIDisabled) {
                    handleDropdownDetails('dailyTurnOver', selectedItems[0] as KpiDropdownActions);
                  } else if (selectedItems[0] === 'showBudget') {
                    setIsBudgetSliderOpen(true);
                  }
                }}
                isOnlyIcon
                allowTooltip
              />
            </StyledWrapper>
          }
          content={
            <>
              {!isMobile && (
                <Container wrap="nowrap" data-testid="hourly-turnover-wrapper" height="100%">
                  <StyledView isMobile={isMobile}>
                    <WidgetMetricTilesContainer>
                      {showForecast && (
                        <WidgetMetricTile
                          metricProperties={{
                            type: WidgetMetricType.ForecastMetric,
                            properties: {
                              metricValue: `${formatValue(forecastTotalNetSales, {
                                locale: i18n.language,
                              })} ${currencyCode}`,
                              forecastIndicator: getDefaultStatus(targetProgressPercent),
                            },
                          }}
                        />
                      )}

                      <WidgetMetricTile
                        metricProperties={{
                          type: WidgetMetricType.RegularMetric,
                          properties: {
                            metricTitle: 'KPI',
                            metricValue: t(
                              kpiTargetTransalations.kpiWidgets.dailyTurnOver.key,
                              kpiTargetTransalations.kpiWidgets.dailyTurnOver.defaultValue,
                            ),
                          },
                        }}
                      />

                      <WidgetMetricTile
                        metricProperties={{
                          type: WidgetMetricType.LegendMetric,
                          properties: {
                            metricTitle: t(
                              homeTranslations.hourlyTurnover.totalSales.key,
                              homeTranslations.hourlyTurnover.totalSales.defaultValue,
                            ),
                            metricValue: `${formatValue(totalNetSales, {
                              locale: i18n.language,
                            })} ${currencyCode}`,
                            legendColor: 'validationGreen',
                          },
                        }}
                      />

                      {target !== null && (
                        <WidgetMetricTile
                          metricProperties={{
                            type: WidgetMetricType.LegendMetric,
                            properties: {
                              metricTitle: t(
                                kpiTargetTransalations.kpiProperties.target.title.key,
                                kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                              ),
                              metricValue: `${formatValue(target, {
                                locale: i18n.language,
                              })} ${currencyCode}`,
                              legendColor: 'tagBlue',
                            },
                          }}
                        />
                      )}

                      <WidgetMetricTile
                        metricProperties={{
                          type: WidgetMetricType.RegularMetric,
                          properties: {
                            metricTitle: t(
                              commontTranslations.lastWeek.key,
                              commontTranslations.lastWeek.defaultValue,
                            ),
                            metricValue: (
                              <StyledTrendIconComponent
                                originalAmount={today}
                                previousAmount={lastWeek}
                                direction="horizontal"
                                valueFormatter={(value) =>
                                  `${formatValue(value, {
                                    locale: i18n.language,
                                  })} ${currencyCode}`
                                }
                                textOptions={{ type: 'caption' }}
                                hasPercentage={false}
                              />
                            ),
                          },
                        }}
                      />

                      <WidgetMetricTile
                        metricProperties={{
                          type: WidgetMetricType.RegularMetric,
                          properties: {
                            metricTitle: t(
                              commontTranslations.lastYear.key,
                              commontTranslations.lastYear.defaultValue,
                            ),
                            metricValue: (
                              <StyledTrendIconComponent
                                originalAmount={today}
                                previousAmount={lastYear}
                                direction="horizontal"
                                valueFormatter={(value) =>
                                  `${formatValue(value, {
                                    locale: i18n.language,
                                  })} ${currencyCode}`
                                }
                                textOptions={{ type: 'caption' }}
                                hasPercentage={false}
                              />
                            ),
                          },
                        }}
                      />
                    </WidgetMetricTilesContainer>

                    <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
                      <WidgetChart
                        performanceItemsChartName={t(
                          hourlyTurnover.subTitle.key,
                          hourlyTurnover.subTitle.defaultValue,
                        )}
                        performanceItems={performanceItems}
                        filteredPerformanceItems={filteredPerformanceItems}
                        forecastPerformanceItems={forecastPerformanceItems}
                        targetItems={targetItems}
                        itemValueResolver={itemValueResolver}
                        showXAxis={true}
                        yAxisFormatter={(value) =>
                          `${Intl.NumberFormat(i18n.language, { notation: 'compact' }).format(value)}`
                        }
                        tooltipFormatter={(value) =>
                          `${formatValue(value, {
                            locale: i18n.language,
                          })} ${currencyCode}`
                        }
                      />
                    </Container>
                  </StyledView>
                </Container>
              )}

              {isMobile && (
                <Container padding={[2, 6, 0]} wrap="nowrap" data-testid="chart" style={{ flex: 1, minHeight: 0 }}>
                  {showForecast && (
                    <WidgetMetricTile
                      metricProperties={{
                        type: WidgetMetricType.ForecastMetric,
                        properties: {
                          metricValue: `${formatValue(forecastTotalNetSales, {
                            locale: i18n.language,
                          })} ${currencyCode}`,
                          forecastIndicator: getDefaultStatus(targetProgressPercent),
                        },
                      }}
                    />
                  )}

                  <WidgetMobileLayout
                    metricItems={[
                      {
                        label: 'KPI',
                        content: t(
                          kpiTargetTransalations.kpiWidgets.dailyTurnOver.key,
                          kpiTargetTransalations.kpiWidgets.dailyTurnOver.defaultValue,
                        ),
                      },
                      {
                        label: t(
                          homeTranslations.hourlyTurnover.totalSales.key,
                          homeTranslations.hourlyTurnover.totalSales.defaultValue,
                        ),
                        content: `${formatValue(totalNetSales, {
                          locale: i18n.language,
                        })} ${currencyCode}`,
                      },
                      {
                        label: t(
                          kpiTargetTransalations.kpiProperties.target.title.key,
                          kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                        ),
                        content:
                          target === null
                            ? `-`
                            : `${formatValue(target, {
                                locale: i18n.language,
                              })} ${currencyCode}`,
                      },

                      {
                        label: t(commontTranslations.lastWeek.key, commontTranslations.lastWeek.defaultValue),
                        content: (
                          <StyledTrendIconComponent
                            originalAmount={today}
                            previousAmount={lastWeek}
                            direction="horizontal"
                            valueFormatter={(value) =>
                              `${formatValue(value, {
                                locale: i18n.language,
                              })} ${currencyCode}`
                            }
                            textOptions={{ type: 'caption' }}
                            hasPercentage={false}
                          />
                        ),
                      },
                      {
                        label: t(commontTranslations.lastYear.key, commontTranslations.lastYear.defaultValue),
                        content: (
                          <StyledTrendIconComponent
                            originalAmount={today}
                            previousAmount={lastYear}
                            direction="horizontal"
                            valueFormatter={(value) =>
                              `${formatValue(value, {
                                locale: i18n.language,
                              })} ${currencyCode}`
                            }
                            textOptions={{ type: 'caption' }}
                            hasPercentage={false}
                          />
                        ),
                      },
                    ]}
                    renderChart={() => {
                      return (
                        <WidgetChart
                          performanceItemsChartName={t(
                            hourlyTurnover.subTitle.key,
                            hourlyTurnover.subTitle.defaultValue,
                          )}
                          performanceItems={performanceItems}
                          filteredPerformanceItems={filteredPerformanceItems}
                          forecastPerformanceItems={forecastPerformanceItems}
                          targetItems={targetItems}
                          itemValueResolver={itemValueResolver}
                          showXAxis={true}
                          yAxisFormatter={(value) =>
                            `${Intl.NumberFormat(i18n.language, { notation: 'compact' }).format(value)}`
                          }
                          tooltipFormatter={(value) =>
                            `${formatValue(value, {
                              locale: i18n.language,
                            })} ${currencyCode}`
                          }
                          sparklineEnabled
                          customYAxis={{ show: false }}
                        />
                      );
                    }}
                  />
                </Container>
              )}
            </>
          }
          toolTipText={t(hourlyTurnover.toolTip.key, hourlyTurnover.toolTip.defaultValue)}
        />
      </ContainerDrawer>
    );
  },
);
