import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import {
  SCOShareOfReceiptsItem,
  useKpiTargetDistriubution,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { muiTheme } from '../../../../../theme';
import { homeTranslations, kpiTargetTransalations } from '../../../../../translations';
import { getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { WidgetMetricTile, WidgetMetricType } from '../../../common/metricTile/WidgetMetricTile';
import { WidgetMetricTilesContainer } from '../../../common/metricTile/WidgetMetricTile.styles';
import { getDefaultStatus } from '../../../common/metricTile/util';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetMobileLayout } from '../../../common/widgetMobileLayout/WidgetMobileLayout';
import { StyledView } from '../shareOfReceipts/ShareOfReceipts.styles';

export type SCOShareOfReceiptsProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  transactionEventsCount: number;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const SCOShareOfReceipts: FC<SCOShareOfReceiptsProps> = observer(
  ({
    interval,
    currentDate,
    hasPermissionToEditKpi,
    transactionEventsCount,
    isToday,
    handleDropdownDetails,
    fromDate,
    toDate,
  }) => {
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const {
      get: { currentStoreId, kpiTargetsProps },
    } = useUser();

    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { fetchSCOShareOfReceiptsData } = useTransaction();
    const { fetchForecastForKpis } = useStores();

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<SCOShareOfReceiptsItem>();

    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailySCOShareOfReceipts')?.isLocked ?? false;

    const { t } = useTranslation();
    const target = getKpiTargetValue(kpiTargetsProps, 'dailySCOShareOfReceipts');

    const {
      scoShareOfReceipts: scoShareOfReceiptsTranslations,
      common: commonTranslations,
      kpiTargets,
    } = homeTranslations;

    const itemValueResolver = (item: SCOShareOfReceiptsItem) => {
      const { totalSCOTransactions, totalTransactions } = item;
      return totalTransactions === 0 ? 0 : parseInt(((totalSCOTransactions / totalTransactions) * 100).toFixed(2));
    };

    const fetchData = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchSCOShareOfReceiptsData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.shareOfReceiptsSCO;
    }, [interval, currentDate]);

    const fetchForecastData = async () => {
      const response = await fetchForecastForKpis<SCOShareOfReceiptsItem>('shareOfReceiptSCO');
      if (isFailureResponse(response)) {
        throw new Error();
      }
      return response.data;
    };

    const getShareOfReceipts = (items: (SCOShareOfReceiptsItem | null)[]) => {
      let currentTotalSCOTransactions = 0;
      let currentTotalTransactions = 0;

      items.forEach((item) => {
        if (item === null) return;

        const { totalSCOTransactions, totalTransactions } = item;

        if (totalTransactions === 0) return;

        currentTotalSCOTransactions += totalSCOTransactions;
        currentTotalTransactions += totalTransactions;
      });

      const currentShareOfReceipts =
        currentTotalTransactions === 0
          ? 0
          : parseFloat(((currentTotalSCOTransactions / currentTotalTransactions) * 100).toFixed(2));

      return {
        currentShareOfReceipts: currentShareOfReceipts,
        currentTotalSCOTransactions: currentTotalSCOTransactions,
      };
    };

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getHasValue: (item) => item.totalTransactions !== 0,
        getForecastDataAction: fetchForecastData,
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate, isToday, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailySCOShareOfReceipts',
    });

    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    const isEditKPIDisabled = performanceItemsError || !hasPermissionToEditKpi;

    const { currentShareOfReceipts, currentTotalSCOTransactions } = getShareOfReceipts(filteredPerformanceItems);

    const isNoData = currentShareOfReceipts === 0;

    const { currentShareOfReceipts: forecastShareOfReceipts } = getShareOfReceipts([
      ...filteredPerformanceItems,
      ...forecastPerformanceItems,
    ]);

    const targetProgressPercent = target && target != 0 ? (forecastShareOfReceipts / target) * 100 : 0;

    const isPreviousDay = interval === StorePerformanceInterval.WEEKLY || !isToday;

    const menuItems = [
      {
        key: target ? 'editKpi' : 'addKpi',
        disabled: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        hasTooltip: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : isPreviousDay
                ? t(kpiTargets.previousDayTooltip.key, kpiTargets.previousDayTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <WidgetCardContent
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(scoShareOfReceiptsTranslations.title.key, scoShareOfReceiptsTranslations.title.defaultValue)}
        subHeaderText={
          t(scoShareOfReceiptsTranslations.subTitle.key, scoShareOfReceiptsTranslations.subTitle.defaultValue) +
          ' ' +
          currentStoreId
        }
        isLoading={performanceItemsLoading}
        toolTipText={t(
          scoShareOfReceiptsTranslations.toolTip.key,
          scoShareOfReceiptsTranslations.toolTip.defaultValue,
        )}
        isError={performanceItemsError}
        errorText={t(scoShareOfReceiptsTranslations.error.key, scoShareOfReceiptsTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData || false}
        noDataText={t(commonTranslations.noSCOReceipts.key, commonTranslations.noSCOReceipts.defaultValue)}
        lastUpdatedDate={lastUpdated}
        headerSideContent={
          <DropdownMenu
            data-testid="kpiDropdown"
            dropdownLabel={''}
            buttonContentProps={{
              iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
            }}
            menuItems={menuItems}
            minWidth={'0px'}
            isCompact
            initialSelected={[]}
            onSelect={(selectedItem) => {
              if (!isEditKPIDisabled) {
                handleDropdownDetails('dailySCOShareOfReceipts', selectedItem[0] as KpiDropdownActions);
              }
            }}
            isOnlyIcon
            allowTooltip
          />
        }
        content={
          <>
            {!isMobile && (
              <Container padding={[2]} wrap="nowrap" data-testid="sco-share-of-receipts-wrapper" height="100%">
                <StyledView>
                  <WidgetMetricTilesContainer>
                    {showForecast && (
                      <WidgetMetricTile
                        metricProperties={{
                          type: WidgetMetricType.ForecastMetric,
                          properties: {
                            metricValue: `${forecastShareOfReceipts}%`,
                            forecastIndicator: getDefaultStatus(targetProgressPercent),
                          },
                        }}
                      />
                    )}

                    <WidgetMetricTile
                      metricProperties={{
                        type: WidgetMetricType.RegularMetric,
                        properties: {
                          metricTitle: 'KPI',
                          metricValue: t(
                            kpiTargetTransalations.kpiWidgets.dailySCOShareOfReceipts.key,
                            kpiTargetTransalations.kpiWidgets.dailySCOShareOfReceipts.defaultValue,
                          ),
                        },
                      }}
                    />

                    <WidgetMetricTile
                      metricProperties={{
                        type: WidgetMetricType.LegendMetric,
                        properties: {
                          metricTitle: t(
                            homeTranslations.scoShareOfReceipts.totalShareOfReceipts.key,
                            homeTranslations.scoShareOfReceipts.totalShareOfReceipts.defaultValue,
                          ),
                          metricValue: `${currentShareOfReceipts}%`,
                          legendColor: 'validationGreen',
                        },
                      }}
                    />

                    {target !== null && (
                      <WidgetMetricTile
                        metricProperties={{
                          type: WidgetMetricType.LegendMetric,
                          properties: {
                            metricTitle: t(
                              kpiTargetTransalations.kpiProperties.target.title.key,
                              kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                            ),
                            metricValue: `${target}%`,
                            legendColor: 'tagBlue',
                          },
                        }}
                      />
                    )}

                    <WidgetMetricTile
                      metricProperties={{
                        type: WidgetMetricType.RegularMetric,
                        properties: {
                          metricTitle: t(
                            homeTranslations.scoShareOfReceipts.totalSCOTransactions.key,
                            homeTranslations.scoShareOfReceipts.totalSCOTransactions.defaultValue,
                          ),
                          metricValue: `${currentTotalSCOTransactions}`,
                        },
                      }}
                    />
                  </WidgetMetricTilesContainer>

                  <Container wrap="nowrap" data-testid="chart" style={{ flex: 1, overflow: 'hidden' }}>
                    <WidgetChart
                      performanceItemsChartName={t(
                        homeTranslations.scoShareOfReceipts.sor.key,
                        homeTranslations.scoShareOfReceipts.sor.defaultValue,
                      )}
                      performanceItems={performanceItems}
                      filteredPerformanceItems={filteredPerformanceItems}
                      forecastPerformanceItems={forecastPerformanceItems}
                      targetItems={targetItems}
                      itemValueResolver={itemValueResolver}
                      showXAxis={true}
                      yAxisFormatter={(value) => `${value}%`}
                      tooltipFormatter={(value) => `${Math.ceil(value)}`}
                    />
                  </Container>
                </StyledView>
              </Container>
            )}

            {isMobile && (
              <Container padding={[2, 6, 2]} wrap="nowrap" data-testid="chart" style={{ flex: 1, minHeight: 0 }}>
                {showForecast && (
                  <WidgetMetricTile
                    metricProperties={{
                      type: WidgetMetricType.ForecastMetric,
                      properties: {
                        metricValue: `${forecastShareOfReceipts}%`,
                        forecastIndicator: getDefaultStatus(targetProgressPercent),
                      },
                    }}
                  />
                )}

                <WidgetMobileLayout
                  metricItems={[
                    {
                      label: 'KPI',
                      content: t(
                        kpiTargetTransalations.kpiWidgets.dailySCOShareOfReceipts.key,
                        kpiTargetTransalations.kpiWidgets.dailySCOShareOfReceipts.defaultValue,
                      ),
                    },
                    {
                      label: t(
                        homeTranslations.scoShareOfReceipts.totalShareOfReceipts.key,
                        homeTranslations.scoShareOfReceipts.totalShareOfReceipts.defaultValue,
                      ),
                      content: `${currentShareOfReceipts}%`,
                    },
                    {
                      label: t(
                        kpiTargetTransalations.kpiProperties.target.title.key,
                        kpiTargetTransalations.kpiProperties.target.title.defaultValue,
                      ),
                      content: target === null ? `-` : `${target}%`,
                    },

                    {
                      label: t(
                        homeTranslations.scoShareOfReceipts.totalSCOTransactions.key,
                        homeTranslations.scoShareOfReceipts.totalSCOTransactions.defaultValue,
                      ),
                      content: `${currentTotalSCOTransactions}`,
                    },
                  ]}
                  renderChart={() => {
                    return (
                      <WidgetChart
                        performanceItemsChartName={t(
                          homeTranslations.shareOfReceipts.sor.key,
                          homeTranslations.shareOfReceipts.sor.defaultValue,
                        )}
                        performanceItems={performanceItems}
                        filteredPerformanceItems={filteredPerformanceItems}
                        forecastPerformanceItems={forecastPerformanceItems}
                        targetItems={targetItems}
                        itemValueResolver={itemValueResolver}
                        showXAxis={true}
                        yAxisFormatter={(value) => `${value}%`}
                        tooltipFormatter={(value) => `${Math.ceil(value)}`}
                        sparklineEnabled
                        customYAxis={{ show: false }}
                      />
                    );
                  }}
                />
              </Container>
            )}
          </>
        }
      />
    );
  },
);
