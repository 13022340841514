import { createTheme } from '@mui/material/styles';
import { css } from 'styled-components';
import { percentToHex } from '../utils/formatter';

export type Sizing = typeof sizing;
export type Color = typeof color;
export type Colors = keyof Color;
export type Sizes = 'small' | 'medium' | 'large';
export type AllSizes = Sizes | 'xl' | 'xxl';
export type Space = { [key in AllSizes]: number };
export type Direction = 'horizontal' | 'vertical';
export type Typography = { [key in Typographies]: TypographyObject };
export type Typographies =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'legal'
  | 'caption'
  | 'button';
export type MUITheme = typeof muiTheme;
export type Breakpoint = typeof breakpoints.values;
export type Breakpoints = keyof Breakpoint;

export type TypographyObject = {
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  lineHeight: number;
};

const sizing = {
  header: 'max(70px, 4vh)', // 70px is depending on new header size
  sideMenu: '242px',
  page: 'min(calc(100vh - 70px), 96vh)',
  pageHeader: 'max(50px, 3vh)',
  receiptWidth: '20vw',
  avatarSize: {
    small: '80px',
    medium: '128px',
    large: '200px',
  },
  avatarSettings: {
    card: {
      height: '500px',
      width: '420px',
    },
  },
};

export const color = {
  primary: '#222222',
  white: '#ffffff',
  inherit: 'inherit',
  red: '#e50010',
  validationGreen: '#00853D',
  backgroundMenuActive: '#F9F1EF',
  backgroundSelected: '#f4eddd',
  backgroundTableHeader: '#F4F6F8',
  tagBlue: '#0000FF',
  infoDark: '#006C9C',

  // banner
  error: '#FF7777',
  errorLight: '#FFB5A5',
  success: '#8DD5B9',
  successLight: percentToHex('#D8FBDE', 80),
  warning: '#FFD1A1',
  warningLight: '#FFAB00',
  info: '#93CDFF',
  backgroundWarning: percentToHex('#FFAB00', 16),
  warningDark: '#B76E00',

  // transparentColor
  errorTransparent: percentToHex('#FF7777', 64),
  successTransparent: percentToHex('#00AB55', 56),
  successLightTransparent: percentToHex('#00AB55', 28),
  warningTransparent: percentToHex('#FFAB00', 48),
  infoTransparent: percentToHex('#93CDFF', 24),

  // grays
  baseGray: '#faf9f8',
  borderGray: '#e4e4e4',
  textGray: '#707070',
  secondaryTextGray: '#555555',
  secondaryDarkTextGray: '#919EAB',
  hoverGray: '#E6E6E6',
  inActiveGray: '#d0d0d0',

  // dark-theme
  backgroundDark: '#161C24',
  backgroundPaperDark: '#212B36',
  backgroundTableHeaderDark: '#333D49',
  backgroundSelectedDark: percentToHex('#919EAB', 8),
  backgroundSelectedHover: percentToHex('#919EAB', 24),
  borderGrayDark: '#5d6875',
};

export const cardShadow = () => css`
  box-shadow: ${({ theme: { palette } }) =>
    palette.mode === 'dark'
      ? `0px 12px 24px -4px ${percentToHex('#000000', 24)}, 0px 0px 2px 0px ${percentToHex('#000000', 20)}`
      : `0px 12px 24px -4px ${color.backgroundSelectedHover}, 0px 0px 2px 0px ${color.backgroundSelectedHover}`};
`;

export const breakpoints = {
  values: {
    mobile: 480,
    tabletPortrait: 641,
    tabletLandscape: 900,
    desktop: 1200,
    desktopLarge: 1800,
  },
};

export const lineHeights: { [key in Typographies]: { desktop: number; tabletPortrait?: number } } = {
  h1: {
    desktop: 1.4,
    tabletPortrait: 1.4,
  },
  h2: {
    desktop: 2.5,
    tabletPortrait: 1.75,
  },
  h3: {
    desktop: 2.25,
    tabletPortrait: 1.5,
  },
  h4: {
    desktop: 2,
    tabletPortrait: 1.25,
  },
  body1: {
    desktop: 1.25,
  },
  body2: {
    desktop: 1.25,
  },
  body3: {
    desktop: 1.25,
  },
  body4: {
    desktop: 1.25,
  },
  legal: {
    desktop: 1,
  },
  caption: {
    desktop: 1.2,
  },
  button: {
    desktop: 1.25,
  },
};

export const fontSizes: {
  [key in Typographies]: {
    desktop: string;
    tabletPortrait?: string;
    mobile?: string;
  };
} = {
  h1: {
    desktop: '2.5rem',
    tabletPortrait: '2.25rem',
    mobile: '1.75rem',
  },
  h2: {
    desktop: '1.75rem',
    mobile: '1.5rem',
  },
  h3: {
    desktop: '1.5rem',
    mobile: '1.25rem',
  },
  h4: {
    desktop: '1.25rem',
    mobile: '1rem',
  },
  body1: {
    desktop: '0.875rem',
  },
  body2: {
    desktop: '0.875rem',
  },
  body3: {
    desktop: '0.875rem',
  },
  body4: {
    desktop: '0.875rem',
  },
  legal: {
    desktop: '0.75rem',
  },
  caption: {
    desktop: '0.813rem',
  },
  button: {
    desktop: '1rem',
  },
};

// Any theme-related properties that are not using breakpoint should go here.
const defaultTheme = createTheme({
  color,
  sizing,
  breakpoints,
  spacing: 4,
});

const { breakpoints: muiBreakpoint } = defaultTheme;

// Breakpoints media query are merged.
const styledTypography: Typography = {
  h1: {
    fontFamily: 'HMSans-SemiBold',
    fontWeight: 400,
    lineHeight: lineHeights.h1.desktop,
    fontSize: fontSizes.h1.desktop,
    [muiBreakpoint.down('desktop')]: {
      fontSize: fontSizes.h1.tabletPortrait,
      lineHeights: lineHeights.h1.tabletPortrait,
    },
    [muiBreakpoint.down('tabletPortrait')]: {
      fontSize: fontSizes.h1.mobile,
    },
  },
  h2: {
    fontFamily: 'HMSans-SemiBold',
    fontWeight: 400,
    lineHeight: lineHeights.h2.desktop,
    fontSize: fontSizes.h2.desktop,
    [muiBreakpoint.down('desktop')]: {
      lineHeights: lineHeights.h2.tabletPortrait,
    },
    [muiBreakpoint.down('tabletPortrait')]: {
      fontSize: fontSizes.h2.mobile,
    },
  },
  h3: {
    fontFamily: 'HMSans-SemiBold',
    fontWeight: 400,
    lineHeight: lineHeights.h3.desktop,
    fontSize: fontSizes.h3.desktop,
    [muiBreakpoint.down('desktop')]: {
      lineHeights: lineHeights.h3.tabletPortrait,
    },
    [muiBreakpoint.down('tabletPortrait')]: {
      fontSize: fontSizes.h3.mobile,
    },
  },
  h4: {
    fontFamily: 'HMSans-SemiBold',
    fontWeight: 400,
    lineHeight: lineHeights.h4.desktop,
    fontSize: fontSizes.h4.desktop,
    [muiBreakpoint.down('desktop')]: {
      lineHeights: lineHeights.h4.tabletPortrait,
    },
    [muiBreakpoint.down('tabletPortrait')]: {
      fontSize: fontSizes.h4.mobile,
    },
  },
  body1: {
    fontFamily: 'HMSans-Light',
    fontWeight: 400,
    lineHeight: lineHeights.body1.desktop,
    fontSize: fontSizes.body1.desktop,
  },
  body2: {
    fontFamily: 'HMSans-Regular',
    fontWeight: 400,
    lineHeight: lineHeights.body2.desktop,
    fontSize: fontSizes.body2.desktop,
  },
  body3: {
    fontFamily: 'HMSans-SemiBold',
    fontWeight: 400,
    lineHeight: lineHeights.body3.desktop,
    fontSize: fontSizes.body3.desktop,
  },
  body4: {
    fontFamily: 'HMSans-Bold',
    fontWeight: 600,
    lineHeight: lineHeights.body4.desktop,
    fontSize: fontSizes.body4.desktop,
  },
  legal: {
    fontFamily: 'HMSans-Regular',
    fontWeight: 400,
    lineHeight: lineHeights.legal.desktop,
    fontSize: fontSizes.legal.desktop,
  },
  caption: {
    fontFamily: 'HMSans-Regular',
    fontWeight: 400,
    lineHeight: lineHeights.caption.desktop,
    fontSize: fontSizes.caption.desktop,
  },
  button: {
    fontFamily: 'HMSans-SemiBold',
    fontWeight: 400,
    lineHeight: lineHeights.button.desktop,
    fontSize: fontSizes.button.desktop,
  },
};

export const themeObject = {
  ...defaultTheme,
  typography: {
    fontFamily: ['HMSans-Bold', 'HMSans-SemiBold', 'HMSans-Regular', 'HMSans-Light'].join(','),
    ...styledTypography,
  },
};
export const muiTheme = createTheme(themeObject);
