import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Icons, Typography } from '../../../../../../stories/atoms';
import { homeTranslations } from '../../../../../../translations';
import { ArticleLookups, EngagementMetrics, MemberIdentification, MemberRecruited } from '../CustomerEngagement';
import { StyledDivider } from '../CustomerEngagement.styles';
import { MetricSection } from '../metricSection/MetricSection';

export type EngagementSliderProps = {
  metricName: EngagementMetrics;
  metric: MemberRecruited | MemberIdentification | ArticleLookups;
  totalCustomerEngaged: string;
};

// Type guard
function isMetricType<T extends MemberRecruited | MemberIdentification | ArticleLookups>(
  metric: MemberRecruited | MemberIdentification | ArticleLookups,
  type: 'MemberRecruited' | 'MemberIdentification' | 'ArticleLookups',
): metric is T {
  const typeChecks = {
    MemberRecruited: 'recruitment' in metric && 'inStoreCareApp' in metric && 'ratio' in metric,
    MemberIdentification:
      'membersIdentified' in metric && 'memberPurchases' in metric && 'conversionRate' in metric,
    ArticleLookups: 'lookups' in metric && 'purchasedArticles' in metric && 'conversionRate' in metric,
  };

  return typeChecks[type];
}

export const EngagementSlider: FC<EngagementSliderProps> = ({ metricName, metric, totalCustomerEngaged }) => {
  const { t } = useTranslation();
  const { customerEngagement: customerEngagementranslations } = homeTranslations;

  return (
    <Container data-testid="customer-engagement-slider" wrap="nowrap" height="100%" padding={[1, 2]}>
      <Typography type="button">
        {t(
          customerEngagementranslations.customerEngaged.key,
          customerEngagementranslations.customerEngaged.defaultValue,
        )}
      </Typography>
      <Typography type="h4">{totalCustomerEngaged}</Typography>
      <StyledDivider />
      <Container wrap="nowrap" padding={[4, 0]} style={{ flex: 1 }}>
        {metricName === 'memberRecruited' && isMetricType<MemberRecruited>(metric, 'MemberRecruited') && (
          <MetricSection
            key="memberRecruited"
            metricName="memberRecruited"
            icon={Icons.NEWMEMBER}
            subMetric1={{ name: 'recruitment', value: metric.recruitment }}
            subMetric2={{ name: 'INSC APP', value: metric.inStoreCareApp }}
            subMetric3={{ name: 'ratio', value: metric.ratio }}
            isMobileView={false}
            tooltipText="memberRecruitedTooltip"
            isSliderView
          />
        )}
        {metricName === 'memberIdentification' &&
          isMetricType<MemberIdentification>(metric, 'MemberIdentification') && (
            <MetricSection
              key="memberIdentification"
              metricName="memberIdentification"
              icon={Icons.BAG}
              subMetric1={{ name: 'membersIdentified', value: metric.membersIdentified }}
              subMetric2={{ name: 'memberPurchases', value: metric.memberPurchases }}
              subMetric3={{ name: 'conversionRate', value: metric.conversionRate }}
              isMobileView={false}
              tooltipText="memberIdentificationTooltip"
              isSliderView
            />
          )}

        {metricName === 'articleLookups' && isMetricType<ArticleLookups>(metric, 'ArticleLookups') && (
          <MetricSection
            key="articleLookups"
            metricName="articleLookups"
            icon={Icons.SEARCH}
            subMetric1={{ name: 'lookups', value: metric.lookups }}
            subMetric2={{ name: 'purchasedArticles', value: metric.purchasedArticles }}
            subMetric3={{ name: 'conversionRate', value: metric.conversionRate }}
            isMobileView={false}
            tooltipText="articleLookupsTooltip"
            isSliderView
          />
        )}
      </Container>
    </Container>
  );
};
