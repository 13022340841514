import { PaletteMode } from '@mui/material';
import { color } from './theme';

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: color.primary,
          },
          secondary: { main: color.white },
          error: { main: color.red },
          text: {
            primary: color.primary,
            secondary: color.primary,
          },
        }
      : {
          primary: {
            main: color.primary,
          },
          secondary: { main: color.primary },
          error: { main: color.red },
          background: {
            default: color.backgroundPaperDark,
            paper: color.backgroundPaperDark,
          },
          text: {
            primary: color.white,
            secondary: color.textGray,
          },
        }),
  },
});

const MuiTab = {
  styleOverrides: {
    root: {
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: 'all',
      },
    },
  },
};

const MuiTabs = {
  styleOverrides: {
    indicator: {
      backgroundColor: color.red,
    },
    flexContainer: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
    },
  },
};

const MuiPickersCalendarHeader = {
  styleOverrides: {
    label: {
      fontFamily: 'HMSans-Bold',
    },
  },
};

const MuiTableFooter = {
  styleOverrides: {
    root: {
      position: 'sticky',
      left: 0,
      bottom: 0,
      zIndex: 2,
    },
  },
};

export const getThemedMuiComponents = (mode: PaletteMode) => ({
  components: {
    ...(mode === 'light'
      ? {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: color.primary,
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.5)',
                borderRadius: '5px',
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                minHeight: 40,
                '&:hover': {
                  backgroundColor: color.borderGray,
                },
              },
            },
          },
          MuiTablePagination: {
            styleOverrides: {
              root: {
                backgroundColor: color.baseGray,
              },
              toolbar: {
                paddingRight: '50px',
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              paper: {
                marginTop: 8,
                minWidth: 200,
              },
            },
          },
          MuiTab,
          MuiTabs,
          MuiPickersCalendarHeader,
          MuiTableFooter,
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: 0,
              },
              input: {
                minHeight: 0,
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              select: {
                minHeight: 0,
              },
            },
          },
        }
      : {
          MuiPaper: {
            styleOverrides: { root: { backgroundImage: 'unset' } },
          },
          MuiTablePagination: {
            styleOverrides: {
              root: {
                backgroundColor: color.backgroundDark,
              },
              toolbar: {
                paddingRight: '50px',
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: color.backgroundSelectedDark,
                },
              },
            },
          },
          MuiCircularProgress: {
            styleOverrides: {
              root: {
                color: color.white,
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: color.white,
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              icon: {
                color: color.white,
              },
            },
          },
          MuiTab,
          MuiTabs,
          MuiPickersCalendarHeader,
          MuiTableFooter,
        }),
  },
});
