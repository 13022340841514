import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { QueueLengthPerformanceItem, QueueLengthTillData, TillType } from '../../../../../hooks';
import { Container, Typography } from '../../../../../stories/atoms';
import { DynamicTab } from '../../../../../stories/molecules';
import { Colors, Typographies } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { Spacing } from '../../../../../utils';
import { GridCell, GridContainer, GridRow, InnerGridContainer } from './GridView.styles';

type TillAggregateData = {
  tillId: string;
  tillType: TillType;
  values: {
    [key: string]: { queueCount: number; transactions: number } | undefined;
  };
};

export const GridView = ({
  performanceItems,
  filteredPerformanceItems,
  target,
}: {
  performanceItems: QueueLengthPerformanceItem[];
  filteredPerformanceItems: (QueueLengthPerformanceItem | null)[];
  target: number | null;
}) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  const { queueLength: queueLengthTranslations } = homeTranslations;

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const tillAggregateDataMap: Map<string, TillAggregateData> = new Map();

  filteredPerformanceItems.forEach((item) => {
    if (!item) return;

    const { timeUnit, tillData } = item;

    tillData.forEach((data) => {
      const { tillId, tillType, queueCount, transactions } = data;

      // Filter based on till type
      if (tabIndex === 1 && tillType != 'DEFAULT' && tillType != null) return;

      if (tabIndex === 2 && tillType != 'SELFCHECKOUT') return;

      const tillAggregateData = tillAggregateDataMap.get(tillId);

      tillAggregateDataMap.set(tillId, {
        tillId,
        tillType,
        values: !tillAggregateData
          ? {
              [timeUnit]: { queueCount, transactions },
            }
          : {
              ...tillAggregateData.values,
              [timeUnit]: { queueCount, transactions },
            },
      });
    });
  });

  const getGridCellColor = ({
    value,
    targetValue,
    isOddRow,
    isHeader = false,
  }: {
    value: number | null;
    targetValue: number | null;
    isOddRow: boolean;
    isHeader?: boolean;
  }): Colors => {
    const defaultColor = isHeader
      ? mode !== 'dark'
        ? 'baseGray'
        : 'backgroundDark'
      : isOddRow
      ? 'backgroundSelectedDark'
      : 'backgroundSelectedHover';

    if (value === null || targetValue === null) return defaultColor;

    const percentProgress = (value / targetValue) * 100;

    if (percentProgress <= 97) return 'successTransparent';
    if (percentProgress <= 100) return 'successLightTransparent';
    if (percentProgress <= 103) return 'warningTransparent';
    return 'errorTransparent';
  };

  const getTillAveragePercent = (tillAggregateData: TillAggregateData): number => {
    if (!tillAggregateData) return 0;

    let totalQueueCount = 0;
    let totalTransactions = 0;

    Object.keys(tillAggregateData.values).map((key) => {
      const value = tillAggregateData.values[key];

      if (!value) return;

      const { queueCount, transactions } = value;

      totalQueueCount += queueCount;
      totalTransactions += transactions;
    });

    return (totalTransactions === 0 ? 0 : totalQueueCount / totalTransactions) * 100;
  };

  const getAveragePercent = (filteredTillAggregateDataList: TillAggregateData[]): number => {
    let totalQueueCount = 0;
    let totalTransactions = 0;

    filteredTillAggregateDataList.map((tillAggregateData) => {
      Object.keys(tillAggregateData.values).map((key) => {
        const value = tillAggregateData.values[key];

        if (!value) return;

        const { queueCount, transactions } = value;

        totalQueueCount += queueCount;
        totalTransactions += transactions;
      });
    });

    return (totalTransactions === 0 ? 0 : totalQueueCount / totalTransactions) * 100;
  };

  const tillAggregateDataList = Array.from(tillAggregateDataMap.values());

  const hasDefaultTill = !!filteredPerformanceItems.find((filteredPerformanceItem) => {
    if (filteredPerformanceItem === null) return false;

    const { tillData } = filteredPerformanceItem;

    const defaultQueueLengthTillData = tillData.find(
      (tillDataItem) => tillDataItem.tillType === 'DEFAULT' || tillDataItem.tillType === null,
    );

    return !!defaultQueueLengthTillData;
  });

  const hasScoTill = !!filteredPerformanceItems.find((filteredPerformanceItem) => {
    if (filteredPerformanceItem === null) return false;

    const { tillData } = filteredPerformanceItem;

    const scoQueueLengthTillData = tillData.find((tillDataItem) => tillDataItem.tillType === 'SELFCHECKOUT');

    return !!scoQueueLengthTillData;
  });

  return (
    <Container wrap="nowrap" data-testid="chart" style={{ flex: 1, minHeight: 0 }}>
      <DynamicTab
        tabLabelArray={[
          {
            tabName: 'All',
            isTabVisible: true,
            isTabDisabled: false,
            labelType: 'body3' as Typographies,
          },
          {
            tabName: t(queueLengthTranslations.tabDefault.key, queueLengthTranslations.tabDefault.defaultValue),
            isTabVisible: true,
            isTabDisabled: !hasDefaultTill,
            labelType: 'body3' as Typographies,
          },
          {
            tabName: t(
              queueLengthTranslations.tabSelfCheckout.key,
              queueLengthTranslations.tabSelfCheckout.defaultValue,
            ),
            isTabVisible: true,
            isTabDisabled: !hasScoTill,
            labelType: 'body3' as Typographies,
            labelMargin: [0] as Spacing,
          },
        ]}
        handleTabChange={handleTabChange}
        tabValue={tabIndex}
        variant="scrollable"
        tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
        content={[]}
      />

      <GridContainer>
        <GridRow>
          <GridCell>
            <Typography type="button">Avg</Typography>
          </GridCell>

          {performanceItems.map((_, index) => {
            const filteredPerformanceItem = filteredPerformanceItems[index];

            if (!filteredPerformanceItem) {
              return (
                <GridCell
                  gridColor={getGridCellColor({ value: null, targetValue: null, isOddRow: false, isHeader: true })}
                >
                  <Typography type="body3">{'-'}</Typography>
                </GridCell>
              );
            }

            const { tillData } = filteredPerformanceItem;

            let filteredQueueLengthTillData: QueueLengthTillData[] = tillData;

            // Filter based on till type
            if (tabIndex === 1) {
              filteredQueueLengthTillData = tillData.filter(
                (item) => item.tillType === 'DEFAULT' || item.tillType === null,
              );
            }

            if (tabIndex === 2) {
              filteredQueueLengthTillData = tillData.filter((item) => item.tillType === 'SELFCHECKOUT');
            }

            const totalQueueCount = filteredQueueLengthTillData.reduce((prev, curr) => {
              return prev + curr.queueCount;
            }, 0);

            const totalTransactions = filteredQueueLengthTillData.reduce((prev, curr) => {
              return prev + curr.transactions;
            }, 0);

            const percentValue = (totalTransactions === 0 ? 0 : totalQueueCount / totalTransactions) * 100;

            return (
              <GridCell
                gridColor={getGridCellColor({
                  value: percentValue,
                  targetValue: target,
                  isOddRow: false,
                  isHeader: true,
                })}
              >
                <Typography type="body3">{percentValue.toFixed(0)}%</Typography>
              </GridCell>
            );
          })}

          <GridCell
            gridColor={getGridCellColor({
              value: getAveragePercent(tillAggregateDataList),
              targetValue: target,
              isOddRow: false,
            })}
          >
            <Typography type="body3">{getAveragePercent(tillAggregateDataList).toFixed(0)}%</Typography>
          </GridCell>
        </GridRow>

        <InnerGridContainer>
          {tillAggregateDataList.map((tillAggregateData, index) => {
            const { tillId, values } = tillAggregateData;
            const isOddRow = index % 2 != 0;

            return (
              <GridRow>
                <GridCell>
                  <Typography>{tillId}</Typography>
                </GridCell>

                {performanceItems.map((performanceItem) => {
                  const { timeUnit } = performanceItem;

                  const timeUnitValues = values[timeUnit];

                  if (!timeUnitValues) {
                    return (
                      <GridCell
                        gridColor={getGridCellColor({ value: null, targetValue: null, isOddRow: isOddRow })}
                      >
                        <Typography>{'-'}</Typography>
                      </GridCell>
                    );
                  }

                  const percentValue =
                    (timeUnitValues.transactions === 0
                      ? 0
                      : timeUnitValues.queueCount / timeUnitValues.transactions) * 100;

                  return (
                    <GridCell
                      gridColor={getGridCellColor({
                        value: percentValue,
                        targetValue: target,
                        isOddRow: isOddRow,
                      })}
                    >
                      <Typography>{percentValue.toFixed(0)}%</Typography>
                    </GridCell>
                  );
                })}

                <GridCell
                  gridColor={getGridCellColor({
                    value: getTillAveragePercent(tillAggregateData),
                    targetValue: target,
                    isOddRow: isOddRow,
                  })}
                >
                  <Typography type="body3">{getTillAveragePercent(tillAggregateData).toFixed(0)}%</Typography>
                </GridCell>
              </GridRow>
            );
          })}
        </InnerGridContainer>

        <GridRow>
          <GridCell>
            <Typography>Till/Time</Typography>
          </GridCell>

          {performanceItems.map((performanceItem) => {
            const { timeUnit } = performanceItem;

            return (
              <GridCell>
                <Typography>{timeUnit}</Typography>
              </GridCell>
            );
          })}

          <GridCell>
            <Typography type="body3">Avg</Typography>
          </GridCell>
        </GridRow>
      </GridContainer>
    </Container>
  );
};
