export enum WidgetsCategory {
  HIGHLIGHTED = 'highlightedWidgets',
  CUSTOMEREXPERIENCE = 'customerExperience',
  CUSTOMERBASEVALUE = 'customerBaseValue',
  SALESEFFICIENCY = 'salesEfficiency',
  TRANSACTIONS = 'transactions',
  ALL = 'allWidgets',
}

export enum WidgetType {
  NewMembers = 'NewMembers',
  PiecesPerReceipt = 'PiecesPerReceipt',
  ConversionRate = 'ConversionRate',
  HourlyTurnover = 'HourlyTurnover',
  TopSellingGarment = 'TopSellingGarment',
  ShareOfReceipts = 'ShareOfReceipts',
  TenderOverview = 'TenderOverview',
  TopReturningGarment = 'TopReturningGarment',
  RegisteredReceipts = 'RegisteredReceipts',
  AssetManagement = 'AssetManagement',
  AveragePurchaseWidget = 'AveragePurchaseWidget',
  QueueLengthWidget = 'QueueLengthWidget',
  AppCustomersWidget = 'AppCustomersWidget',
  AreaComparisonWidget = 'AreaComparisonWidget',
  CustomerFeedBackWidget = 'CustomerFeedBackWidget',
  SCOShareOfReceipts = 'SCOShareOfReceipts',
  SCOHourlyTurnover = 'SCOHourlyTurnover',
  RfidApprovalsWidget = 'RfidApprovalsWidget',
  RfidCountWidget = 'RfidCountWidget',
  RfidSalesRetagging = 'RfidSalesRetagging',
  OrisCustomersWidget = 'OrisCustomersWidget',
  EfficiencyWidget = 'EfficiencyWidget',
  CustomerEngagmentWidget = 'CustomerEngagmentWidget',
}

export type CategoryWidgetOrderMap = {
  [key in WidgetType]?: number;
};
