import {
  QueueLengthCheckoutExperience,
  QueueLengthForecastPerformanceItem,
  QueueLengthPerformanceItem,
} from '../../../../../hooks';

export function isQueueLengthForecastPerformanceItem(
  item: QueueLengthPerformanceItem | QueueLengthForecastPerformanceItem,
): item is QueueLengthForecastPerformanceItem {
  return (item as QueueLengthForecastPerformanceItem).defaultQueueCount !== undefined;
}

export const calculatePercentage = (part: number, total: number) => (total === 0 ? 0 : part / total) * 100;

export const getOverAllQueueInformation = ({
  checkoutExperience,
  filteredPerformanceItems,
}: {
  checkoutExperience: QueueLengthCheckoutExperience | null;
  filteredPerformanceItems: (QueueLengthPerformanceItem | null)[];
}) => {
  if (checkoutExperience === null) {
    return {
      totalQueueCount: 0,
      totalTransactions: 0,
      overallQueueLengthPercentage: 0,
      overallExperiencePercentage: 0,
    };
  }

  let totalQueueCount = 0;
  let totalTransactions = 0;

  filteredPerformanceItems.forEach((filteredPerformanceItem) => {
    if (!filteredPerformanceItem) return;

    filteredPerformanceItem.tillData.forEach((queueLengthTillData) => {
      const { queueCount, transactions } = queueLengthTillData;

      totalQueueCount += queueCount;
      totalTransactions += transactions;
    });
  });

  const { happyCheckoutExperienceCount, totalCheckoutExperienceCount } = checkoutExperience;

  const overallQueueLengthPercentage = calculatePercentage(totalQueueCount, totalTransactions);

  const overallExperiencePercentage = calculatePercentage(
    happyCheckoutExperienceCount,
    totalCheckoutExperienceCount,
  );

  return {
    totalQueueCount,
    totalTransactions,
    overallQueueLengthPercentage,
    overallExperiencePercentage,
  };
};
