import { Divider } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '../../stories/atoms';

export const StyledKPIHeader = styled.div<{ isTabletView: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme, isTabletView }) => (isTabletView ? theme.spacing(0, 5) : theme.spacing(0, 10))};
  align-items: flex-start;
`;

export const StyledTextContainer = styled.div<{ isTabletView: boolean }>`
  display: flex;
  flex-direction: column;
`;

export const StyledKPIContent = styled.div<{ isTabletView: boolean }>`
  flex: 1;
  padding: ${({ theme, isTabletView }) => (isTabletView ? theme.spacing(5, 3) : theme.spacing(5, 10))};
  display: flex;
  flex-direction: column;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { palette, color } }) =>
    palette.mode === 'dark' ? color.borderGrayDark : color.baseGray};
`;

export const StyledTypography = styled(Typography)`
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.inherit : color.backgroundWarning};
  border-radius: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.warning : color.warningDark)};
  border: ${({ theme: { color, palette } }) => palette.mode === 'dark' && `1px solid ${color.warning}`};
`;

export const KPIWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: grid;
  gap: 1rem;
  grid-template-columns: ${({ isTablet, isMobile }) =>
    isMobile ? 'repeat(1, 1fr)' : isTablet ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
`;
