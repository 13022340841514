import { Forecast } from '../../../../constants';
import { formatAmountWithCurrency } from '../../../../utils';
import { ProgressIndicatorStatus } from './WidgetMetricTile';

export const getDefaultStatus = (percentage: number): ProgressIndicatorStatus => {
  if (percentage <= 97) return 'SAD';
  if (percentage <= 99) return 'CONFUSED';
  return 'HAPPY';
};

export const getForecastFormattedValue = (
  valueType: Forecast,
  metricValue: number,
  i18nLanguage: string,
  currencyCode: string,
  valueFormatter?: (value: number) => string,
): string => {
  let formattedValue = '';
  if (valueType === Forecast.CURRENCY) {
    formattedValue = formatAmountWithCurrency(parseInt(metricValue.toFixed(2)), i18nLanguage, currencyCode);
  } else if (valueType === Forecast.PERCENTAGE) {
    formattedValue = `${metricValue.toFixed(2)}%`;
  } else {
    formattedValue = valueFormatter ? valueFormatter(metricValue) : metricValue.toFixed(2).toString();
  }
  return formattedValue;
};
