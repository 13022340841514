import { ToggleButton as MuiToggleButton, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import {
  QueueLengthCheckoutExperience,
  QueueLengthForecastPerformanceItem,
  QueueLengthPerformanceItem,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { Animation, Animations, DropdownMenu } from '../../../../../stories/molecules';
import { ToggleButton } from '../../../../../stories/molecules/toggleButton/ToggleButton';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { GraphView } from './GraphView';
import { GridView } from './GridView';
import { StyledRightContainerWrapper, Wrapper } from './QueueLengthWidget.styles';
import { isQueueLengthForecastPerformanceItem } from './util';

export type QueueLengthProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  isToday: boolean;
  hasPermissionToEditKpi: boolean;
  fromDate?: Date;
  toDate?: Date;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
};

export const QueueLengthWidget: FC<QueueLengthProps> = observer(
  ({ interval, currentDate, isToday, hasPermissionToEditKpi, fromDate, toDate, handleDropdownDetails }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
    const { queueLength: queueLengthTranslations, kpiTargets: kpiTargetsTranslations } = homeTranslations;
    const { fetchQueueLengthData } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const {
      get: { kpiTargetsProps, userPermissions },
    } = useUser();

    const canViewTillGrid = userPermissions.queueLengthTillViewWidget.canRead;

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyQueueLength');
    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyQueueLength')?.isLocked ?? false;

    const [checkoutExperience, setCheckoutExperience] = useState<QueueLengthCheckoutExperience | null>(null);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const [view, setView] = useState('view1');

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, newView: string) => {
      if (newView !== null) {
        setView(newView);
      }
    };

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<QueueLengthPerformanceItem>();

    const fetchQueueLength = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchQueueLengthData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setCheckoutExperience(response.data.checkoutExperience);
      setLastUpdated(response.data.lastUpdated);

      return response.data.queueLength;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<QueueLengthPerformanceItem>('queueLength');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchQueueLength,
        getForecastDataAction: fetchForecastData,
        getHasValue: (item) => {
          // Forecast data type does not match the dnormal data type. Need to manually cast here
          const performanceOrForecastItem = item as
            | QueueLengthPerformanceItem
            | QueueLengthForecastPerformanceItem;

          if (isQueueLengthForecastPerformanceItem(performanceOrForecastItem)) {
            return (
              performanceOrForecastItem.defaultTransactionsCount !== 0 ||
              performanceOrForecastItem.scoTransactionsCount !== 0
            );
          }

          const { tillData } = item;

          return !!tillData.find((tillDataItem) => tillDataItem.queueCount > 0 || tillDataItem.transactions > 0);
        },
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate]);

    const isEditKPIDisabled = performanceItemsError || !hasPermissionToEditKpi;

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);

    const isPreviousDay = interval === StorePerformanceInterval.WEEKLY || !isToday;

    return (
      <WidgetCardContent
        headerIcon={Icons.PROFILE}
        headerText={t(queueLengthTranslations.title.key, queueLengthTranslations.title.defaultValue)}
        subHeaderText={t(queueLengthTranslations.subTitle.key, queueLengthTranslations.subTitle.defaultValue)}
        isLoading={performanceItemsLoading}
        isError={performanceItemsError}
        errorText={t(queueLengthTranslations.error.key, queueLengthTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData}
        lastUpdatedDate={lastUpdated}
        toolTipText={t(queueLengthTranslations.toolTip.key, queueLengthTranslations.toolTip.defaultValue)}
        noDataContent={
          <Animation
            size="small"
            title=""
            subtitle={t(queueLengthTranslations.noData.key, queueLengthTranslations.noData.defaultValue)}
            animation={Animations.COOL}
          />
        }
        headerSideContent={
          <StyledRightContainerWrapper>
            {performanceItems.length > 0 && !isMobile && canViewTillGrid && (
              <ToggleButton
                value={view}
                onChange={handleChangeView}
                leftButton={
                  <MuiToggleButton value="view1" aria-label="left button">
                    <Icon data-testid="left-icon" icon={Icons.TRENDUP} size="small" />
                  </MuiToggleButton>
                }
                rightButton={
                  <MuiToggleButton value="view2" aria-label="left button">
                    <Icon data-testid="right-icon" icon={Icons.ALLWIDGETS} size="small" />
                  </MuiToggleButton>
                }
              />
            )}

            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={[
                {
                  key: target ? 'editkpi' : 'addkpi',
                  disabled: isEditKPIDisabled || isTargetLocked || isPreviousDay,
                  hasTooltip: isEditKPIDisabled || isTargetLocked || isPreviousDay,
                  tooltipText: (
                    <Container padding={[1]}>
                      <Typography color="white">
                        {!hasPermissionToEditKpi
                          ? t(
                              kpiTargetsTranslations.noPermissionTooltip.key,
                              kpiTargetsTranslations.noPermissionTooltip.defaultValue,
                            )
                          : isTargetLocked
                          ? t(
                              kpiTargetsTranslations.lockedTargetTooltip.key,
                              kpiTargetsTranslations.lockedTargetTooltip.defaultValue,
                            )
                          : isPreviousDay
                          ? t(
                              kpiTargetsTranslations.previousDayTooltip.key,
                              kpiTargetsTranslations.previousDayTooltip.defaultValue,
                            )
                          : t(
                              kpiTargetsTranslations.disabledTooltip.key,
                              kpiTargetsTranslations.disabledTooltip.defaultValue,
                            )}
                      </Typography>
                    </Container>
                  ),
                  item: (
                    <Typography margin={[0, 2, 0]}>
                      {target
                        ? t(kpiTargetsTranslations.editKpi.key, kpiTargetsTranslations.editKpi.defaultValue)
                        : t(kpiTargetsTranslations.addKpi.key, kpiTargetsTranslations.addKpi.defaultValue)}
                    </Typography>
                  ),
                },
              ]}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItem) => {
                handleDropdownDetails('dailyQueueLength', selectedItem[0] as KpiDropdownActions);
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledRightContainerWrapper>
        }
        content={
          <Wrapper data-testid="queue-length-wrapper">
            <Container wrap="nowrap" data-testid="chart" style={{ flex: 1, minHeight: 0 }}>
              {(view === 'view1' || isMobile) && (
                <GraphView
                  target={target}
                  checkoutExperience={checkoutExperience}
                  performanceItems={performanceItems}
                  filteredPerformanceItems={filteredPerformanceItems}
                  forecastPerformanceItems={forecastPerformanceItems}
                />
              )}

              {view === 'view2' && !isMobile && (
                <GridView
                  target={target}
                  performanceItems={performanceItems}
                  filteredPerformanceItems={filteredPerformanceItems}
                />
              )}
            </Container>
          </Wrapper>
        }
      />
    );
  },
);
