import { PaletteMode } from '@mui/material';
import styled from 'styled-components';
import { Colors, color as ThemeColor } from '../../../../theme';
import { percentToHex } from '../../../../utils';
import { ProgressIndicatorStatus } from './WidgetMetricTile';

const getIndicatorColors = (indicator: ProgressIndicatorStatus, mode: PaletteMode) => {
  if (indicator === 'SAD') {
    return mode === 'light'
      ? { backgroundColor: percentToHex(ThemeColor.error, 24) }
      : { backgroundColor: percentToHex(ThemeColor.red, 24) };
  } else if (indicator === 'CONFUSED') {
    return mode === 'light'
      ? { backgroundColor: percentToHex(ThemeColor.warning, 24) }
      : { backgroundColor: percentToHex(ThemeColor.warningDark, 24) };
  } else {
    return mode === 'light'
      ? { backgroundColor: ThemeColor.successLight }
      : { backgroundColor: percentToHex(ThemeColor.validationGreen, 24) };
  }
};

export const TileWrapper = styled.div<{
  indicator?: ProgressIndicatorStatus;
}>`
  height: ${({ theme }) => theme.spacing(6)};
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  justify-content: space-between;
  background-color: ${({ theme: { color, palette }, indicator }) =>
    indicator
      ? getIndicatorColors(indicator, palette.mode).backgroundColor
      : palette.mode === 'dark'
      ? color.backgroundSelectedHover
      : color.backgroundTableHeader};
`;

export const StyledLegend = styled.div<{ legendColor: Colors }>`
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
  background-color: ${({ theme: { color }, legendColor }) => color && color[legendColor]};
  border: 1px solid ${({ theme: { color } }) => color.white};
`;

export const WidgetMetricTilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1.5)};
  flex-wrap: wrap;
`;
