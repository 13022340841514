import { ToggleButton as MuiToggleButton, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConversionRatePerformanceItem,
  useKpiTargetDistriubution,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { ToggleButton } from '../../../../../stories/molecules/toggleButton/ToggleButton';
import { homeTranslations } from '../../../../../translations';
import { getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';

import {
  createStorePerformanceRequest,
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { muiTheme } from '../../../../../theme';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { ConversionRateGraphView } from './ConversionRateGraphView';
import { StyledRightContainerWrapper, StyledView, StyledWrapper } from './ConversionRateWidget.styles';
import { PayingNonPayingGraphView } from './PayingNonPayingGraphView';

export type ConversionRateWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  isNonSigninUser?: boolean;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const ConversionRateWidget: FC<ConversionRateWidgetProps> = observer(
  ({
    interval,
    currentDate,
    hasPermissionToEditKpi,
    isNonSigninUser,
    isToday,
    handleDropdownDetails,
    fromDate,
    toDate,
  }) => {
    const {
      get: { kpiTargetsProps },
    } = useUser();
    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { fetchConversionRateWidgetData } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { transactionEventsCount } = useEventContext();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<ConversionRatePerformanceItem>();

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyConversionRate');
    const [view, setView] = useState('view1');
    const [today, setToday] = useState(0);
    const [lastWeek, setLastWeek] = useState(0);
    const [lastYear, setLastYear] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyConversionRate')?.isLocked ?? false;

    const { t } = useTranslation();

    const { conversionRate: conversionRateTranslation, kpiTargets } = homeTranslations;

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchConversionRateWidgetData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setToday(response.data.today);
      setLastWeek(response.data.lastWeek);
      setLastYear(response.data.lastYear);
      setLastUpdated(response.data.lastUpdated);

      return response.data.conversionRate;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<ConversionRatePerformanceItem>('conversionRate');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [isToday]);

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getForecastDataAction: fetchForecastData,
        getHasValue: (item) => item.payingCustomers !== 0 || item.nonPayingCustomers !== 0,
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate, isNonSigninUser, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailyConversionRate',
    });

    const isEditKPIDisabled = !hasPermissionToEditKpi;

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
      if (view2 !== null) {
        setView(view2);
      }
    };

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);

    const isPreviousDay = interval === StorePerformanceInterval.WEEKLY || !isToday;

    const menuItems = [
      {
        key: target ? 'editKpi' : 'addKpi',
        disabled: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        hasTooltip: isEditKPIDisabled || isTargetLocked || isPreviousDay,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : isPreviousDay
                ? t(kpiTargets.previousDayTooltip.key, kpiTargets.previousDayTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <WidgetCardContent
        headerIcon={Icons.SALESEFFICIENCY}
        headerText={t(conversionRateTranslation.title.key, conversionRateTranslation.title.defaultValue)}
        subHeaderText={t(conversionRateTranslation.subTitle.key, conversionRateTranslation.subTitle.defaultValue)}
        lastUpdatedDate={lastUpdated}
        isLoading={performanceItemsLoading}
        isError={performanceItemsError}
        errorText={t(conversionRateTranslation.error.key, conversionRateTranslation.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData}
        headerSideContent={
          <StyledRightContainerWrapper>
            {performanceItems.length > 0 && (
              <StyledWrapper isMobile={isMobile}>
                <ToggleButton
                  value={view}
                  onChange={handleChangeView}
                  leftButton={
                    <MuiToggleButton value="view1" aria-label="left button">
                      <Icon data-testid="left-icon" icon={Icons.CONVERSION} size="small" />
                    </MuiToggleButton>
                  }
                  rightButton={'123'}
                />
              </StyledWrapper>
            )}

            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={menuItems}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItem) => {
                if (!isEditKPIDisabled) {
                  handleDropdownDetails('dailyConversionRate', selectedItem[0] as KpiDropdownActions);
                }
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledRightContainerWrapper>
        }
        content={
          <>
            <Container wrap="nowrap" data-testid="conversion-rate-wrapper" height="100%">
              <StyledView isMobile={isMobile}>
                {view === 'view1' && (
                  <ConversionRateGraphView
                    target={target}
                    today={today}
                    lastWeek={lastWeek}
                    lastYear={lastYear}
                    performanceItems={performanceItems}
                    filteredPerformanceItems={filteredPerformanceItems}
                    forecastPerformanceItems={forecastPerformanceItems}
                    targetItems={targetItems}
                  />
                )}

                {view === 'view2' && (
                  <PayingNonPayingGraphView
                    interval={interval}
                    performanceItems={performanceItems}
                    filteredPerformanceItems={filteredPerformanceItems}
                  />
                )}
              </StyledView>
            </Container>
          </>
        }
        toolTipText={t(conversionRateTranslation.toolTip.key, conversionRateTranslation.toolTip.defaultValue)}
      />
    );
  },
);
