import { useMediaQuery } from '@mui/material';
import { createContext, ReactNode, useContext, useState, useEffect, useMemo } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useLocalStorage, useUser } from '../hooks';
import { themeObject } from '../theme';
import { LocalStorageKey } from '../constants/localStorageKey';
import { getDesignTokens, getThemedMuiComponents } from '../theme/styleOverrides';

export type ThemeMode = 'light' | 'dark';

type ThemeContextType = {
  mode: ThemeMode;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);
const useThemeContext = () => useContext(ThemeContext);

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { getItem, setItem } = useLocalStorage(); // For Persisting state in local storage
  const {
    get: { profile },
  } = useUser();
  const isDarkOS = useMediaQuery('(prefers-color-scheme: dark)'); // To capture state from system preferences

  const storedTheme = getItem(LocalStorageKey.SelectedTheme);

  const initialThemeMode: ThemeMode = useMemo(() => {
    if (profile?.settings?.darkMode !== undefined) {
      return profile.settings.darkMode ? 'dark' : 'light';
    }
    if (storedTheme) {
      return JSON.parse(storedTheme);
    }
    return isDarkOS ? 'dark' : 'light';
  }, [profile, storedTheme, isDarkOS]);

  const [mode, setMode] = useState<ThemeMode>(initialThemeMode);

  useEffect(() => {
    if (initialThemeMode) {
      setMode(initialThemeMode);
    }
  }, [initialThemeMode]);

  useEffect(() => {
    setItem(LocalStorageKey.SelectedTheme, JSON.stringify(mode));
  }, [mode, setItem]);

  const toggleTheme = () => {
    setMode((prevMode: ThemeMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(
    () => createTheme({ ...themeObject }, getDesignTokens(mode), getThemedMuiComponents(mode)),
    [mode],
  );

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { useThemeContext, ThemeProvider };
