import styled from 'styled-components';
import { Typography } from '../../../../stories/atoms';
import { Direction } from '../../../../theme';

export const StyledWrapper = styled.div<{ direction: Direction; fullWidth?: boolean }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  align-items: center;
  justify-content: ${({ fullWidth }) => fullWidth && 'space-between'};
  flex-wrap: wrap;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.white};
`;
