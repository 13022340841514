import styled from 'styled-components';
import { Container, Typography } from '../../../stories/atoms';
import { cardShadow, Colors } from '../../../theme';

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexColumn = styled(FlexRow)`
  display: flex;
  flex-direction: column;
`;

export const StyledKpiHistoryWrapper = styled(FlexColumn)<{ isTabletView: boolean; isMobileView: boolean }>`
  display: flex;
  flex: 1;
  padding: ${({ theme, isTabletView, isMobileView }) =>
    isMobileView ? theme.spacing(0, 0, 2) : isTabletView ? theme.spacing(0, 2, 2) : theme.spacing(0, 10, 8)};
`;

export const StyledContentWrapper = styled(FlexColumn)`
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  margin: ${({ theme: { spacing } }) => spacing(4, 0, 0)};
  background-color: ${({ theme: { palette, color } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  ${cardShadow()};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.secondaryDarkTextGray : color.secondaryTextGray};
`;

export const StyledTag = styled(Container)<{ bgColor?: Colors; textColor?: Colors }>`
  background-color: ${({ theme: { color }, bgColor }) => bgColor && color[bgColor]};
  border-radius: ${({ theme }) => theme.spacing(2)};
  & > div {
    color: ${({ theme: { color }, textColor }) => textColor && color[textColor]};
  }
`;

export const StyledPastKpiContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacing(3)};

  max-height: 60vh;
  overflow: auto;
`;

export const StyledTargetContainer = styled(FlexColumn)`
  border-radius: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2)};
  overflow: auto;
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
`;

export const StyledDateContainer = styled(FlexRow)`
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};

  & > div {
    flex: 1;
    padding: ${({ theme }) => theme.spacing(2)};
  }
  & > :first-child {
    border-right: 1px solid
      ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  }
  & > div :first-child {
    color: ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.secondaryDarkTextGray : color.secondaryTextGray};
  }
`;
