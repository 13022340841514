import { Divider } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '../../../../../stories/atoms';
import { muiTheme } from '../../../../../theme';
import { percentToHex } from '../../../../../utils';
import { EngagementMetrics } from './CustomerEngagement';

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

export const StyledTypography = styled(Typography)`
  width: 100%;
`;

export const StyledMetricContainer = styled(FlexColumn)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const getEngagementColors = (metricName: EngagementMetrics) => {
  if (metricName === 'memberRecruited') {
    return {
      backgroundColor: percentToHex(muiTheme.color.info, 8),
      borderColor: muiTheme.color.infoDark,
      tileColor: percentToHex(muiTheme.color.info, 50),
    };
  } else if (metricName === 'memberIdentification') {
    return {
      backgroundColor: percentToHex(muiTheme.color.warning, 8),
      borderColor: muiTheme.color.warningDark,
      tileColor: percentToHex(muiTheme.color.warning, 50),
    };
  } else {
    return {
      backgroundColor: percentToHex(muiTheme.color.success, 8),
      borderColor: muiTheme.color.validationGreen,
      tileColor: percentToHex(muiTheme.color.success, 50),
    };
  }
};

export const SectionContainer = styled(FlexColumn)<{ metricName: EngagementMetrics; isSliderView?: boolean }>`
  background-color: ${({ metricName }) => getEngagementColors(metricName).backgroundColor};
  border: ${({ metricName }) => `1px solid ${getEngagementColors(metricName).borderColor}`};
  border-radius: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  flex: 1;
  cursor: ${({ isSliderView }) => isSliderView && 'pointer'};
`;

export const StyledChartText = styled(FlexColumn)<{ isMobileView: boolean }>`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ isMobileView }) => (isMobileView ? '30px' : '0px')};
  left: 0px;
`;

export const StyledMetricTile = styled.div<{ metricName: EngagementMetrics; isMobileView: boolean }>`
  display: flex;
  flex-direction: ${({ isMobileView }) => (isMobileView ? 'column' : 'row')};
  background-color: ${({ metricName, isMobileView }) =>
    isMobileView ? 'none' : getEngagementColors(metricName).tileColor};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme, isMobileView }) => (isMobileView ? theme.spacing(0, 0, 0) : theme.spacing(2))};
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

export const StyledIconWrapper = styled.div<{ metricName: EngagementMetrics; isMobileView: boolean }>`
  background-color: ${({ metricName, isMobileView }) =>
    !isMobileView ? 'none' : getEngagementColors(metricName).tileColor};
  padding: ${({ theme, isMobileView }) => (!isMobileView ? theme.spacing(0) : theme.spacing(1.5))};
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const StyledTrafficContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundSelectedDark};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  border-radius: ${({ theme }) => theme.spacing(3)};
  margin: ${({ theme }) => theme.spacing(2, 0, 0)};
`;

export const StyledDivider = styled(Divider)`
  background-color: ${({ theme: { palette, color } }) => palette.mode === 'dark' && color.borderGrayDark};
`;
