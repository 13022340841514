import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Icons, Typography } from '../../../../stories/atoms';
import { Colors } from '../../../../theme';
import { homeTranslations } from '../../../../translations';
import { StyledLegend, TileWrapper } from './WidgetMetricTile.styles';

export type ProgressIndicatorStatus = 'SAD' | 'CONFUSED' | 'HAPPY';

export enum WidgetMetricType {
  ForecastMetric = 'forecast',
  RegularMetric = 'regular',
  LegendMetric = 'legend',
}

export type ForecastMetricProperties = {
  metricValue: string;
  forecastIndicator: ProgressIndicatorStatus;
};

export type RegularMetricProperties = {
  metricTitle: string;
  metricValue: string | JSX.Element;
};

export type LegendMetricProperties = RegularMetricProperties & {
  legendColor: Colors;
};
export type WidgetMetric =
  | { type: WidgetMetricType.ForecastMetric; properties: ForecastMetricProperties }
  | { type: WidgetMetricType.RegularMetric; properties: RegularMetricProperties }
  | { type: WidgetMetricType.LegendMetric; properties: LegendMetricProperties };

export type MetricTileProps = {
  metricProperties: WidgetMetric;
};

export const WidgetMetricTile: FC<MetricTileProps> = ({ metricProperties }) => {
  const { t } = useTranslation();
  const { common: commontTranslations } = homeTranslations;
  const { type, properties } = metricProperties;

  return (
    <>
      {type === 'regular' && (
        <TileWrapper data-testid={`${type}-tile`}>
          <Typography type="caption" color="textGray">
            {properties.metricTitle}:
          </Typography>

          {typeof properties.metricValue === 'string' && (
            <Typography type="caption">{properties.metricValue}</Typography>
          )}

          {typeof properties.metricValue !== 'string' && properties.metricValue}
        </TileWrapper>
      )}
      {type === 'legend' && (
        <TileWrapper data-testid={`${type}-tile`}>
          <StyledLegend legendColor={properties.legendColor} />
          <Typography type="caption" color="textGray">
            {properties.metricTitle}:
          </Typography>
          <Typography type="caption">{properties.metricValue}</Typography>
        </TileWrapper>
      )}

      {type === 'forecast' &&
        (() => {
          const { metricValue, forecastIndicator } = properties;
          return (
            <TileWrapper indicator={forecastIndicator} data-testid={`${type}-tile`}>
              <Typography type="caption" color="textGray">
                {t(commontTranslations.forecast.key, commontTranslations.forecast.defaultValue)}:
              </Typography>
              <Typography type="caption">{metricValue}</Typography>
              <Icon icon={Icons[forecastIndicator]} size="small" data-testid={forecastIndicator} />
            </TileWrapper>
          );
        })()}
    </>
  );
};
