import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStorePerformanceRequest, StorePerformanceInterval } from '../../../../../constants';
import { CustomerEngagementResponse, useAsyncAction, useTransaction } from '../../../../../hooks';
import { Container, Icons } from '../../../../../stories/atoms';
import { ContainerDrawer } from '../../../../../stories/molecules/container-drawer/ContainerDrawer';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { formatValue, isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { CustomerEngagementCard } from './customerEngagementCard/CustomerEngagementCard';
import { EngagementOverview } from './engagementOverview/EngagementOverview';
import { EngagementSlider } from './engagementSlider/EngagementSlider';

export type EngagementMetrics = 'memberRecruited' | 'memberIdentification' | 'articleLookups';
const { customerEngagement: customerEngagementranslations } = homeTranslations;

export type CustomerEngagementTranslation = keyof typeof customerEngagementranslations;

export type CustomerEngagementWidgetProps = {
  currentDate: Date;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

export type MemberRecruited = {
  recruitment: number;
  inStoreCareApp: number;
  ratio: number;
};

export type MemberIdentification = {
  membersIdentified: number;
  memberPurchases: number;
  conversionRate: number;
};

export type ArticleLookups = {
  lookups: number;
  purchasedArticles: number;
  conversionRate: number;
};

export type CustomerDataType = {
  memberRecruited: MemberRecruited;
  memberIdentification: MemberIdentification;
  articleLookups: ArticleLookups;
};

export const CustomerEngagementWidget: FC<CustomerEngagementWidgetProps> = observer(
  ({ currentDate, interval, fromDate, toDate }) => {
    const { t } = useTranslation();
    const { fetchCustomerEngagementData } = useTransaction();
    const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const [isEngagementSliderOpen, setIsEngagementSliderOpen] = useState(false);
    const [totalCustomerEngaged, setTotalCustomerEngaged] = useState(0);
    const [selectedMetric, setSelectedMetric] = useState<EngagementMetrics>('memberRecruited');
    const [customerData, setCustomerData] = useState<CustomerDataType>({
      memberRecruited: {
        recruitment: 0,
        inStoreCareApp: 0,
        ratio: 0,
      },
      memberIdentification: {
        membersIdentified: 0,
        memberPurchases: 0,
        conversionRate: 0,
      },
      articleLookups: {
        lookups: 0,
        purchasedArticles: 0,
        conversionRate: 0,
      },
    });

    const {
      result: customerEngagementData,
      isLoading: customerEngagementLoading,
      isError: customerEngagementError,
      triggerAction: triggerFetchCustomerEngagementAction,
    } = useAsyncAction<CustomerEngagementResponse>();

    // fetch data
    const fetchData = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchCustomerEngagementData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    // Calculate engagement metrics
    useEffect(() => {
      if (!customerEngagementData) return;

      const { memberRecruited, memberIdentification, articleLookups } = customerEngagementData;

      const totalEngaged =
        memberRecruited.recruitment + memberIdentification.membersIdentified + articleLookups.lookups;

      setTotalCustomerEngaged(totalEngaged);
      setCustomerData({
        memberRecruited: {
          recruitment: memberRecruited.recruitment,
          inStoreCareApp: memberRecruited.inStoreCareApp,
          ratio:
            memberRecruited.recruitment === 0
              ? 0
              : (memberRecruited.inStoreCareApp / memberRecruited.recruitment) * 100,
        },
        memberIdentification: {
          membersIdentified: memberIdentification.membersIdentified,
          memberPurchases: memberIdentification.memberPurchases,
          conversionRate:
            memberIdentification.membersIdentified === 0
              ? 0
              : (memberIdentification.memberPurchases / memberIdentification.membersIdentified) * 100,
        },
        articleLookups: {
          lookups: articleLookups.lookups,
          purchasedArticles: articleLookups.purchasedArticles,
          conversionRate:
            articleLookups.lookups === 0 ? 0 : (articleLookups.purchasedArticles / articleLookups.lookups) * 100,
        },
      });
    }, [customerEngagementData]);

    useEffect(() => {
      triggerFetchCustomerEngagementAction(fetchData);
    }, [currentDate, interval]);

    return (
      <ContainerDrawer
        isOpen={isEngagementSliderOpen}
        onClose={() => setIsEngagementSliderOpen(false)}
        sliderContent={
          <EngagementSlider
            metric={customerData[selectedMetric]}
            totalCustomerEngaged={formatValue(totalCustomerEngaged, {
              options: { minimumFractionDigits: 0, notation: 'compact' },
            })}
            metricName={selectedMetric}
          />
        }
        width="250px"
      >
        <WidgetCardContent
          headerIcon={Icons.PROFILE}
          headerText={t(customerEngagementranslations.title.key, customerEngagementranslations.title.defaultValue)}
          subHeaderText={t(
            customerEngagementranslations.subTitle.key,
            customerEngagementranslations.subTitle.defaultValue,
          )}
          isLoading={customerEngagementLoading}
          isError={customerEngagementError}
          errorText={t(customerEngagementranslations.error.key, customerEngagementranslations.error.defaultValue)}
          toolTipText={t(
            customerEngagementranslations.toolTip.key,
            customerEngagementranslations.toolTip.defaultValue,
          )}
          noDataText={t(
            customerEngagementranslations.nodataText.key,
            customerEngagementranslations.nodataText.defaultValue,
          )}
          isNoData={totalCustomerEngaged === 0}
          lastUpdatedDate={customerEngagementData?.lastUpdated}
          content={
            <Container
              style={{ gap: '16px' }}
              wrap="nowrap"
              data-testid="customer-engagement-widget-wrapper"
              padding={[2, 4, 4]}
              direction="horizontal"
              height="100%"
            >
              <EngagementOverview
                totalCustomerEngaged={totalCustomerEngaged}
                storeTraffic={customerEngagementData?.storeTraffic ?? 0}
                memberRecruited={customerEngagementData?.memberRecruited.recruitment ?? 0}
                memberIdentification={customerEngagementData?.memberIdentification.membersIdentified ?? 0}
                articleLookups={customerEngagementData?.articleLookups.lookups ?? 0}
              />
              <CustomerEngagementCard
                customerData={customerData}
                sendMetricName={(name: EngagementMetrics) => {
                  if (isMobileView) {
                    setSelectedMetric(name);
                    setIsEngagementSliderOpen(true);
                  }
                }}
              />
            </Container>
          }
        />
      </ContainerDrawer>
    );
  },
);
