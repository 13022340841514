import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { Typography } from '../../../../stories/atoms';
import { muiTheme } from '../../../../theme';
import { LayoutChart, LayoutContent, LayoutSection, LayoutWrapper } from './WidgetMobileLayout.styles';

type MetricItem = {
  label: string;
  content: string | JSX.Element;
};

type WidgetMobileLayoutProps = {
  metricItems: MetricItem[];
  topContent?: JSX.Element;
  renderChart: () => JSX.Element;
};

export const WidgetMobileLayout: FC<WidgetMobileLayoutProps> = ({ metricItems, topContent, renderChart }) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  return (
    <LayoutWrapper data-testid="layout-wrapper">
      {topContent && topContent}

      <LayoutContent data-testid="layout-content">
        {metricItems.map((item, index) => {
          const { label, content } = item;
          return (
            <LayoutSection key={index}>
              <Typography color={isMobile ? 'primary' : 'textGray'}>{label}</Typography>

              {typeof content === 'string' && <Typography>{content}</Typography>}

              {typeof content !== 'string' && content}
            </LayoutSection>
          );
        })}
      </LayoutContent>
      <LayoutChart data-testid="layout-chart"> {renderChart()}</LayoutChart>
    </LayoutWrapper>
  );
};
