import styled from 'styled-components';
import { Container } from '../../../../../stories/atoms';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const StyledRightContainerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSummaryContainer = styled(Container)`
  padding: ${({ theme: { spacing } }) => spacing(2)};
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.backgroundTableHeader};
`;

export const StyledSummaryItem = styled(Container)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ForecastContainer = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(2, 2, 1)};
`;
