import { useMediaQuery } from '@mui/material';
import { addMonths, format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BreakdownCostDetails,
  BreakdownCostType,
  ProfitLossResponse,
  useAsyncAction,
  useStores,
} from '../../hooks';
import { Container, Drawer, Icon, Icons, Typography } from '../../stories/atoms';
import {
  ActionButton,
  Animation,
  Animations,
  Banner,
  BannerItemsType,
  Button,
  DropdownMenu,
  FilterDrawer,
  FilterType,
  IconWithText,
} from '../../stories/molecules';
import { Page } from '../../stories/organisms';
import { muiTheme } from '../../theme';
import { commonTranslations, homeTranslations, profitAndLossTransalations } from '../../translations';
import { getUniqueId, isFailureResponse } from '../../utils';
import { StyledButton, StyledDivider, StyledExpandButton, StyledPLCardContainer } from './ProfitAndLoss.styles';
import { PLCardContent } from './plCardContent/PLCardContent';
import { ValueContainer } from './plCardContent/ValueContainer';
import { PLExpandCard } from './plExpandCard/PLExpandCard';
import PLFilter from './plFilter/PLFilter';
import { generateCardTitle, profitLossPropertyToValueMap } from './util';

export const ProfitAndLoss: FC<unknown> = observer(() => {
  const { t } = useTranslation();
  const { common } = homeTranslations;
  const { close } = commonTranslations;

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const {
    error,
    headerText,
    loading: loadingTranslation,
    monthDropdown,
    mobileBannerText,
    costNames,
  } = profitAndLossTransalations;

  const { fetchProfitLossData } = useStores();

  const {
    result: profitLossDetails,
    isLoading: profitLossLoading,
    isError: profitLossError,
    triggerAction: triggerFetchProfitLossDetails,
  } = useAsyncAction<ProfitLossResponse>();

  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const [expandData, setExpandData] = useState<Record<string, boolean>>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerDetails, setDrawerDetails] = useState<BreakdownCostDetails | null>(null);
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const isAnyExpanded = Object.values(expandData).some(Boolean);

  const profitLossValues =
    drawerDetails &&
    profitLossDetails &&
    profitLossPropertyToValueMap(drawerDetails.name, drawerDetails, profitLossDetails?.salesExcVat);

  // Fetch profit and loss data
  const fetchProfitLoss = async (monthArray: string[]) => {
    const response = await fetchProfitLossData(monthArray);

    if (isFailureResponse(response)) {
      throw new Error();
    }
    return response.data;
  };

  // Handle accordion expand
  const handleAccordionExpand = (value: { [key in string]: boolean }) => {
    setExpandData((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const getIsExpanded = (key: string) => {
    return expandData[key] ?? false;
  };

  // Handle expand all button
  const handleAllExpanded = () => {
    if (!profitLossDetails) return;
    const updatedExpandData: Record<string, boolean> = {};

    Object.keys(profitLossDetails.details).forEach((item) => {
      updatedExpandData[item] = true;
    });

    setExpandData(updatedExpandData);
  };

  const handleAllCollapse = () => {
    setExpandData({});
  };

  // Handle details drawer
  const onViewDetailsClick = (details: BreakdownCostDetails) => {
    setIsDrawerOpen(true);
    setDrawerDetails(details);
  };

  // Get financial year and months
  const getFinancialYearMonths = () => {
    const currentDate = new Date();
    const monthMap: Record<string, string> = {};

    // Determine the financial year range
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Include the last financial year
    const financialYearStart = currentMonth === 11 ? currentYear - 1 : currentYear - 2;
    const financialYearEnd = currentMonth === 11 ? currentYear + 1 : currentYear;

    const financialYear = `${financialYearStart}-${financialYearEnd}`;

    // Start from December of the year before last
    let date = new Date(financialYearStart, 11, 1); // December of the starting year
    while (date < new Date(currentYear, currentMonth, 1)) {
      // Exclude the current month
      const monthNumber = format(date, 'MM');
      const monthName = format(date, 'MMMM');
      const year = format(date, 'yyyy');
      const capitalizedMonth = monthName.charAt(0).toUpperCase() + monthName.slice(1);
      monthMap[`${year}${monthNumber}`] = `${capitalizedMonth} ${year}`;

      // Move to the next month
      date = addMonths(date, 1);
    }

    return { financialYear, monthMap };
  };

  const { financialYear, monthMap } = getFinancialYearMonths();

  const getTitle = (costName: string) => {
    const title = costNames[costName as BreakdownCostType]
      ? t(costNames[costName as BreakdownCostType].key, costNames[costName as BreakdownCostType].defaultValue)
      : costName;

    return generateCardTitle('', title, false);
  };

  // Trigger fetch profit and loss details on selected months change or on initial load
  useEffect(() => {
    const fetchForMonths = selectedMonths.length > 0 ? selectedMonths : Object.keys(monthMap);
    triggerFetchProfitLossDetails(() => fetchProfitLoss(fetchForMonths));
  }, [selectedMonths]);

  // Set banner items for mobile
  useEffect(() => {
    if (isMobile) {
      setBannerItems([
        {
          id: getUniqueId(),
          label: t(mobileBannerText.key, mobileBannerText.defaultValue),
          type: 'info',
          actionButton: ActionButton.Close,
        },
      ]);
    }
  }, []);

  // const dope = ['202412', '202411', '202410', '202409', '202408', '202407', '202406', '202405', '202404', '202403', '202402', '202401', '202312'];

  return (
    <Page
      header={
        <Container direction="horizontal" space="between" padding={isMobile ? [0, 4] : [2, 8]}>
          {isMobile && (
            <>
              <Banner items={bannerItems} onChange={() => null} />
              <FilterDrawer
                isOpen={isFilterDrawerOpen}
                onDrawerClose={() => setIsFilterDrawerOpen(false)}
                onClearAllFilters={() => setSelectedMonths([])}
                filtersSpecs={[
                  {
                    key: 'month',
                    type: FilterType.MultiSelectList,
                    title: t(monthDropdown.key, monthDropdown.defaultValue),
                    options: Object.entries(monthMap)
                      .reverse()
                      .map(([key, value]) => ({
                        key: key,
                        title: value,
                        disabled: false,
                      })),
                    selectedOptionsKeys: selectedMonths,
                    onSelect: (selectedItemKeys) => setSelectedMonths(selectedItemKeys),
                  },
                ]}
              />
              <Button
                label={t(commonTranslations.allFilters.key, commonTranslations.allFilters.defaultValue)}
                buttonContentProps={{
                  labelPosition: 'left',
                  iconOptions: {
                    icon: Icons.FILTER,
                    size: 'small',
                  },
                  textOptions: {
                    whiteSpace: 'nowrap',
                  },
                }}
                aria-haspopup="true"
                aria-expanded={isFilterDrawerOpen ? 'true' : undefined}
                onClick={() => setIsFilterDrawerOpen(true)}
                variant="text"
              />
            </>
          )}
          {isMobile && drawerDetails && (
            <Drawer
              variant="temporary"
              position="bottom"
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              hasBorderRadius
            >
              <Container direction="horizontal" wrap="nowrap" padding={[6, 8, 2, 6]} space="between">
                <Typography type="h3">{getTitle(drawerDetails.name)}</Typography>
                <IconWithText
                  label=""
                  iconOptions={{
                    icon: Icons.CLOSE,
                    size: 'small',
                  }}
                  onClick={() => setIsDrawerOpen(false)}
                />
              </Container>
              <Container wrap="nowrap" padding={[2, 8, 4]}>
                {profitLossValues &&
                  profitLossValues.map((item) => (
                    <React.Fragment key={item.key}>
                      <ValueContainer label={item.key} value={item.value?.toLocaleString()} />
                    </React.Fragment>
                  ))}
                <StyledButton
                  label={t(close.key, close.defaultValue)}
                  data-testid="close-drawer"
                  textOptions={{
                    type: 'button',
                    color: 'white',
                  }}
                  margin={[3, 0, 3]}
                  hasRadius
                  onClick={() => setIsDrawerOpen(false)}
                />
              </Container>
            </Drawer>
          )}

          {!isMobile && (
            <DropdownMenu
              data-testid="select-month-dropdown"
              dropdownLabel={t(monthDropdown.key, monthDropdown.defaultValue)}
              menuItems={Object.entries(monthMap)
                .reverse()
                .map(([key, value]) => ({
                  key: key,
                  item: <Typography margin={[0, 2]}>{value}</Typography>,
                }))}
              minWidth={'0px'}
              multiSelect
              checkbox
              isCompact
              maxHeight={'50vh'}
              initialSelected={selectedMonths}
              onSelect={(selectedItems) => {
                setSelectedMonths(selectedItems);
              }}
              isOnlyIcon
              allowTooltip
            />
          )}

          <StyledExpandButton
            data-testid="expand-button"
            label={
              isAnyExpanded
                ? t(common.collapseAll.key, common.collapseAll.defaultValue)
                : t(common.expandAll.key, common.expandAll.defaultValue)
            }
            shouldRotate={isAnyExpanded ? false : true}
            iconOptions={{
              icon: isAnyExpanded ? Icons.CHECKBOX_INDETERMINATE : Icons.CLOSE,
              size: 'small',
              rotate: 'up',
            }}
            textOptions={{
              type: 'button',
            }}
            onClick={isAnyExpanded ? handleAllCollapse : handleAllExpanded}
          />
        </Container>
      }
      body={
        <div>
          {profitLossLoading && (
            <Animation
              title={t(loadingTranslation.key, loadingTranslation.defaultValue)}
              animation={Animations.LOADING}
            />
          )}

          {!profitLossLoading && profitLossError && (
            <Animation
              title={t(error.errorTitle.key, error.errorTitle.defaultValue)}
              subtitle={t(error.errorSubtitle.key, error.errorSubtitle.defaultValue)}
              animation={Animations.SAD}
            />
          )}

          {!profitLossLoading && !profitLossError && profitLossDetails && (
            <Container
              wrap="nowrap"
              padding={isMobile ? [3, 4] : [4, 8]}
              data-testid="profit-and-loss-page-wrapper"
            >
              <Container wrap="nowrap" space="between" margin={[0, 0, 3, 0]}>
                <Typography gutterBottom>
                  {t(headerText.key, {
                    defaultValue: headerText.defaultValue,
                    reportPeriod: financialYear,
                  })}
                </Typography>
                {selectedMonths.length > 0 && !isMobile && (
                  <PLFilter
                    selectedMonths={selectedMonths}
                    monthMap={monthMap}
                    onClose={(selectedMonth) => {
                      const filteredMonths = selectedMonths.filter((month) => month !== selectedMonth);
                      setSelectedMonths(filteredMonths);
                    }}
                    onReset={() => {
                      setSelectedMonths([]);
                    }}
                  />
                )}
              </Container>

              <StyledPLCardContainer
                padding={isMobile ? [3, 2] : [4, 3]}
                margin={[0, 0, 4, 0]}
                isParent
                position="bottom"
                data-testid="profit-loss-summary-card"
              >
                <Container position="center" wrap="nowrap" direction="horizontal" width="100%">
                  <Icon icon={Icons.TAG} size={isMobile ? 'small' : 'medium'} margin={[1, 2]} />
                  <PLCardContent
                    costDetails={profitLossDetails.salesExcVat}
                    compareCostDetails={profitLossDetails.salesExcVat}
                    onViewDetailsClick={onViewDetailsClick}
                    costName="salesExcVat"
                    isParent
                  />
                </Container>
                <StyledDivider />
                <Container position="center" wrap="nowrap" direction="horizontal" width="100%">
                  <Icon icon={Icons.TAG} size={isMobile ? 'small' : 'medium'} margin={[1, 2]} />
                  <PLCardContent
                    costDetails={profitLossDetails.salesIncVat}
                    compareCostDetails={profitLossDetails.salesIncVat}
                    onViewDetailsClick={onViewDetailsClick}
                    costName="salesIncVat"
                    isParent
                  />
                </Container>
              </StyledPLCardContainer>

              {profitLossDetails &&
                profitLossDetails.details &&
                Object.entries(profitLossDetails.details).map(([parentCost, content]) => (
                  <PLExpandCard
                    key={parentCost}
                    costName={parentCost}
                    compareCostDetails={profitLossDetails.salesExcVat}
                    content={content}
                    isParent
                    isExpanded={getIsExpanded(parentCost)}
                    onViewDetailsClick={onViewDetailsClick}
                    onExpand={handleAccordionExpand}
                  />
                ))}
            </Container>
          )}
        </div>
      }
    />
  );
});
