import styled, { css } from 'styled-components';
import { ThemeMode } from '../../../global-state/themeContext';
import { Icon } from '../../../stories/atoms';
import { IconWithText } from '../../../stories/molecules';
import { cardShadow, Color } from '../../../theme';

// Flex utilities
const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

// Common styled components
export const FlexRow = styled.div`
  ${flexRow};
`;

export const FlexColumn = styled.div`
  ${flexColumn};
`;

// Utility function for background color
const getBackgroundColor = (color: Color, mode: ThemeMode) =>
  mode === 'dark' ? color.backgroundPaperDark : color.white;

// Utility function for border color
const getBorderColor = (color: Color, mode: ThemeMode) =>
  mode === 'dark' ? color.borderGrayDark : color.borderGray;

// Utility function for secondary text color
const getSecondaryTextColor = (color: Color, mode: ThemeMode) =>
  mode === 'dark' ? color.secondaryDarkTextGray : color.textGray;

// Containers
export const KPIContainerWrapper = styled(FlexColumn)`
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
  background-color: ${({ theme: { color, palette } }) => getBackgroundColor(color, palette.mode)};
  padding: ${({ theme: { spacing } }) => spacing(4, 5)};
  ${cardShadow()};
`;

export const StyledKPIValueContainer = styled(FlexColumn)`
  border: 1px solid ${({ theme: { color, palette } }) => getBorderColor(color, palette.mode)};
  position: relative;
  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme: { spacing } }) => spacing(5)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
`;

export const FlexRowItem = styled(FlexRow)<{ isLast?: boolean }>`
  flex: 1;
  padding: 0 10px;
`;

// Dividers
export const StyledDivider = styled.div<{ isRowDivider: boolean }>`
  height: ${({ isRowDivider }) => (isRowDivider ? '1px' : '52px')};
  width: ${({ isRowDivider }) => (isRowDivider ? '100%' : '1px')};
  background-color: ${({ theme: { color, palette } }) => getBorderColor(color, palette.mode)};
  margin: 4px 0;
`;

// Header
export const StyledKPIHeader = styled(FlexRow)`
  justify-content: space-between;

  & > div {
    ${flexRow};
  }
`;

export const HeaderIconsContainer = styled.div`
  position: relative;
`;

export const HeaderIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(9)};
  height: ${({ theme: { spacing } }) => spacing(9)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? theme.color.backgroundSelectedHover : theme.color.borderGray};
  justify-content: center;
`;

export const HeaderStarIconContainer = styled.div`
  position: absolute;
  left: ${({ theme: { spacing } }) => spacing(-1.5)};
  top: ${({ theme: { spacing } }) => spacing(-1)};
`;

// KPI Elements
export const StyledIconWithText = styled(IconWithText)<{ isLast?: boolean }>`
  flex: 1;
  border-right: 1px solid
    ${({ theme: { color, palette }, isLast }) => (isLast ? 'none' : getBorderColor(color, palette.mode))};
  padding: ${({ theme: { spacing } }) => spacing(2, 0)};
  &:hover {
    background-color: ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.backgroundSelectedHover : color.backgroundTableHeader};
  }
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(3)};
`;

export const StyledKPIProperty = styled(FlexRow)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(1)};

  & > div,
  [data-testid='infoIcon'] {
    color: ${({ theme: { color, palette } }) => getSecondaryTextColor(color, palette.mode)};
  }
`;

// Buttons
export const ButtonsContainer = styled(FlexRow)`
  margin-top: ${({ theme: { spacing } }) => spacing(4)};
  border: 1px solid ${({ theme: { color, palette } }) => getBorderColor(color, palette.mode)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  & > :first-child {
    border-top-left-radius: ${({ theme }) => theme.spacing(2)};
    border-bottom-left-radius: ${({ theme }) => theme.spacing(2)};
  }
  & > :last-child {
    border-top-right-radius: ${({ theme }) => theme.spacing(2)};
    border-bottom-right-radius: ${({ theme }) => theme.spacing(2)};
  }
`;

// Lock Icon
export const StyledHighlightIcon = styled(Icon)`
  & > svg {
    stroke: ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.warningTransparent : color.warning};
    color: ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.warningLight : color.warningLight};
  }
`;
